<template>
	<div class="navigation-style">
		<ZyroSelect
			class="navigation-style__dropdown"
			:options="props.styleOptions"
			:model-value="currentStyleOption"
			:filterable="false"
			:searchable="false"
			@update:model-value="emit('update:current-style-option', $event)"
		>
			<template #option="{ option }">
				{{ option.title }}
			</template>

			<template #selected-option="{ option }">
				{{ option.title }}
			</template>
		</ZyroSelect>

		<EditBlockHeaderTabStyleHeader v-if="props.currentStyleOption.id === STYLE_OPTION_TYPES.HEADER" />
		<EditBlockHeaderTabStyleButton v-if="props.currentStyleOption.id === STYLE_OPTION_TYPES.BUTTON" />
		<EditBlockHeaderTabStyleSocialIcons v-else-if="props.currentStyleOption.id === STYLE_OPTION_TYPES.SOCIAL_ICONS" />

		<template v-else>
			<!-- Button element -->
		</template>
	</div>
</template>

<script setup lang="ts">
import EditBlockHeaderTabStyleHeader from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabStyleHeader.vue';
import EditBlockHeaderTabStyleButton from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabStyleButton.vue';
import EditBlockHeaderTabStyleSocialIcons from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabStyleSocialIcons.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import { STYLE_OPTION_TYPES } from '@/constants/builderConstants';
import { EditBlockHeaderStyleDropdownOption } from '@/types/builderTypes';

const props = defineProps<{
	currentStyleOption: EditBlockHeaderStyleDropdownOption,
	styleOptions: EditBlockHeaderStyleDropdownOption[],
}>();

const emit = defineEmits<{
	'update:current-style-option': [EditBlockHeaderStyleDropdownOption],
}>();
</script>

<style lang="scss" scoped>
.navigation-style {
	&__dropdown {
		margin-bottom: 16px;
	}

	:deep(.navigation-style__dropdown) {
		.vs__dropdown-menu {
			min-height: 90px !important;
		}
	}

	&__item {
		padding: 8px 0;
	}

	&__separator {
		margin: 8px 0;
	}

	&__edit-text-styles-button {
		display: block;
		align-self: center;
		margin: 12px auto 0;
	}
}
</style>
