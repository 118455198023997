<template>
	<div class="add-block-button">
		<div class="add-block-button__onboarding">
			<HostingerButton
				ref="addSectionButton"
				v-qa="'builder-btn-addsection'"
				class="add-block-button__button"
				:class="{ 'add-block-button__button--mobile': isMobileMode }"
				:aria-label="$t('common.addSection')"
				theme="add-block"
				@click="addSection"
			>
				<template #icon-left>
					<Icon name="add" />
				</template>
				{{ isMobileMode ? null : t('common.addSection') }}
			</HostingerButton>
			<BuilderProductPageTemplateOnboardingStep
				v-if="isProductTemplateOnboardingStepAddSectionVisible"
				left="50%"
				top="0%"
				placement="top"
				:offset="{
					mainAxis: -240,
					crossAxis: 0,
				}"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import BuilderProductPageTemplateOnboardingStep from '@/components//BuilderProductPageTemplateOnboardingStep.vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import { OPEN_MODAL } from '@/store/builder/gui';
import { MODAL_ADD_BLOCK } from '@/constants/builderConstants';

import {
	computed,
	ref,
} from 'vue';
import EventLogApi from '@/api/EventLogApi';

const props = defineProps<{
	blockId?: string | null,
	isProductTemplateOnboardingStepAddSectionVisible?: boolean,
}>();

const {
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();

const addSectionButtonRef = ref<HTMLElement | null>(null);
const isMobileMode = computed(() => getters['gui/isMobileMode']);
const addSection = () => {
	dispatch('unselectCurrentElement');
	dispatch(`gui/${OPEN_MODAL}`, {
		name: MODAL_ADD_BLOCK,
		settings: {
			previousBlockId: props.blockId,
		},
	});

	if (addSectionButtonRef.value) {
		addSectionButtonRef.value.blur();
	}

	EventLogApi.logEvent({
		eventName: 'website_builder.add_section.enter',
	});
};
</script>

<style lang="scss" scoped>
.add-block-button {
	&__button {
		&--mobile {
			padding: 2px 0 2px 8px;
		}
	}

	&__onboarding {
		position: relative;
	}
}
</style>
