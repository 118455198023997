import { computed } from 'vue';
import {
	NEXT_BEST_ACTION,
	type NextBestAction,
} from '@hostinger/hdomains-status';
import EventLogApi from '@/api/EventLogApi';
import { useI18n } from 'vue-i18n';
import { useDomainStore } from '@/stores/domainStore';
import { useSiteStore } from '@/stores/siteStore';
import { useRedirects } from '@/use/useRedirects';
import {
	STATUS_ICONS,
	STATUS_THEMES,
	HOSTINGER_BUTTON_TYPES,
	HOSTINGER_BUTTON_THEMES,
} from '@/types/sharedPropTypes';
import { useIntercomStore } from '@/stores/intercomStore';
import {
	HPANEL_REDIRECT_ACTIONS,
	HPANEL_REDIRECT_SECTIONS,
	HPANEL_REDIRECTS_PATHS,
} from '@/types/redirectModuleTypes';
import type {
	StatusIcons,
	StatusThemes,
	HostingerButtonTheme,
	HostingerButtonType,
} from '@/types/sharedPropTypes';

interface DomainStatus {
	id: NextBestAction,
    statusText: string;
    statusTheme?: StatusThemes;
    modalStatusTheme?: StatusThemes;
	actionButtonText: string;
	actionButtonType: HostingerButtonType;
	isPublishDomainStatusVisible: boolean;
	action: () => void;
	buttonTheme?: HostingerButtonTheme,
	publishModalTitle?: string,
	publishModalIcon?: StatusIcons,
	publishModalButtonText?: string,
	publishModalDescription?: string,
	popupMessage?: string,
	isIconFilled?: boolean,
}

export const useDomainStatus = () => {
	const domainStore = useDomainStore();
	const siteStore = useSiteStore();
	const {
		redirectToHPanel,
		redirectToHPanelAddDomain,
		redirectToHPanelDomainSettings,
		redirectToHPanelDomainsPage,
		redirectToHPanelRegisterDomainResource,
	} = useRedirects();
	const intercomStore = useIntercomStore();

	const { t } = useI18n();

	const domainStatusMap = computed<Record<NextBestAction, DomainStatus>>(() => ({
		[NEXT_BEST_ACTION.MANAGE]: {
			id: NEXT_BEST_ACTION.MANAGE,
			statusText: t('siteSettings.domain.domainConnected'),
			statusTheme: STATUS_THEMES.SUCCESS,
			actionButtonText: t('builder.domainSettings'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.OUTLINED,
			isPublishDomainStatusVisible: false,
			action: () => {
				if (domainStore.domainStatus?.isDomainExternal) {
					redirectToHPanelDomainsPage();

					return;
				}

				redirectToHPanelDomainSettings({
					currentDomain: domainStore.customDomain,
					siteId: siteStore.websiteId,
				});
			},
		},
		[NEXT_BEST_ACTION.CONNECTING]: {
			id: NEXT_BEST_ACTION.CONNECTING,
			statusText: t('siteSettings.domain.verifyingConnectionV3'),
			statusTheme: STATUS_THEMES.WARNING,
			modalStatusTheme: STATUS_THEMES.SUCCESS,
			actionButtonText: t('siteSettings.domain.refreshStatus'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.OUTLINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				domainStore.fetchDomainStatus();
			},
			popupMessage: t('builder.domainPropagationMessage'),
			publishModalTitle: t('builder.domainConnectingTitle'),
			publishModalDescription: t('builder.domainConnectingMessage'),
			publishModalIcon: STATUS_ICONS.SCHEDULE,
		},
		[NEXT_BEST_ACTION.FINISH_REGISTRATION]: {
			id: NEXT_BEST_ACTION.FINISH_REGISTRATION,
			statusText: t('siteSettings.domain.domainRegistrationPending'),
			statusTheme: STATUS_THEMES.WARNING,
			actionButtonText: t('siteSettings.domain.finishRegistration'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => redirectToHPanelRegisterDomainResource({
				currentDomain: domainStore.customDomain,
				siteId: siteStore.websiteId,
			}),
			buttonTheme: HOSTINGER_BUTTON_THEMES.WARNING,
			publishModalButtonText: t('siteSettings.domain.finishRegistration'),
			publishModalTitle: t('builder.domainFinishRegistrationMessage'),
			publishModalIcon: STATUS_ICONS.ERROR,
			isIconFilled: true,
		},
		[NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY]: {
			id: NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY,
			statusText: t('common.connectDomain'),
			actionButtonText: t('builder.getDomainMessage'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: false,
			action: () => {
				EventLogApi.logEvent({
					eventName: 'website_builder.connect_domain_manual.enter',
					eventProperties: {
						location: 'from_preview',
					},
				});

				redirectToHPanelAddDomain({
					currentDomain: domainStore.customDomain,
					siteId: siteStore.websiteId,
				});
			},
			buttonTheme: HOSTINGER_BUTTON_THEMES.PRIMARY,
			publishModalButtonText: t('builder.getDomainMessage'),
			publishModalTitle: domainStore.isEligibleForFreeDomain
				? t('builder.publishedModalClaimDomainTitleV2') : t('builder.domainConnectTemporaryStatusTitle'),
			publishModalDescription: domainStore.isEligibleForFreeDomain
				? t('builder.publishedModalClaimDomainDescription') : t('builder.domainConnectTemporaryStatusDescription'),
			publishModalIcon: STATUS_ICONS.SCHEDULE,
			isIconFilled: false,
		},
		[NEXT_BEST_ACTION.CONNECT_DOMAIN]: {
			id: NEXT_BEST_ACTION.CONNECT_DOMAIN,
			statusText: t('siteSettings.domain.domainNotConnected'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.connect'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.CONNECT_DOMAIN,
					},
				});
			},
			buttonTheme: HOSTINGER_BUTTON_THEMES.DANGER,
			publishModalButtonText: t('common.connect'),
			publishModalTitle: t('builder.domainConnectTitle'),
			publishModalDescription: t('builder.domainConnectMessage'),
			publishModalIcon: STATUS_ICONS.ERROR,
			isIconFilled: true,
			popupMessage: t('builder.domainPendingVerificationMessage'),
		},
		[NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL]: {
			id: NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL,
			statusText: t('siteSettings.domain.domainNotConnected'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.connect'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.CONNECT_DOMAIN,
					},
				});
			},
		},
		[NEXT_BEST_ACTION.TRANSFER_DOMAIN]: {
			id: NEXT_BEST_ACTION.TRANSFER_DOMAIN,
			statusText: t('siteSettings.domain.domainConnected'),
			statusTheme: STATUS_THEMES.SUCCESS,
			actionButtonText: t('builder.domainSettings'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.OUTLINED,
			isPublishDomainStatusVisible: false,
			action: () => {
				redirectToHPanelDomainSettings({
					currentDomain: domainStore.customDomain,
					siteId: siteStore.websiteId,
				});
			},
		},
		[NEXT_BEST_ACTION.RENEW_DOMAIN]: {
			id: NEXT_BEST_ACTION.RENEW_DOMAIN,
			statusText: t('siteSettings.domain.domainHasExpired'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.renew'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.RENEW_DOMAIN,
					},
				});
			},
			buttonTheme: HOSTINGER_BUTTON_THEMES.DANGER,
			publishModalButtonText: t('common.renew'),
			publishModalTitle: t('builder.domainRenewDomainTitle'),
			publishModalIcon: STATUS_ICONS.ERROR,
			isIconFilled: true,
			publishModalDescription: t('builder.domainRenewDomainMessage'),
		},
		[NEXT_BEST_ACTION.RESTORE_DOMAIN]: {
			id: NEXT_BEST_ACTION.RESTORE_DOMAIN,
			statusText: t('siteSettings.domain.domainHasExpired'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.restore'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.RESTORE_DOMAIN,
					},
				});
			},
			buttonTheme: HOSTINGER_BUTTON_THEMES.DANGER,
			publishModalButtonText: t('common.restore'),
			publishModalTitle: t('builder.domainRenewDomainTitle'),
			publishModalIcon: STATUS_ICONS.ERROR,
			isIconFilled: true,
			publishModalDescription: t('builder.domainRestoreDomainMessage'),
		},
		[NEXT_BEST_ACTION.BUY_DOMAIN]: {
			id: NEXT_BEST_ACTION.BUY_DOMAIN,
			statusText: t('siteSettings.domain.domainIsAvailable'),
			statusTheme: STATUS_THEMES.INACTIVE,
			actionButtonText: t('common.buyDomain'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.CONTAINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanel({
					path: HPANEL_REDIRECTS_PATHS.WEBSITES,
					params: {
						section: HPANEL_REDIRECT_SECTIONS.WEBSITES,
						domain: domainStore.customDomain,
						action: HPANEL_REDIRECT_ACTIONS.BUY_DOMAIN,
					},
				});
			},
			buttonTheme: HOSTINGER_BUTTON_THEMES.WARNING,
			publishModalButtonText: t('common.buyDomain'),
			publishModalTitle: t('builder.domainBuyDomainMessage'),
			publishModalIcon: STATUS_ICONS.ERROR,
			isIconFilled: true,
		},
		[NEXT_BEST_ACTION.CONTACT_SUPPORT]: {
			id: NEXT_BEST_ACTION.CONTACT_SUPPORT,
			statusText: t('siteSettings.domain.domainSuspended'),
			statusTheme: STATUS_THEMES.DANGER,
			actionButtonText: t('common.contactSupport'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.OUTLINED,
			isPublishDomainStatusVisible: false,
			action: () => {
				intercomStore.handleIntercomOpen();
			},
		},
		[NEXT_BEST_ACTION.PENDING_VERIFICATION]: {
			id: NEXT_BEST_ACTION.PENDING_VERIFICATION,
			statusText: t('siteSettings.domain.pendingEmailVerification'),
			statusTheme: STATUS_THEMES.WARNING,
			actionButtonText: t('common.moreInfo'),
			actionButtonType: HOSTINGER_BUTTON_TYPES.OUTLINED,
			isPublishDomainStatusVisible: true,
			action: () => {
				redirectToHPanelDomainSettings({
					currentDomain: domainStore.customDomain,
					siteId: siteStore.websiteId,
				});
			},
			buttonTheme: HOSTINGER_BUTTON_THEMES.WARNING,
			publishModalButtonText: t('common.moreInfo'),
			publishModalTitle: t('builder.domainPendingVerificationMessage'),
			publishModalIcon: STATUS_ICONS.ERROR,
			isIconFilled: true,
			popupMessage: t('builder.domainPendingVerificationMessage'),
		},
	}));

	const domainStatusConfig = computed<DomainStatus | null>(() => {
		if (!domainStore.domainNextBestAction) return null;

		return domainStatusMap.value[domainStore.domainNextBestAction];
	});

	return {
		domainStatusConfig,
	};
};
