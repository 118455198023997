import { defineStore } from 'pinia';
import { PINIA_STORES } from '@/constants/stores';
import {
	nextTick,
	ref,
} from 'vue';
import { useSiteStore } from '@/stores/siteStore';
import {
	IFRAME_EVENT_ECOMMERCE_ADMIN_UPDATED,
	IFRAME_EVENT_ECOMMERCE_ADMIN_UNAVAILABLE,
	ECOMMERCE_REDIRECT_PATHS,
} from '@/constants/builderConstants';
import { EcommerceAdminPath } from '@/types/ecommerceTypes';
import { getRedirectUrlToEcommerce } from '@/use/useRedirects';
import { useAuthStore } from '@/stores/authStore';
import { useNotifications } from '@/use/useNotifications';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useSavingStore } from '@/stores/savingStore';

export const useEcommerceAdminStore = defineStore(PINIA_STORES.ECOMMERCE_ADMIN, () => {
	const siteStore = useSiteStore();
	const authStore = useAuthStore();
	const ecommerceStore = useEcommerceStore();
	const savingStore = useSavingStore();

	const isEcommerceAdminIframeOpen = ref(false);
	const ecommerceAdminIframeSrc = ref('');
	const shouldUpdateEcommerceData = ref(false);
	const isEcommerceDataBeingUpdatedAfterChangesInAdmin = ref(false);

	const handleSaveChanges = async () => {
		if (!savingStore.canSave) {
			return;
		}

		await savingStore.saveWebsite();
	};

	const setIsEcommerceAdminIframeOpen = async ({
		isOpen,
		path,
		params,
	}: {isOpen: boolean, path?: EcommerceAdminPath, params?: Record<string, string | number>, location?: string}) => {
		if (!isOpen) {
			isEcommerceAdminIframeOpen.value = false;
			ecommerceAdminIframeSrc.value = '';

			return;
		}

		await handleSaveChanges();

		const url = getRedirectUrlToEcommerce({
			path: path as string,
			siteId: siteStore.websiteId,
			params: {
				...params,
				jwt: authStore.getAuthToken(),
			},
		});

		isEcommerceAdminIframeOpen.value = isOpen;
		ecommerceAdminIframeSrc.value = url;
	};

	const handleEcommerceAdminMessage = (event: MessageEvent) => {
		if (event.data === IFRAME_EVENT_ECOMMERCE_ADMIN_UNAVAILABLE) {
			const { notify } = useNotifications();

			setIsEcommerceAdminIframeOpen({
				isOpen: false,
			});

			notify({
				messageI18nKeyPath: 'builder.storeManagerUnavailable',
			});

			return;
		}

		if (event.data !== IFRAME_EVENT_ECOMMERCE_ADMIN_UPDATED || shouldUpdateEcommerceData.value) {
			return;
		}

		shouldUpdateEcommerceData.value = true;
	};

	const handleEcommerceDataUpdate = async () => {
		// Reset product lists so all blocks would refetch new data on page open
		ecommerceStore.resetProductsList();

		await ecommerceStore.initEcommerce({
			refetch: true,
		});

		// set flag after initial data is fetched to prevent duplicate info fetching for legacy flow
		// TODO: move above fetch after ecommerce product legacy flow is deprecated, https://hostingers.atlassian.net/browse/BE-731
		isEcommerceDataBeingUpdatedAfterChangesInAdmin.value = true;

		const isDynamicPageProductSelected = !!ecommerceStore.currentDynamicPageProductId
			&& ecommerceStore.currentDynamicPageProductId !== -1;

		if (isDynamicPageProductSelected) {
			const isDynamicPageProductPresent = ecommerceStore.productsSeo.some(
				(product) => product.id === ecommerceStore.currentDynamicPageProductId,
			);

			if (!isDynamicPageProductPresent) {
				ecommerceStore.setCurrentDynamicPageProductId(ecommerceStore.productsSeo[0]?.id || -1);
			}
		}

		shouldUpdateEcommerceData.value = false;
		// TODO: remove timeout after ecommerce product legacy flow is deprecated, https://hostingers.atlassian.net/browse/BE-731
		await nextTick();
		isEcommerceDataBeingUpdatedAfterChangesInAdmin.value = false;
	};

	const openProductsAddInIframe = async () => {
		setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_ADD,
		});
	};

	const openPaymentsInIframe = async () => {
		setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PAYMENT_MANAGEMENT,
		});
	};

	const openCompanyInformationInIframe = async () => {
		setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.COMPANY_INFORMATION,
		});
	};

	const openShippingEditInIframe = async ({ shouldEditFirst }: {
		shouldEditFirst: number,
	}) => {
		setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.SHIPPING_EDIT,
			params: {
				shouldEditFirst,
			},
		});
	};

	return {
		shouldUpdateEcommerceData,
		isEcommerceAdminIframeOpen,
		ecommerceAdminIframeSrc,
		setIsEcommerceAdminIframeOpen,
		handleEcommerceAdminMessage,
		handleEcommerceDataUpdate,
		openProductsAddInIframe,
		openPaymentsInIframe,
		openCompanyInformationInIframe,
		openShippingEditInIframe,
		isEcommerceDataBeingUpdatedAfterChangesInAdmin,
	};
});
