<template>
	<div class="ecommerce-tab-ribbons">
		<div class="ecommerce-tab-ribbons__item">
			<ZyroLabel>
				{{ $t('builder.editButton.customButtonStyles.fillColor') }}
			</ZyroLabel>
			<ColorPicker
				:color="backgroundColor"
				placement="left"
				:offset="250"
				:is-open="isBackgroundColorPickerOpen"
				@click-outside="isBackgroundColorPickerOpen = false"
				@toggle="isBackgroundColorPickerOpen = !isBackgroundColorPickerOpen"
				@update-color="setRibbonStyle(PROPERTIES.backgroundColor, $event)"
			/>
		</div>
		<div class="ecommerce-tab-ribbons__item">
			<ZyroLabel>
				{{ $t('builder.editButton.customButtonStyles.textColor') }}
			</ZyroLabel>
			<ColorPicker
				:color="color"
				placement="left"
				:offset="250"
				:is-open="isColorPickerOpen"
				@click-outside="isColorPickerOpen = false"
				@toggle="isColorPickerOpen = !isColorPickerOpen"
				@update-color="setRibbonStyle(PROPERTIES.color, $event)"
			/>
		</div>
		<hr class="ecommerce-tab-ribbons__hr">
		<i18n-t
			tag="p"
			keypath="builder.productListRibbonTooltip"
			class="ecommerce-tab-ribbons__note"
		>
			<a
				href="https://support.hostinger.com/en/articles/6823389-website-builder-how-to-change-text-color-in-the-online-store"
				target="_blank"
				rel="noopener"
			>
				{{ t('builder.productListRibbonTooltipLinkText') }}
			</a>
		</i18n-t>
	</div>
</template>

<script setup lang="ts">
import ColorPicker from '@/components/global/color-picker/ColorPicker.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';

import cloneDeep from 'lodash.clonedeep';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import {
	ref,
	computed,
	onMounted,
	onUnmounted,
} from 'vue';

const PROPERTIES = {
	backgroundColor: 'ribbon-background-color',
	color: 'ribbon-color',
};

const { t } = useI18n();

const {
	getters,
	state,
	dispatch,
} = useStore();

const currentBlockId = computed(() => state.currentBlockId);
const currentBlock = computed(() => getters.currentBlock);

const blockId = ref(null);
const initialCurrentBlockData = ref(null);
const isColorPickerOpen = ref(false);
const isBackgroundColorPickerOpen = ref(false);

const ribbonStyle = computed(() => currentBlock.value.ribbonStyle || {});
const backgroundColor = computed(() => ribbonStyle.value[PROPERTIES.backgroundColor] || 'black');
const color = computed(() => ribbonStyle.value[PROPERTIES.color] || 'white');

const setRibbonStyle = (property: string, style: string) => {
	dispatch('updateBlockData', {
		blockId: blockId.value,
		blockData: {
			ribbonStyle: {
				[property]: style,
			},
		},
		merge: true,
	});
};

onMounted(() => {
	blockId.value = currentBlockId.value;
	initialCurrentBlockData.value = cloneDeep(currentBlock.value);
});

onUnmounted(() => {
	dispatch('undoRedo/createSnapshot');
});

</script>

<style lang="scss" scoped>
.ecommerce-tab-ribbons {
	display: flex;
	flex-direction: column;

	&__item {
		display: flex;
		justify-content: space-between;

		&:first-child {
			margin-bottom: 16px;
		}
	}

	&__hr {
		margin: 16px auto;
		border: none;
		height: 1px;
		width: 100%;
		background-color: $color-gray-border;
	}

	&__note {
		color: $color-gray;
		font-size: 14px;
		line-height: 1.5rem;
	}
}
</style>
