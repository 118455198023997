import {
	computed,
	ref,
} from 'vue';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { captureException } from '@sentry/vue';
import {
	HOSTING_PLANS,
	HOSTING_PLANS_PRIORITY,
} from '@/constants/resources';

import { useStore } from 'vuex';
import { getUser } from '@/api/UsersApi';
import { checkHasHostingPlan } from '@/api/HostingApi';
import {
	HRESOURCE_STATE,
	HRESOURCE_TYPE,
	HostingPlan,
} from '@/types/resourceTypes';
import { useResourcesStore } from '@/stores/resourcesStore';
import { AxiosError } from 'axios';

const isUserAuthenticated = ref(false);
const isUserWithActivePlan = ref(false);
const isUserPayToPublish = ref(false);
const availablePlans = ref<HostingPlan[]>([]);

const isSiteCapacityReached = computed(() => availablePlans.value.length
	&& availablePlans.value.every((plan) => plan.limits.addons.limit <= plan.limits.addons.count));
const isOnlyOneStarterPlanUser = computed(() => availablePlans.value.length === 1
	&& availablePlans.value[0].plan === HOSTING_PLANS.STARTER);

export const useUserAuthorizationState = () => {
	const { dispatch } = useStore();
	const resourcesStore = useResourcesStore();

	const getIsPayToPublishUser = (): Promise<boolean> => new Promise((resolve, reject) => {
		try {
			if (resourcesStore.isLoaded) {
				const hostingResources = resourcesStore.getResourcesByTypes([HRESOURCE_TYPE.HOSTING]);

				resolve(!hostingResources.length);
			}

			const unsubscribe = resourcesStore.$subscribe((_, state) => {
				if (!state.isLoaded) {
					return;
				}

				const hostingResources = resourcesStore.getResourcesByTypes([HRESOURCE_TYPE.HOSTING]);

				resolve(!hostingResources.length);
				unsubscribe();
			});
		} catch (error) {
			captureException(error);
			reject(new Error(`Error while getting users information. ${error}`));
		}
	});

	const fetchUserAuthorizationState = async () => {
		try {
			const { hPanelId } = await getUser();

			if (!hPanelId) {
				return;
			}

			if (hPanelId.startsWith('test-')) {
				isUserAuthenticated.value = true;
				isUserWithActivePlan.value = true;

				return;
			}

			isUserPayToPublish.value = await getIsPayToPublishUser();

			if (isUserPayToPublish.value) {
				isUserAuthenticated.value = true;
				isUserWithActivePlan.value = false;

				return;
			}

			try {
				isUserAuthenticated.value = true;

				const { data } = await checkHasHostingPlan();

				isUserWithActivePlan.value = true;
				availablePlans.value = data.filter(
					(plan) => plan.status === HRESOURCE_STATE.PENDING || plan.status === HRESOURCE_STATE.ACTIVE,
				);

				availablePlans.value.sort((a, b) => {
					const priorityA = HOSTING_PLANS_PRIORITY[a.plan as keyof typeof HOSTING_PLANS_PRIORITY]
						|| HOSTING_PLANS_PRIORITY[HOSTING_PLANS.STARTER];
					const priorityB = HOSTING_PLANS_PRIORITY[b.plan as keyof typeof HOSTING_PLANS_PRIORITY]
						|| HOSTING_PLANS_PRIORITY[HOSTING_PLANS.STARTER];

					return priorityA - priorityB;
				});
			} catch (error) {
				if ((error as AxiosError).response?.status === 401) {
					isUserWithActivePlan.value = false;
				} else if (isHostingerBrand && (error as AxiosError).response?.status === 403) {
					isUserWithActivePlan.value = false;
				}
			}
		} catch (error) {
			if (isHostingerBrand && (error as AxiosError).response?.status === 401) {
				isUserAuthenticated.value = false;
				isUserWithActivePlan.value = false;
			} else {
				dispatch('notifications/notify', {
					message: 'An error occurred while fetching user information.',
				});

				isUserAuthenticated.value = false;
				isUserWithActivePlan.value = false;
			}
		}
	};

	const getPlanData = (hostingReferenceId: string) => availablePlans.value.find(
		(plan) => Number.parseInt(hostingReferenceId, 10) === plan.order_id,
	);

	const isHostingPlanCapacityReached = (hostingReferenceId: string) => {
		const planData = getPlanData(hostingReferenceId);

		return !planData || planData.limits.addons.limit <= planData.limits.addons.count;
	};

	const getFirstAvailablePlanId = () => {
		const firstAvailablePlan = availablePlans.value.find((plan) => plan.limits.addons.limit > plan.limits.addons.count);

		return firstAvailablePlan?.order_id.toString();
	};

	// If passed hosting reference ID plan capacity is reached, get first available plan ID
	const getHostingReferenceIdForSiteCreation = (hostingReferenceId: string | undefined) => {
		if (!hostingReferenceId) {
			return getFirstAvailablePlanId();
		}

		if (!isHostingPlanCapacityReached(hostingReferenceId)) {
			return hostingReferenceId;
		}

		return getFirstAvailablePlanId();
	};

	return {
		isUserAuthenticated,
		isUserWithActivePlan,
		fetchUserAuthorizationState,
		isSiteCapacityReached,
		isUserPayToPublish,
		isOnlyOneStarterPlanUser,
		isHostingPlanCapacityReached,
		getFirstAvailablePlanId,
		getHostingReferenceIdForSiteCreation,
	};
};
