import { PINIA_STORES } from '@/constants/stores';
import {
	AiBuilderColorPalette,
	ColorsTag,
	WebsiteType,
} from '@/types/aiBuilderTypes';
import { defineStore } from 'pinia';
import {
	computed,
	ref,
} from 'vue';
import { AI_BUILDER_CATEGORY_ID_OTHER } from '@/constants/builderConstants';

type LastGeneratedSiteData = {
	brandName: string;
	websiteDescription: string;
	websiteType: WebsiteType;
	selectedColors?: AiBuilderColorPalette;
};

type CustomColors = { color1: string, color2: string, color3: string };

export const useAiBuilderStore = defineStore(PINIA_STORES.AI_BUILDER_PREVIEW, () => {
	const lastBrandName = ref('');
	const lastWebsiteDescription = ref('');
	const lastWebsiteType = ref<WebsiteType>(AI_BUILDER_CATEGORY_ID_OTHER);
	const lastSelectedColors = ref<AiBuilderColorPalette | undefined>();
	const aiFormVisible = ref(false);
	const currentVersionIndex = ref(0);
	const isLoadingEcommerceData = ref(false);
	const isAiBuilderPreviewInitialized = ref(false);
	const isPromptAdjusted = ref(false);
	const brandName = ref('');
	const websiteDescription = ref('');
	const websiteType = ref<WebsiteType>(AI_BUILDER_CATEGORY_ID_OTHER);
	const isDescriptionUpdatedManually = ref(false);
	const isCollapsibleContainerOpen = ref(false);
	const isPaletteListPopupOpen = ref(false);
	const selectedColorsTag = ref<ColorsTag>('random');
	const selectedHostingReferenceId = ref<string | undefined>(undefined);
	const isWebsiteTypeFetched = ref(false);
	const selectedColors = ref<AiBuilderColorPalette>({
		name: '',
		color1: '',
		color2: '',
		color3: '',
		light: '',
		dark: '',
		grey: '',
	});
	const shouldAddOnlineStore = ref(true);
	const isOnlineStoreSuggestionDeclined = ref(false);
	const isSiteBeingGenerated = ref(false);
	const isGoingToBuilder = ref(false);
	const hasGenerationFailed = ref(false);
	const isCustomColorPickerVisible = ref(false);
	const customColors = ref<CustomColors>({
		color1: '',
		color2: '',
		color3: '',
	});

	const isAiBuilderLoading = computed(() => isSiteBeingGenerated.value || isGoingToBuilder.value);

	const setLastAiGenerationData = (lastGeneratedSiteData: LastGeneratedSiteData) => {
		lastBrandName.value = lastGeneratedSiteData.brandName;
		lastWebsiteDescription.value = lastGeneratedSiteData.websiteDescription;
		lastWebsiteType.value = lastGeneratedSiteData.websiteType;
		lastSelectedColors.value = lastGeneratedSiteData.selectedColors;
	};

	const getLastAiGenerationData = () => ({
		brandName: lastBrandName.value,
		websiteDescription: lastWebsiteDescription.value,
		websiteType: lastWebsiteType.value,
		selectedColors: lastSelectedColors.value,
	});

	const setAiFormVisibility = (value: boolean) => {
		aiFormVisible.value = value;
	};

	const setCurrentVersionIndex = (value: number) => {
		currentVersionIndex.value = value;
	};

	const setIsLoadingEcommerceData = (value: boolean) => {
		isLoadingEcommerceData.value = value;
	};

	const setIsAiBuilderPreviewInitialized = (value: boolean) => {
		isAiBuilderPreviewInitialized.value = value;
	};

	const setIsPromptAdjusted = (value: boolean) => {
		isPromptAdjusted.value = value;
	};

	const setBrandName = (value: string) => {
		brandName.value = value;
	};

	const setWebsiteDescription = (value: string) => {
		websiteDescription.value = value;
	};

	const setWebsiteType = (value: WebsiteType) => {
		websiteType.value = value;
	};

	const setIsDescriptionUpdatedManually = (value: boolean) => {
		isDescriptionUpdatedManually.value = value;
	};

	const setIsCollapsibleContainerOpen = (value: boolean) => {
		isCollapsibleContainerOpen.value = value;
	};

	const setIsPaletteListPopupOpen = (value: boolean) => {
		isPaletteListPopupOpen.value = value;
	};

	const setSelectedColorsTag = (value: ColorsTag) => {
		selectedColorsTag.value = value;
	};

	const setSelectedHostingReferenceId = (value: string) => {
		selectedHostingReferenceId.value = value;
	};

	const setSelectedColors = (colors: AiBuilderColorPalette) => {
		selectedColors.value = colors;
	};

	const setShouldAddOnlineStore = (value: boolean) => {
		shouldAddOnlineStore.value = value;
	};

	const toggleOnlineStoreCheckboxChange = (value: boolean) => {
		isOnlineStoreSuggestionDeclined.value = !value;
	};

	const setIsSiteBeingGenerated = (value: boolean) => {
		isSiteBeingGenerated.value = value;
	};

	const setIsGoingToBuilder = (value: boolean) => {
		isGoingToBuilder.value = value;
	};

	const setHasGenerationFailed = (value: boolean) => {
		hasGenerationFailed.value = value;
	};

	const setIsCustomColorPickerVisible = (value: boolean) => {
		isCustomColorPickerVisible.value = value;
	};

	const setCustomColors = (colors: CustomColors) => {
		customColors.value = colors;
	};

	const setIsWebsiteTypeFetched = (value: boolean) => {
		isWebsiteTypeFetched.value = value;
	};

	return {
		brandName,
		websiteDescription,
		websiteType,
		lastBrandName,
		lastWebsiteDescription,
		lastWebsiteType,
		getLastAiGenerationData,
		aiFormVisible,
		currentVersionIndex,
		isLoadingEcommerceData,
		isAiBuilderPreviewInitialized,
		isPromptAdjusted,
		isDescriptionUpdatedManually,
		isCollapsibleContainerOpen,
		isPaletteListPopupOpen,
		selectedColorsTag,
		selectedHostingReferenceId,
		selectedColors,
		shouldAddOnlineStore,
		isOnlineStoreSuggestionDeclined,
		isSiteBeingGenerated,
		isGoingToBuilder,
		hasGenerationFailed,
		isCustomColorPickerVisible,
		customColors,
		isAiBuilderLoading,
		isWebsiteTypeFetched,
		setBrandName,
		setWebsiteDescription,
		setWebsiteType,
		setLastAiGenerationData,
		setAiFormVisibility,
		setCurrentVersionIndex,
		setIsLoadingEcommerceData,
		setIsAiBuilderPreviewInitialized,
		setIsPromptAdjusted,
		setIsDescriptionUpdatedManually,
		setIsCollapsibleContainerOpen,
		setIsPaletteListPopupOpen,
		setSelectedColorsTag,
		setSelectedHostingReferenceId,
		setSelectedColors,
		setShouldAddOnlineStore,
		toggleOnlineStoreCheckboxChange,
		setIsSiteBeingGenerated,
		setIsGoingToBuilder,
		setHasGenerationFailed,
		setIsCustomColorPickerVisible,
		setCustomColors,
		setIsWebsiteTypeFetched,
	};
});
