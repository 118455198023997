<template>
	<GridImage
		ref="imageRef"
		:data-element-ref="elementId"
		:href="isPreviewMode ? href : undefined"
		:tag-name="isPreviewMode ? tagName : undefined"
		class="image layout-element__component layout-element__component--GridImage"
		:class="{
			'image--cropped': isImageCropped,
			'image--zoom-in': isPreviewMode && isZoomInAction,
			'image--link': isLinkAction,
		}"
		:src="isImageInView ? src : ''"
		:alt="data.settings.alt"
		:shape-mask-source="shapeMaskSource"
		:reset-mobile-position="resetMobilePosition"
		prevent-drag
		:crop-css-vars="cropCSSVars"
		:is-overflow-visible="isImageCropped"
		is-eager
		is-in-builder
		:[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_IMAGE"
		:mobile-border-radius="data.mobile.borderRadius"
		:desktop-border-radius="data.desktop.borderRadius"
		:overlay-opacity="data.overlayOpacity"
		@mousedown="startMovingCropArea"
		@image-click="handleImageClick"
	/>
	<GridImage
		v-if="isImageCropped"
		class="image image--masked layout-element__component layout-element__component--GridImage"
		:src="isImageInView ? src : ''"
		:reset-mobile-position="resetMobilePosition"
		prevent-drag
		:crop-css-vars="cropCSSVars"
		is-overflow-visible
		is-in-builder
		:overlay-opacity="data.overlayOpacity"
	/>

	<Teleport
		v-if="showAssetManager"
		to="body"
	>
		<AssetManager
			ref="assetManagerRef"
			:visible-categories="[ASSET_CATEGORY.IMAGE]"
			@select-image="setImage({ image: $event })"
			@close="handleAssetManagerClose"
		/>
	</Teleport>

	<template v-if="!isPreviewMode">
		<EditCropControlsPopup
			v-if="isCropPopupVisible"
			:target-ref="imageRef.$el"
		/>

		<ElementEditControls
			v-else
			:target-ref="imageRef?.$el"
			:element-id="elementId"
			:is-element-active="isActive"
			:enter-edit-mode-button-title="$t('builder.editImage.title')"
		>
			<template #edit-mode-popup>
				<EditImage
					@toggle-ai-generate="toggleImageGeneratorPopup({ location: 'settings' })"
					@close="closeElementEditPopupHandler"
				/>
			</template>
			<template #additional-edit-buttons>
				<template v-if="!isAiBuilderMode && isHostingerBrand">
					<VerticalSeparator />
					<AiImageGenerationButton @toggle-ai-generate="toggleImageGeneratorPopup({ location: 'controls' })" />
				</template>
				<VerticalSeparator />
				<ControlsTooltip :title="$t('common.changeImage')">
					<HostingerButton
						v-qa="'builder-element-edit-change-image'"
						button-type="plain"
						theme="highlight"
						:title="$t('common.changeImage')"
						@click="handleAssetManagerButtonClick"
					>
						<template #icon>
							<Icon name="add_photo_alternate" />
						</template>
					</HostingerButton>
				</ControlsTooltip>

				<ControlsTooltip
					v-if="showCrop"
					:title="$t('common.cropAndPosition')"
				>
					<HostingerButton
						v-qa="'builder-elementedit-buttoncrop'"
						button-type="plain"
						theme="highlight"
						:title="$t('common.crop')"
						@click="enterCropMode(elementId)"
					>
						<template #icon>
							<Icon name="crop" />
						</template>
					</HostingerButton>
				</ControlsTooltip>
			</template>
		</ElementEditControls>
	</template>
</template>
<script setup>
import { useStore } from 'vuex';
import {
	computed,
	ref,
	watch,
	onUnmounted,
} from 'vue';

import GridImage from '@zyro-inc/site-modules/components/elements/image/GridImage.vue';
import EditImage from '@/components/builder-controls/edit-image/EditImage.vue';
import EditCropControlsPopup from '@/components/builder-controls/EditCropControlsPopup.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import AssetManager from '@/components/builder-modals/modals/AssetManager.vue';

import {
	getFullWidthSrcset,
	getOptimizedSrc,
} from '@zyro-inc/site-modules/utils/getSrcsets';
import EventLogApi from '@/api/EventLogApi';
import { useDrag } from '@/use/useDrag';
import { useElementEditPopup } from '@/use/useElementEditPopup';
import { useCropImage } from '@/components/layout-element/useCropImage';
import {
	useGridImage,
	MAX_WIDTH,
} from '@zyro-inc/site-modules/components/elements/image/useGridImage';
import { getExtension } from '@zyro-inc/site-modules/utils/modifyString';
import { onClickOutside } from '@/utils/onClickOutside';

import {
	DATA_SELECTOR_IMAGE_PROVIDER,
	GAMIFICATION_TASK_UPDATE_IMAGE,
	IMAGE_ORIGIN_ASSETS,
	DEFAULT_IMAGE_SHAPE_MASK,
} from '@/constants/builderConstants';
import {
	DATA_ATTRIBUTE_SELECTOR,
	DATA_ATTRIBUTE_SELECTOR_IMAGE,
	IMAGE_CLICK_ACTION_LIGHTBOX,
	IMAGE_CLICK_ACTION_LINK,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import { useBuilderMode } from '@/use/useBuilderMode';
import { useGamification } from '@/use/useGamification';
import { useImageIntersectionObserver } from '@/use/useImageIntersectionObserver';
import AiImageGenerationButton from '@/components/builder-controls/AiImageGenerationButton.vue';
import {
	isAiGeneratedImageUrl,
	updateAiGeneratedImagePath,
} from '@/utils/urlValidators';
import { isAiImageGeneratorOpen } from '@/use/useAiImageGenerator';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { useSiteStore } from '@/stores/siteStore';
import { LINK_TYPE } from '@hostinger/builder-schema-validator';
import { useLightbox } from '@zyro-inc/site-modules/components/lightbox/useLightbox';
import { storeToRefs } from 'pinia';
import { ASSET_CATEGORY } from '@/types/fileFormatTypes';
import { getIsDynamicProductPageEnabled } from '@zyro-inc/site-modules/utils/ecommerce/siteModulesUtils';

const UNSUPPORTED_CROP_IMAGE_FORMATS = [
	'svg',
	'ico',
	'gif',
];

const props = defineProps({
	data: {
		type: Object,
		required: true,
	},
	elementId: {
		type: String,
		required: true,
	},
	renderedPosition: {
		type: Object,
		default: null,
	},
	resetMobilePosition: {
		type: Boolean,
		default: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
	isPreviewMode: {
		type: Boolean,
		required: false,
	},
});

const { addImagesToLightbox } = useLightbox();

// #region Store
const {
	state,
	dispatch,
	getters,
} = useStore();

const { isAiBuilderMode } = useBuilderMode();
const currentElementId = computed(() => getters.currentElementId);
const isMobileScreen = computed(() => state.gui.isMobileScreen);

const { completeAchievement } = useGamification();
const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});

const {
	addElement,
	elements,
} = useImageIntersectionObserver();

const {
	startDragging,
	dragDeltaXPosition,
	dragDeltaYPosition,
} = useDrag({
	trackScroll: false,
});

const assetManagerRef = ref(null);
const imageRef = ref(null);
const showAssetManager = ref(false);
const siteStore = useSiteStore();
const {
	siteBlocks,
	sitePages,
	defaultLocale,
	site,
} = storeToRefs(siteStore);

const tagName = computed(() => (props.data.settings.clickAction === IMAGE_CLICK_ACTION_LINK ? 'a' : 'div'));

const siteData = computed(() => siteStore.site);
const locale = computed(() => state.currentLocale);

const href = computed(() => {
	if (props.data.linkType === LINK_TYPE.PAGE) {
		return getPagePathFromId({
			siteData: siteData?.value,
			pageId: props.data.linkedPageId,
			locale: locale?.value,
		});
	}

	return props.data.href;
});

const {
	cropCSSVars,
	desktopTopPercentWithOffset,
	desktopLeftPercentWithOffset,
} = useGridImage(props, {
	dragDeltaYPosition,
	dragDeltaXPosition,
	href,
});

const {
	croppedImageId,
	exitCropMode,
	enterCropMode,
} = useCropImage();

const isImageCropped = computed(() => croppedImageId.value === props.elementId);
const shapeMaskSource = computed(() => {
	// If image is cropped and shape mask is not set, we set default shape mask
	if (isImageCropped.value && !props.data?.shapeMaskSource) {
		return DEFAULT_IMAGE_SHAPE_MASK;
	}

	return props.data?.shapeMaskSource;
});
const isCropPopupVisible = computed(() => croppedImageId.value && croppedImageId.value === props.elementId);

// For now we only allow cropping on desktop
const cropData = computed(() => props.data.desktop.crop);

const showCrop = computed(() => (props.data.settings.origin === 'assets' || isAiGeneratedImageUrl(props.data.settings.path)) && !UNSUPPORTED_CROP_IMAGE_FORMATS.includes(getExtension(props.data.settings.path)));

const isImageInView = computed(() => !elements.value.includes(imageRef.value?.$el));

const src = computed(() => {
	let width = isMobileScreen.value ? props.data.mobile.width : props.data.desktop.width;
	const height = isMobileScreen.value ? props.data.mobile.height : props.data.desktop.height;
	const isImageScaled = props.data.desktop.crop?.scale > 1 && croppedImageId.value === null;

	if (isImageScaled) {
		width = MAX_WIDTH;
	}

	return getOptimizedSrc(props.data.settings.origin, props.data.settings.path, state.websiteId, {
		isLossless: true,
		...(width >= height && {
			width,
		}),
		...(height > width && !isImageScaled && {
			height,
		}),
		shouldContain: true,
	});
});

const handleAssetManagerButtonClick = async () => {
	dispatch('enterElementEditMode');
	showAssetManager.value = true;
};

const handleAssetManagerClose = () => {
	showAssetManager.value = false;
	dispatch('leaveElementEditMode');
};

const setImage = ({ image }) => {
	const {
		origin,
		path,
		alt,
		fullResolutionWidth,
		fullResolutionHeight,
		url,
	} = image;
	const canImageBeCropped = origin === IMAGE_ORIGIN_ASSETS;
	const validPath = updateAiGeneratedImagePath(url, path);

	dispatch('mergeElementData', {
		elementId: props.elementId,
		elementData: {
			settings: {
				origin,
				path: validPath,
				alt,
			},
			fullResolutionWidth,
			fullResolutionHeight,
			...(!canImageBeCropped && {
				desktop: {
					crop: null,
				},
			}),
		},
	});

	handleAssetManagerClose();

	completeAchievement(GAMIFICATION_TASK_UPDATE_IMAGE);
};

const toggleImageGeneratorPopup = ({ location } = {}) => {
	if (!isAiImageGeneratorOpen.value) {
		EventLogApi.logEvent({
			eventName: 'website_builder.ai_image_generator.enter',
			eventProperties: {
				location: `element_${location}`,
			},
		});
	}

	isAiImageGeneratorOpen.value = !isAiImageGeneratorOpen.value;
};

const updateCropData = () => {
	dispatch('mergeElementData', {
		elementId: props.elementId,
		elementData: {
			desktop: {
				crop: {
					left: desktopLeftPercentWithOffset.value,
					top: desktopTopPercentWithOffset.value,
				},
			},
		},
	});
};

const addDefaultCropData = () => {
	// Add default crop data only if it doesn't exist
	if (cropData.value) {
		return;
	}

	dispatch('mergeElementData', {
		elementId: props.elementId,
		elementData: {
			desktop: {
				crop: {
					scale: 1,
					left: 50,
					top: 50,
				},
			},
		},
	});
};

const isZoomInAction = computed(() => props.data.settings.clickAction === IMAGE_CLICK_ACTION_LIGHTBOX);
const isLinkAction = computed(() => props.data.settings.clickAction === IMAGE_CLICK_ACTION_LINK);

const startMovingCropArea = (e) => {
	if (croppedImageId.value !== props.elementId) {
		return;
	}

	e.stopPropagation();

	startDragging({
		onDragEnd: updateCropData,
	});
};

const previewButtonNavigate = async (e) => {
	e.preventDefault();

	if (props.data.settings.isFormButton) {
		return;
	}

	if (props.data.linkType !== LINK_TYPE.ANCHORED_SECTION && props.data.linkedPageId) {
		const isDynamicProductPageEnabled = getIsDynamicProductPageEnabled({
			defaultLocalePages: site.value.languages[defaultLocale.value]?.pages,
		});

		if (isDynamicProductPageEnabled) {
			const [path] = props.data.href.split('#');
			const pageSlug = path.split('/').pop();

			dispatch('updateCurrentLocale', defaultLocale.value);
			dispatch('updateCurrentPageSlug', pageSlug);
		}

		dispatch('updateCurrentPageId', props.data.linkedPageId);

		return;
	}

	if (props.data.linkType === LINK_TYPE.ANCHORED_SECTION && props.data.linkedPageId) {
		const blockId = Object.entries(siteBlocks.value)
			.find(([, { htmlId }]) => htmlId === props.data.linkedPageId)
			?.[0];

		const pageId = Object.entries(sitePages.value).find(([, { blocks }]) => blocks && blocks.includes(blockId))?.[0];

		await dispatch('updateCurrentPageId', pageId);

		if (!blockId) {
			return;
		}

		const element = document.querySelector(`[data-block-id="${blockId}"]`);

		if (!element) {
			return;
		}

		window.requestAnimationFrame(() => {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		});

		return;
	}

	window.open(props.data.href, '_blank');
};

const handleImageClick = (e) => {
	if (!props.isPreviewMode) {
		return;
	}

	if (isLinkAction.value) {
		previewButtonNavigate(e);

		return;
	}

	if (!isZoomInAction.value) {
		return;
	}

	addImagesToLightbox({
		src: getOptimizedSrc(
			props.data.settings.origin,
			props.data.settings.path,
			siteStore.websiteId,
		),
		srcset: getFullWidthSrcset(
			props.data.settings.origin,
			props.data.settings.path,
			siteStore.websiteId,
		),
		alt: props.data.settings.alt,
	});
};

onClickOutside({
	target: imageRef,
	preventSelector: DATA_SELECTOR_IMAGE_PROVIDER,
}, () => {
	if (!croppedImageId.value) return;

	exitCropMode();
});

watch(croppedImageId, (newId) => {
	if (newId === props.elementId) {
		addDefaultCropData();
	}
});

watch(currentElementId, (newCurrentElementId) => {
	if (newCurrentElementId !== props.elementId) {
		exitCropMode();
	}
});

addElement(imageRef);

onUnmounted(() => {
	isAiImageGeneratorOpen.value = false;
});
</script>

<style lang="scss" scoped>
.image {
	height: 100%;
	user-select: none;

	&--zoom-in {
		cursor: zoom-in;
	}

	&--link {
		cursor: pointer
	}

	&--cropped {
		position: relative;
		z-index: 2;
	}

	&--masked {
		position: absolute;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 1;

		:deep() {
			img {
				opacity: 0.5;
			}
		}
	}

	:deep() {
			img[src=""] {
				visibility: hidden;
			}
		}
}
</style>
