import { PINIA_STORES } from '@/constants/stores';
import { defineStore } from 'pinia';
import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
// eslint-disable-next-line import/no-cycle
import {
	validateSiteJSONHandler,
	getErrorMessagePath,
} from '@/utils/savingUtils';
import {
	patcher,
	createDiff,
} from '@/utils/jsondiffpatch';
import {
	addBreadcrumb,
	captureException,
} from '@sentry/vue';

import { saveSite } from '@/api/SitesApi';

import { getSiteMetaFlags } from '@/utils/getSiteMetaFlags';
import { TEMPLATES_ACCOUNT_EMAIL } from '@/constants/builderConstants';
import { useSiteStore } from '@/stores/siteStore';
// eslint-disable-next-line import/no-cycle
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { getIsLocalDevelopmentPlaygroundMode } from '@/utils/getIsLocalDevelopmentPlaygroundMode';
import { SiteData } from '@hostinger/builder-schema-validator';
import { AxiosError } from 'axios';
import { useBuilderMode } from '@/use/useBuilderMode';
import { AI_PREVIEW_ROUTE } from '@/constants/routes';
import { useRoute } from 'vue-router';

const AUTOSAVE_INTERVAL = 120000; // autosave every 2 minutes

export const useSavingStore = defineStore(PINIA_STORES.SAVING, () => {
	const {
		state,
		dispatch,
	} = useStore();
	const route = useRoute();
	const siteStore = useSiteStore();
	const ecommerceStore = useEcommerceStore();
	const { isAiBuilderMode } = useBuilderMode();

	const isSaving = ref(false);
	// timestamp, received after successful save. it was not renamed due to backwards compatibility
	const clientTimestamp = ref<number | null>(null);
	// used to deep compare with current website data and is reset after successful save
	const siteDataSnapshot = ref<SiteData | null>(null);
	// setTimeout identifier
	const timer = ref<number | ReturnType<typeof setInterval>>();

	// explicitly setting/getting diff as undefined because jsondiffpatch returns undefined when objects are deep equal
	const unsavedSiteDataDiff = computed(() => (siteDataSnapshot.value
		? createDiff(siteDataSnapshot.value, siteStore.site)
		: undefined));

	const hasUnsavedSeoChanges = computed(() => {
		const metaUpdates = state.ecommerce.productMetaUpdates;

		return metaUpdates && (Object.keys(metaUpdates).length > 0);
	});

	const hasUnsavedChanges = computed(() => hasUnsavedSeoChanges.value || typeof unsavedSiteDataDiff.value !== 'undefined');

	const canSave = computed<boolean>(() => {
		if (route.name === AI_PREVIEW_ROUTE || isAiBuilderMode.value) {
			return false;
		}

		return state.websiteId && hasUnsavedChanges.value && !isSaving.value;
	});

	const setIsSaving = (value: boolean) => {
		isSaving.value = value;
	};

	const setSiteDataSnapshot = ({ siteData }: {siteData: SiteData}) => {
		siteDataSnapshot.value = patcher.clone(siteData) as SiteData;
	};

	const setClientTimestamp = (value: number) => {
		clientTimestamp.value = value;
	};

	const setTimer = (value: ReturnType<typeof setInterval>) => {
		timer.value = value;
	};

	const clearTimer = () => {
		clearInterval(timer.value);
	};

	const saveWebsite = async ({
		saveWhenImpersonating = false,
		isTimerStarted = true,
	} = {}) => {
		const {
			user,
			websiteId,
		} = state;

		if ((user.user?.isStaff && !saveWhenImpersonating) || getIsLocalDevelopmentPlaygroundMode()) {
			return;
		}

		clearTimer();
		setIsSaving(true);
		addBreadcrumb({
			category: 'CLIENT_TIMESTAMP',
			message: 'Before Save',
			data: {
				clientTimestamp: clientTimestamp.value,
			},
		});
		validateSiteJSONHandler({
			websiteId,
			website: siteStore.site as SiteData,
		});
		try {
			ecommerceStore.updateProductsSeoData();

			const siteMetaFlags = getSiteMetaFlags({
				siteData: siteStore.site,
			});

			const { data } = await saveSite(websiteId, siteStore.site, clientTimestamp.value, siteMetaFlags);

			addBreadcrumb({
				category: 'CLIENT_TIMESTAMP',
				message: 'After Save',
				data: {
					clientTimestamp: data.clientTimestamp,
				},
			});

			setClientTimestamp(data.clientTimestamp);

			setSiteDataSnapshot({
				siteData: siteStore.site as SiteData,
			});
		} catch (error) {
			dispatch('notifications/notify', {
				messageI18nKeyPath: getErrorMessagePath(error as AxiosError),
				submitLabelI18nKeyPath: 'common.reload',
				isDiscardButtonShown: false,
				submitCallback: () => window.location.reload(),
			}, {
				root: true,
			});
			console.error(error);
			captureException(error);

			throw error;
		} finally {
			if (isTimerStarted) {
				// eslint-disable-next-line no-use-before-define
				startSavingTimer();
			}

			setIsSaving(false);
		}
	};

	const startSavingTimer = () => {
		if (state.user.user?.isStaff || [
			TEMPLATES_ACCOUNT_EMAIL.TEMPLATES,
			TEMPLATES_ACCOUNT_EMAIL.AI_TEMPLATES,
		].includes(state.user.user?.email)) {
			return;
		}

		const newTimer = setInterval(() => {
			if (canSave.value) {
				saveWebsite();
			}
		}, AUTOSAVE_INTERVAL);

		setTimer(newTimer);
	};

	return {
		isSaving,
		clientTimestamp,
		siteDataSnapshot,
		timer,
		unsavedSiteDataDiff,
		hasUnsavedSeoChanges,
		hasUnsavedChanges,
		canSave,
		setIsSaving,
		setSiteDataSnapshot,
		setClientTimestamp,
		setTimer,
		clearTimer,
		saveWebsite,
		startSavingTimer,
	};
});
