import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import { captureException } from '@sentry/vue';
import { defineStore } from 'pinia';
import EventLogApi from '@/api/EventLogApi';
import { getDomainWithoutWWWPrefix } from '@zyro-inc/site-modules/utils/domainUtils';

import { PINIA_STORES } from '@/constants/stores';
import {
	NEXT_BEST_ACTION,
	hDomainsStatus,
} from '@hostinger/hdomains-status';
import type {
	NextBestAction,
	IDomainStatus,
} from '@hostinger/hdomains-status';
import { useUserStore } from '@/stores/userStore';
import { hDomainAxiosInstance } from '@/services/http/hDomainStatusAxios';
import { PREVIEW_DOMAINS } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	HRESOURCE_STATE,
	HRESOURCE_TYPE,
} from '@/types/resourceTypes';
import { useResourcesStore } from '@/stores/resourcesStore';

export const useDomainStore = defineStore(PINIA_STORES.DOMAIN, () => {
	const {
		state,
		getters,
		dispatch,
	} = useStore();
	const userStore = useUserStore();

	const resourcesStore = useResourcesStore();

	const customDomain = ref<string>('');
	const domainStatus = ref<IDomainStatus | null>(null);
	const isEligibleForFreeDomain = ref(false);
	const isStatusLoading = ref(false);
	const isFreeDomainEligibilityLoading = ref(false);

	const domainNextBestAction = computed<NextBestAction | null>(() => {
		if (!domainStatus.value) {
			return null;
		}

		return domainStatus.value?.nextBestAction;
	});

	const isCustomDomainTemporary = computed<boolean>(() => domainNextBestAction.value === NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY);

	const isDomainConnected = computed(() => {
		if (!domainNextBestAction.value) {
			return false;
		}

		return ([
			NEXT_BEST_ACTION.MANAGE,
			NEXT_BEST_ACTION.TRANSFER_DOMAIN,
			NEXT_BEST_ACTION.RENEW_DOMAIN,
			NEXT_BEST_ACTION.RESTORE_DOMAIN,
		]as NextBestAction[]).includes(domainNextBestAction.value);
	});

	const setCustomDomain = (domain: string) => {
		customDomain.value = getDomainWithoutWWWPrefix(domain);
	};

	const setHDomainsStatusConfig = () => {
		try {
			hDomainsStatus.setAxiosInstance(hDomainAxiosInstance);
			hDomainsStatus.setConfig({
				WEB_HOSTING_API_URL: `${import.meta.env.VITE_HPANEL_URL}/api/rest-hosting`,
				HDOMAINS_API_URL: `${import.meta.env.VITE_HPANEL_URL}/api/domains/api/direct`,
				PREVIEW_DOMAIN: PREVIEW_DOMAINS,
			});
		} catch (error) {
			captureException(error);
		}
	};

	const trackDomainConnectionStatus = () => {
		const wasPropagationEventAlreadySent = !!getters.builderCompletedSteps?.isDomainPropagatedEventSent;

		if (wasPropagationEventAlreadySent || !domainNextBestAction.value) {
			return;
		}

		const isDomainPropagated = ([
			NEXT_BEST_ACTION.MANAGE,
			NEXT_BEST_ACTION.TRANSFER_DOMAIN,
			NEXT_BEST_ACTION.RENEW_DOMAIN,
			NEXT_BEST_ACTION.PENDING_VERIFICATION,
		] as NextBestAction[]).includes(domainNextBestAction.value);

		if (isDomainPropagated) {
			EventLogApi.logEvent({
				eventName: 'website_builder.domain.propagated',
				eventProperties: {
					website_id: state.websiteId,
					domain_name: customDomain.value,
				},
			});

			dispatch('addBuilderCompletedStep', {
				isDomainPropagatedEventSent: true,
			});
		}
	};

	const fetchDomainStatus = async () => {
		if (!userStore.userHpanelId) {
			return;
		}

		try {
			isStatusLoading.value = true;
			const hDomainStatus = await hDomainsStatus.getDomainStatus({
				domain: customDomain.value,
				currentClientId: userStore.userHpanelId,
			});

			isStatusLoading.value = false;
			domainStatus.value = hDomainStatus.domainStatus;

			trackDomainConnectionStatus();
		} catch (error) {
			captureException(error);
		}
	};

	const fetchFreeDomainEligibility = async () => {
		try {
			isFreeDomainEligibilityLoading.value = true;

			const freeDomainResources = resourcesStore.getResourcesByTypes([HRESOURCE_TYPE.FREE_DOMAIN]);

			isEligibleForFreeDomain.value = freeDomainResources.some((resource: any) => resource?.state === HRESOURCE_STATE.PENDING);
		} catch (error) {
			captureException(error);
		}

		isFreeDomainEligibilityLoading.value = false;
	};

	return {
		customDomain,
		domainStatus,
		domainNextBestAction,
		isDomainConnected,
		isEligibleForFreeDomain,
		isStatusLoading,
		isFreeDomainEligibilityLoading,
		isCustomDomainTemporary,
		setCustomDomain,
		setHDomainsStatusConfig,
		fetchDomainStatus,
		fetchFreeDomainEligibility,
	};
});
