<script setup lang="ts">
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import BlockEditControls from '@/components/builder-controls/BlockEditControls.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';
import BlockControls from '@/components/block/BlockControls.vue';
import BlockEcommerceProduct from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProduct.vue';
import EditBlockEcommerceProductTabs from '@/components/builder-controls/edit-block-ecommerce-product/EditBlockTabsEcommerceProduct.vue';
import { useBlockEcommerceProduct } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProduct';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	DEFAULT_EMPTY_PRODUCT_VALUE,
	DEMO_PRODUCTS,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	computed,
	ref,
	watch,
} from 'vue';
import {
	ECOMMERCE_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
} from '@/constants/builderConstants';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import { useSiteStore } from '@/stores/siteStore';
import { SiteBlock } from '@hostinger/builder-schema-validator';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { useNotifications } from '@/use/useNotifications';
import { useLightbox } from '@zyro-inc/site-modules/components/lightbox/useLightbox';
import { getProductByIdOrSlug } from '@zyro-inc/site-modules/api/StoreApi';
import { storeToRefs } from 'pinia';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';

const EDIT_POPUP_OPTIONS = {
	placement: 'right-start',
	placementMobile: 'right-start',
	flip: false,
	offset: {
		mainAxis: 8,
		crossAxis: 24,
	},
	offsetMobile: {
		mainAxis: 24,
		crossAxis: 0,
	},
	padding: 8,
	autoUpdate: true,
};

type Props = {
	blockId: string;
	data: SiteBlock;
	areDemoProductsShown?: boolean;
	ecommerceTranslations: Record<string, string>;
	currentPageType?: string;
	isFirstBlock?: boolean;
	transparentHeaderHeight?: number;
	isPreviewMode?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	areDemoProductsShown: false,
	currentPageType: 'default',
	isFirstBlock: false,
	transparentHeaderHeight: 0,
});

const {
	state,
	getters,
	dispatch,
} = useStore();
const siteStore = useSiteStore();
const {
	currentPage,
	siteMeta,
} = storeToRefs(siteStore);
const { t } = useI18n();

const { notify } = useNotifications();

const {
	productId: blockProductId,
	blockStyle,
	blockButtonText,
	blockButtonStyle,
	blockButtonType,
	blockButtonBorderWidth,
	textColorVars,
	imageBorderRadius,
	navigationArrowsColor,
	navigationThumbnailArrowsColor,
	galleryPlacement,
	imageRatio,
	isQuantityPickerEnabled,
} = useBlockEcommerceProduct(props);
const {
	customAnimationClass,
	animationAttributeStateValue,
} = useSiteEngineAnimations({
	data: props.data,
});
const ecommerceAdminStore = useEcommerceAdminStore();
const ecommerceStore = useEcommerceStore();
const { addImagesToLightbox } = useLightbox();

const previewAnimationState = ref<string | null>(null);

const setAnimationState = () => {
	setTimeout(() => {
		previewAnimationState.value = DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE;
	}, 100);
};

const isLoading = ref(false);

const ecommerceProductRef = ref<InstanceType<typeof BlockEcommerceProduct> | null>(null);
const product = ref<EcommerceProduct>(DEFAULT_EMPTY_PRODUCT_VALUE as EcommerceProduct);

const websiteId = computed(() => state.websiteId);
const isMobileView = computed(() => state.gui.isMobileView);
const shoppingCartItems = computed(() => state.ecommerce?.shoppingCartItems);
const variantsQuantity = computed(() => state.ecommerce?.variantsQuantity);
const productId = computed(() => ecommerceStore.currentDynamicPageProductId || blockProductId.value);
const isCartVisible = computed(() => getters['ecommerce/isCartVisible']);
const products = computed(() => [
	...ecommerceStore.products,
	...ecommerceStore.hiddenProducts,
]);
const productsSeo = computed(() => ecommerceStore.productsSeo);

const setProduct = async () => {
	if (props.areDemoProductsShown) {
		product.value = DEMO_PRODUCTS[0] as EcommerceProduct;

		if (props.isPreviewMode) {
			setAnimationState();
		}

		return;
	}

	if (props.isPreviewMode && currentPage.value?.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT) {
		isLoading.value = true;
		setAnimationState();

		if (product.value.id !== -1) {
			ecommerceProductRef.value?.$el.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}

		product.value = await getProductByIdOrSlug({
			storeId: getStoreId(siteMeta.value),
			slug: !productId.value || productId.value === -1 ? state.currentPageSlug : undefined,
			productId: productId.value !== -1 ? productId.value : undefined,
		});

		isLoading.value = false;

		return;
	}

	if (productId.value === -1) {
		product.value = DEFAULT_EMPTY_PRODUCT_VALUE;

		if (props.isPreviewMode) {
			setAnimationState();
		}

		return;
	}

	const foundProduct = products.value.find((item) => item.id === productId.value);
	const isProductHidden = productsSeo.value.find((seoData) => seoData.id === productId.value)?.is_hidden;

	if (foundProduct) {
		product.value = foundProduct;
	} else {
		const fetchedProduct = await ecommerceStore.fetchProductById({
			productId: productId.value,
			blockId: props.blockId,
			isHidden: isProductHidden,
		});

		product.value = fetchedProduct || DEFAULT_EMPTY_PRODUCT_VALUE as EcommerceProduct;
	}

	if (props.isPreviewMode) {
		setAnimationState();
	}
};

const handleEditProductClick = () => {
	if (productId.value !== -1) {
		ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_EDIT,
			params: {
				[REDIRECT_PARAM_KEYS.PRODUCT]: productId.value,
			},
		});
	} else {
		ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
			isOpen: true,
			path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS_ADD,
		});
	}
};

const closeBlockEditPopupHandler = () => {
	dispatch('leaveBlockEditMode');
};

const handleAddToCartClick = () => {
	if (!props.isPreviewMode) {
		return;
	}

	notify({
		messageI18nKeyPath: 'onlineStore.checkoutModalDisabledPreview',
		theme: 'info',
	});
};

const handleImageClick = (index: number) => {
	if (!props.isPreviewMode) {
		return;
	}

	const productMedia = product.value.images || product.value.media || [];
	const aggregatedImages = productMedia.map((image) => ({
		alt: product.value.title,
		src: image.url,
	}));

	addImagesToLightbox(aggregatedImages, index);
};

watch(() => ecommerceAdminStore.isEcommerceDataBeingUpdatedAfterChangesInAdmin, (value: boolean) => {
	if (props.isPreviewMode || !value) {
		return;
	}

	setProduct();
});

watch(() => [
	state.currentPageSlug,
	productId.value,
], async () => {
	await setProduct();
}, {
	immediate: true,
});
</script>

<template>
	<BlockEcommerceProduct
		ref="ecommerceProductRef"
		v-qa="'builder-section-zyroecommerceproduct'"
		v-bind="{
			blockId,
			blockStyle,
			blockButtonText,
			blockButtonStyle,
			blockButtonType,
			blockButtonBorderWidth,
			textColorVars,
			imageBorderRadius,
			navigationArrowsColor,
			navigationThumbnailArrowsColor,
			galleryPlacement,
			imageRatio,
			isQuantityPickerEnabled,
			currentPageType
		}"
		:is-loading="ecommerceStore.isLoadingProducts[blockId] || isLoading"
		:product-data="product"
		:is-mobile-view="isMobileView"
		:is-cart-visible="isCartVisible"
		:translations="ecommerceTranslations"
		:class="customAnimationClass"
		:site-id="websiteId"
		:shopping-cart-items="shoppingCartItems"
		:variants-quantity="variantsQuantity"
		:[DATA_ATTRIBUTE_ANIMATION_STATE]="isPreviewMode ? previewAnimationState : animationAttributeStateValue"
		@buy-button-click="handleAddToCartClick"
		@image-click="handleImageClick"
	/>
	<template v-if="!isPreviewMode">
		<BlockControls
			:block-id="blockId"
			:target-ref="ecommerceProductRef?.$el"
			:is-first-block="isFirstBlock"
			:transparent-header-height="transparentHeaderHeight"
		/>
		<BlockEditControls
			:block-id="blockId"
			:target-ref="ecommerceProductRef?.$el"
			:is-duplicate-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
			:is-delete-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
			:is-context-menu-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
			is-reordering-button-visible
			:is-visibility-button-visible="!siteStore.isCurrentPageTypeEcommerceProduct"
			:edit-popup-options="EDIT_POPUP_OPTIONS"
			:enter-edit-mode-button-title="t('builder.editBlockButton.editSection')"
		>
			<template #additional-edit-buttons>
				<VerticalSeparator />
				<ControlsTooltip>
					<HostingerButton
						v-qa="'builder-section-btn-assignproduct'"
						button-type="plain"
						theme="highlight"
						@click="handleEditProductClick"
					>
						<span class="text-body-2">{{ productId !== -1 ? $t('builder.manageProduct') : $t('builder.addProduct') }}</span>
					</HostingerButton>
				</ControlsTooltip>
			</template>

			<template #edit-mode-popup>
				<EditBlockEcommerceProductTabs
					:block-id="blockId"
					:is-product-purchasable="product.purchasable ?? true"
					@close="closeBlockEditPopupHandler"
				/>
			</template>
		</BlockEditControls>
	</template>
</template>
