import { defineStore } from 'pinia';
import {
	computed,
	nextTick,
} from 'vue';
import { useSiteStore } from '@/stores/siteStore';
import { generateRandomId } from '@/utils/generateRandomId';
import { PINIA_STORES } from '@/constants/stores';
import {
	BLOCK_TYPE_BLOG_LIST,
	ELEMENT_TYPE_IMAGE,
	ELEMENT_TYPE_TEXT_BOX,
	SYSTEM_LOCALE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useStore } from 'vuex';
import { filterObject } from '@zyro-inc/site-modules/utils/object';
import {
	SiteBlogCategory,
	SiteData,
	SitePage,
} from '@hostinger/builder-schema-validator';
import { PAGE_TYPE_BLOG } from '@/constants/builderConstants';
import { addBlogPostTemplate } from '@/utils/siteDataUtils';
import { useUserStore } from '@/stores/userStore';
import {
	addBlogListToExistingPage,
	addBlogPostsIfNeeded,
	createBlogListBlock,
	createNewBlogListPage,
	updateBlogTextElementDOMPositions,
} from '@/utils/blog';

export const useBlogStore = defineStore(PINIA_STORES.BLOG, () => {
	const siteStore = useSiteStore();
	const userStore = useUserStore();
	// Until index.ts is migrated we need dispatch here
	const { dispatch } = useStore();

	// Getters
	const blogReadingTimeText = computed(() => siteStore.site.languages[siteStore.currentLocale].blogReadingTimeText);
	const blogPages = computed(() => filterObject(
		siteStore.sitePages,
		({ value }: { value: SitePage }) => value.type === PAGE_TYPE_BLOG,
	));

	const draftBlogPages = computed(() => filterObject(
		blogPages.value,
		({ value }: { value: SitePage }) => value.isDraft && !value.isScheduled,
	));

	const scheduledBlogPages = computed(() => filterObject(
		blogPages.value,
		({ value }: { value: SitePage }) => value.isScheduled,
	));

	const publishedBlogPages = computed(() => filterObject(
		blogPages.value,
		({ value }: { value: SitePage }) => !value.isDraft && !value.isScheduled,
	));

	const blogCategories = computed(() => siteStore.site.blogCategories);
	const categoriesNames = computed(() => Object.values(blogCategories.value).map((value) => value.name));
	const isLocaleWithBlogList = computed(() => Object.values(siteStore.siteBlocks).some((block) => block.type === BLOCK_TYPE_BLOG_LIST));
	const hasBlogPages = computed(() => Object.keys(blogPages.value).length > 0);
	const hasBlog = computed(() => hasBlogPages.value || isLocaleWithBlogList.value);

	// Actions
	const getCategoryIdByName = (categoryName: string) => Object.entries(blogCategories.value)
		.find(([, category]) => category.name === categoryName)?.[0];

	const getCategoryNameById = (categoryId: string) => blogCategories.value[categoryId]?.name;

	const getPostCategoryNames = (postId: string) => {
		const page = siteStore.sitePages[postId];

		if (!page || !page.categories) {
			throw new Error(`Post with id "${postId}" not found or has no categories`);
		}

		return page.categories.map((categoryId) => blogCategories.value[categoryId].name);
	};

	const getPostCategories = (postId: string) => siteStore.sitePages[postId].categories;

	const getBlogListCategoryNames = (blockId: string) => (siteStore.siteBlocks[blockId].settings.categories ?? [])
		.map((categoryId) => blogCategories.value[categoryId].name);

	const toggleBlogPostVisibility = (pageId: string) => {
		const blogPage = siteStore.site.languages[siteStore.currentLocale].pages[pageId];

		if (blogPage.isScheduled) {
			blogPage.isScheduled = false;
			blogPage.isDraft = false;

			return;
		}

		blogPage.isDraft = !blogPage.isDraft;
	};

	const addCategory = ({
		categoryName,
		id = generateRandomId(),
	}: {
		categoryName: string,
		id?: string,
	}): string => {
		if (siteStore.site.blogCategories[id]) {
			throw new Error(`Category with id ${id} already exists`);
		}

		siteStore.site.blogCategories[id] = {
			name: categoryName,
		};

		return id;
	};

	const addPostToCategory = ({
		postPageId,
		categoryName,
	}:{
		postPageId: string,
		categoryName: string,
	}) => {
		if (!siteStore.site.languages[siteStore.currentLocale].pages[postPageId]) {
			throw new Error(`Post with id ${postPageId} not found`);
		}

		let categoryId = getCategoryIdByName(categoryName);

		if (!categoryId) {
			categoryId = addCategory({
				categoryName,
			});
		}

		const wasPostCategoryAdded = siteStore.site.languages[siteStore.currentLocale].pages[postPageId].categories?.push(categoryId);

		if (!wasPostCategoryAdded) {
			throw new Error(`Failed to add category with id ${categoryId} to post with id ${postPageId}`);
		}
	};

	const addBlogListCategory = ({
		blockId,
		categoryName,
	}:{
		blockId: string,
		categoryName: string
	}) => {
		if (!siteStore.site.languages[siteStore.currentLocale].blocks[blockId]) {
			throw new Error(`Block with id ${blockId} not found`);
		}

		let categoryId = getCategoryIdByName(categoryName);

		if (!categoryId) {
			categoryId = addCategory({
				categoryName,
			});
		}

		const wasBlogListCategoryAdded = siteStore
			.site
			.languages[siteStore.currentLocale]
			.blocks[blockId]
			.settings
			.categories
			?.push(categoryId);

		if (!wasBlogListCategoryAdded) {
			throw new Error(`Failed to add category with id ${categoryId} to block with id ${blockId}`);
		}
	};

	const editCategory = ({
		oldName,
		newName,
	}:{
		oldName: string,
		newName: string,
	}) => {
		const categoryId = getCategoryIdByName(oldName);

		if (!categoryId) {
			throw new Error(`Category with name ${oldName} not found`);
		}

		siteStore.site.blogCategories[categoryId].name = newName;
	};

	// Blog categories are global - meaning they are reused across all locales.
	// So when a category is removed from the main settings popup, it has to be removed from all Blog List blocks and Blog pages.
	const removeCategory = (id: string) => {
		siteStore.site.languages = Object.fromEntries(Object.entries(siteStore.site.languages).map(([languageKey, languageData]) => {
			const updatedPages = Object.fromEntries(Object.entries(languageData.pages).map(([pageKey, pageData]) => [
				pageKey,
				{
					...pageData,
					...(pageData.type === PAGE_TYPE_BLOG && {
						categories: pageData?.categories?.filter((categoryId) => categoryId !== id),
					}),
				},
			]));

			const updatedBlocks = Object.fromEntries(Object.entries(languageData.blocks).map(([blockKey, blockData]) => [
				blockKey,
				{
					...blockData,
					...(blockData.type === BLOCK_TYPE_BLOG_LIST && {
						settings: {
							...blockData.settings,
							categories: (blockData.settings.categories ?? []).filter((categoryId) => categoryId !== id),
						},
					}),
				},
			]));

			return [
				languageKey,
				{
					...languageData,
					pages: updatedPages,
					blocks: updatedBlocks,
				},
			];
		}));

		delete siteStore.site.blogCategories[id];
	};

	const setCategories = (categories: Record<string, SiteBlogCategory>) => {
		siteStore.site.blogCategories = categories;
	};

	const calculateReadTime = (pageId: string) => {
		// Collect all components that are inside the blog post.
		const pageBlocksIds = siteStore.sitePages[pageId].blocks;

		if (!pageBlocksIds) {
			throw new Error(`Couldn't calculate page read time. Page with id ${pageId} not found`);
		}

		const excludedBlockTypes = ['BlockBlogHeader'];

		const filteredPageBlockIds = pageBlocksIds
			.filter((blockId) => !excludedBlockTypes.includes(siteStore.siteBlocks[blockId].type));

		const filteredComponentIds = filteredPageBlockIds
			.flatMap((blockId) => siteStore.siteBlocks[blockId].components);

		const minImageReadTime = 3;
		const wordReadTime = 0.22;
		let imageReadTime = 12;
		let readTime = 0;

		const domParser = new DOMParser();

		filteredComponentIds.forEach((componentId) => {
			if (!componentId) {
				return;
			}

			// Clean up TextBox content from HTML, count amount of words and get read time.
			const component = siteStore.siteElements[componentId];

			if (component.type === ELEMENT_TYPE_TEXT_BOX && component.content) {
				const cleanContent = domParser.parseFromString(component.content, 'text/html').body.textContent ?? '';
				const wordsCount = cleanContent.match(/\b[\w()+?-]+\b/gi)?.length;

				readTime += wordsCount ? wordsCount * wordReadTime : 0;
			}

			// Add imageReadTime for each image. After each image addition, lower the reading time by 1.
			// When the imageReadTime reaches min reading time, stop reducing and use the min value.
			if (component.type === ELEMENT_TYPE_IMAGE) {
				readTime += imageReadTime === minImageReadTime ? minImageReadTime : imageReadTime;
				imageReadTime = imageReadTime === minImageReadTime ? minImageReadTime : imageReadTime - 1;
			}
		});
		// Normalize the read time. If it is less than 60 seconds, treat like 1 minute.
		// Else, just convert seconds into minutes and use that number as the final value.
		const readTimeInMinutes = readTime / 60;
		const normalizedReadTime = readTimeInMinutes < 1 ? 1 : Math.round(readTimeInMinutes);

		dispatch('mergePageData', {
			pageId,
			pageData: {
				minutesToRead: `${normalizedReadTime}`,
			},
		}, {
			root: true,
		});
	};

	const addBlogPostPage = async ({
		postTitle,
		postDescription,
		postContent,
		postThumbnail,
	}:{
		postTitle: string,
		postDescription: string,
		postContent: string,
		postThumbnail: { path: string, alt: string, origin: string },
	}) => {
		const {
			siteDataWithBlogPostPage,
			pageId: blogPageId,
		} = addBlogPostTemplate({
			locale: siteStore.currentLocale,
			siteData: siteStore.site,
			postTitle,
			postDescription,
			postContent,
			postThumbnail,
			isDraft: true,
		});

		dispatch('overwriteWebsiteData', {
			websiteData: siteDataWithBlogPostPage,
		});
		dispatch('updateCurrentPageId', blogPageId);
		// Temporary reset undo/redo after page add/remove, to assure reliable data flow.
		dispatch('undoRedo/resetUndoRedo');
	};

	const addBlog = async ({
		existingPageId,
		existingPagePreviousBlockId,
		pageTitle,
		blogPostsData,
		postsPerPage,
		postColumnCount,
		pageId = generateRandomId(),
	}: {
		pageTitle: string,
		blogPostsData: any[],
		postColumnCount?: number,
		postsPerPage?: number,
		existingPagePreviousBlockId?: string,
		pageId?: string,
		existingPageId?: string,
	}) => {
		const locale = siteStore.isCurrentPageEmpty ? SYSTEM_LOCALE : siteStore.currentLocale;

		const blogListBlock = createBlogListBlock({
			postColumnCount,
			postsPerPage,
		});

		const siteData = addBlogPostsIfNeeded({
			siteData: siteStore.site as SiteData,
			locale,
			blogPostsData,
		});

		let siteDataWithBlogListAdded;

		if (existingPageId) {
			siteDataWithBlogListAdded = addBlogListToExistingPage({
				siteData: siteData as SiteData,
				locale,
				pageId: siteStore.currentPageId,
				blockId: blogListBlock.id,
				blockData: blogListBlock.data,
				previousBlockId: existingPagePreviousBlockId,
			});
		} else {
			siteDataWithBlogListAdded = createNewBlogListPage({
				siteData: siteData as SiteData,
				locale,
				pageId,
				pageTitle,
				blogListBlock,
				pages: siteStore.currentSiteLanguage.pages,
			});
		}

		dispatch('overwriteWebsiteData', {
			websiteData: siteDataWithBlogListAdded,
		});

		dispatch('updateCurrentPageId', existingPageId ? siteStore.currentPageId : pageId);
		dispatch('undoRedo/resetUndoRedo');

		if (existingPageId && userStore.user) {
			window.hj('identify', userStore.user.id, {
				'builder.blog.insert_blog_list': true,
			});
		}

		await nextTick();

		updateBlogTextElementDOMPositions({
			siteData: siteDataWithBlogListAdded,
			locale,
			pageId: existingPageId || pageId,
			dispatch,
		});
	};

	return {
		blogReadingTimeText,
		blogCategories,
		blogPages,
		draftBlogPages,
		scheduledBlogPages,
		publishedBlogPages,
		categoriesNames,
		isLocaleWithBlogList,
		hasBlogPages,
		hasBlog,
		toggleBlogPostVisibility,
		addPostToCategory,
		addBlogListCategory,
		addCategory,
		editCategory,
		removeCategory,
		setCategories,
		calculateReadTime,
		getCategoryIdByName,
		getCategoryNameById,
		getPostCategoryNames,
		getPostCategories,
		getBlogListCategoryNames,
		addBlogPostPage,
		addBlog,
	};
});
