<template>
	<section
		ref="blockRef"
		class="block"
		:style="computedStyles"
		:class="[
			{
				'block--desktop-hidden': isPreviewMode && !isMobileView && isDesktopBlockHidden,
				'block--mobile-hidden': isPreviewMode && isMobileView && isMobileBlockHidden,
				'block--desktop-first-visible': isPreviewMode && isFirstVisibleDesktopBlock,
				'block--mobile-first-visible': isPreviewMode && isFirstVisibleMobileBlock,
			},
			isPreviewMode ? animationClass : ''
		]"
		@contextmenu="!isPreviewMode && openLayoutBlockContextMenu($event, blockId)"
	>
		<BlockBackground
			v-if="data.background"
			class="block__background"
			:class="{ 'block__background--fixed': isFixedMobileBackground }"
			:overlay-opacity="data.background['overlay-opacity']"
			:type="data.background.current"
			:gradient="data.background.gradient"
			:color="data.background.color"
			:src="backgroundSrc"
			:srcset="backgroundSrcSet"
			:is-fixed="isFixedBlockBackground"
			:alt="data.background && data.background.alt"
			:video-poster="data.background.video?.videoThumbnailSrc"
		/>
		<VisibilityTooltip
			:is-hidden-desktop="isHiddenDesktop"
			:is-hidden-mobile="isHiddenMobile"
		/>
		<BlockLayoutProviderBuilder
			v-if="data.type === BLOCK_TYPE_LAYOUT"
			:is-preview-mode="isPreviewMode"
			:data="data"
			:block-id="blockId"
			:components="components"
			:are-controls-disabled="areControlsDisabled"
			:is-first-block="isFirstBlock"
			:is-last-block="isLastBlock"
			:transparent-header-height="transparentHeaderHeight"
			@set-edit-control-visibility="$emit('set-edit-control-visibility', $event)"
		/>
		<BlockBlogHeaderProviderBuilder
			v-else-if="data.type === BLOCK_TYPE_BLOG_HEADER"
			:data="data"
			:block-id="blockId"
			:transparent-header-height="transparentHeaderHeight"
			:is-preview-mode="isPreviewMode"
		/>
		<BlockBlogListProviderBuilder
			v-else-if="data.type === BLOCK_TYPE_BLOG_LIST"
			:data="data"
			:block-id="blockId"
			:are-demo-posts-shown="areDemoPostsShown"
			:is-first-block="isFirstBlock"
			:transparent-header-height="transparentHeaderHeight"
			:is-preview-mode="isPreviewMode"
		/>
		<BlockImageSlideshowProviderBuilder
			v-else-if="data.type === BLOCK_TYPE_IMAGE_SLIDESHOW"
			:data="data"
			:block-id="blockId"
			:is-first-block="isFirstBlock"
			:is-last-block="isLastBlock"
			:transparent-header-height="transparentHeaderHeight"
			:is-preview-mode="isPreviewMode"
		/>
		<BlockEcommerceProductProviderBuilder
			v-else-if="data.type === BLOCK_TYPE_ECOMMERCE_PRODUCT"
			:data="data"
			:block-id="blockId"
			:are-demo-products-shown="areDemoProductsShown"
			:ecommerce-translations="ecommerceTranslations"
			:current-page-type="currentPageType"
			:is-first-block="isFirstBlock"
			:transparent-header-height="transparentHeaderHeight"
			:is-preview-mode="isPreviewMode"
		/>
		<BlockEcommerceProductListProviderBuilder
			v-else-if="data.type === BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST"
			:data="data"
			:block-id="blockId"
			:current-locale="currentLocale"
			:are-demo-products-shown="areDemoProductsShown"
			:ecommerce-translations="ecommerceTranslations"
			:is-first-block="isFirstBlock"
			:transparent-header-height="transparentHeaderHeight"
			:is-mobile-view="isMobileView"
			:demo-product-count="demoProductCount"
			:is-preview-mode="isPreviewMode"
		/>
	</section>
</template>

<script setup lang="ts">
import {
	StyleValue,
	computed,
	ref,
	watch,
	onMounted,
	onBeforeUnmount,
	ComponentPublicInstance,
} from 'vue';
import { useStore } from 'vuex';

import BlockBackground from '@zyro-inc/site-modules/components/blocks/BlockBackground.vue';
import VisibilityTooltip from '@/components/builder-view/VisibilityTooltip.vue';
import {
	BLOCK_TYPE_LAYOUT,
	BLOCK_TYPE_BLOG_HEADER,
	BLOCK_TYPE_BLOG_LIST,
	BLOCK_TYPE_IMAGE_SLIDESHOW,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
	BACKGROUND_TYPES,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { SIDEBAR_WIDTH } from '@/constants/builderConstants';
import { FULL_WIDTH } from '@zyro-inc/site-modules/utils/getGridItemSize';
import {
	getFullWidthSrcset,
	getOptimizedSrc,
} from '@zyro-inc/site-modules/utils/getSrcsets';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import BlockLayoutProviderBuilder from '@/components/block-layout/BlockLayoutProviderBuilder.vue';
import BlockBlogListProviderBuilder from '@/components/blocks/BlockBlogListProviderBuilder.vue';
import BlockBlogHeaderProviderBuilder from '@/components/blocks/BlockBlogHeaderProviderBuilder.vue';
import BlockEcommerceProductListProviderBuilder from '@/components/blocks/BlockEcommerceProductListProviderBuilder.vue';
import BlockEcommerceProductProviderBuilder from '@/components/blocks/BlockEcommerceProductProviderBuilder.vue';
import BlockImageSlideshowProviderBuilder from '@/components/blocks/BlockImageSlideshowProviderBuilder.vue';
import { useLayoutContextMenu } from '@/components/context-menu/useLayoutContextMenu';
import { useContextMenu } from '@/components/context-menu/useContextMenu';
import {
	blockHeightOnResize,
	resizedSectionId,
} from '@/use/useSectionResizing';
import { SiteBlock } from '@hostinger/builder-schema-validator';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';

type Props = {
	blockId: string;
	data: SiteBlock;
	// In AddBlockModal.vue, components are not aware of the context where they are at.
	// For example, BlockBlogList.vue shows mock posts if there are none to show in preview.
	// But it does not know when it should show mock posts - so a flag which tells in what context it is rendered is needed.
	// This is short term until we think of a better architecture for such cases.
	areControlsDisabled?: boolean;
	areDemoProductsShown?: boolean;
	areDemoPostsShown?: boolean;
	components?: Record<string, any>;
	blocks?: Record<string, any>;
	isFirstBlock?: boolean;
	isLastBlock?: boolean;
	transparentHeaderHeight?: number;
	demoProductCount?: number;
	isPreviewMode?: boolean;
	isFirstVisibleDesktopBlock?: boolean,
	isFirstVisibleMobileBlock?: boolean,
};
type Emits = {
	'set-edit-control-visibility': [boolean];
};

const props = withDefaults(defineProps<Props>(), {
	areControlsDisabled: false,
	areDemoProductsShown: false,
	areDemoPostsShown: false,
	components: () => ({}),
	blocks: () => ({}),
	isFirstBlock: false,
	isLastBlock: false,
	transparentHeaderHeight: 0,
	demoProductCount: 0,
});

defineEmits<Emits>();

const blockRef = ref<ComponentPublicInstance<HTMLElement> | null>(null);

const {
	state,
	getters,
} = useStore();
const {
	setSelectedLayoutBlockId,
	setSelectedLayoutElementId,
} = useLayoutContextMenu();
const { openContextMenu } = useContextMenu();

const {
	addIntersectionObserver,
	observe,
	intersectionObserver,
	animationClass,
} = useSiteEngineAnimations({
	elementData: props.data,
	data: props.data,
	elementId: props.blockId,
	blockId: props.blockId,
} as any);

const isDesktopBlockHidden = computed(() => props.data.desktop?.isHidden);
const isMobileBlockHidden = computed(() => props.data.mobile?.isHidden);

const { isLoaded: isEcommerceProductsLoaded } = useEcommerceGlobal({
	blockId: props.blockId,
});

const initiateAnimations = async () => {
	addIntersectionObserver();
};

watch(() => isEcommerceProductsLoaded.value, async () => {
	if (isEcommerceProductsLoaded.value && props.isPreviewMode && blockRef.value) {
		await observe(blockRef.value);
	}
});

onMounted(async () => {
	if (!props.isPreviewMode) {
		return;
	}

	await initiateAnimations();
});

onBeforeUnmount(() => {
	if (!props.isPreviewMode) {
		return;
	}

	(intersectionObserver.value as any)?.disconnect();
});

const isEditingTextBoxElement = computed(() => getters.isEditingTextBoxElement);

const currentPage = computed(() => getters.currentPage);
const currentPageType = computed(() => currentPage.value.type);
const backgroundSrc = computed(() => {
	if (props.data.background?.current === BACKGROUND_TYPES.VIDEO) {
		return props.data.background.video?.videoSrc;
	}

	return getOptimizedSrc(
		props.data.background?.origin as string,
		props.data.background?.path as string,
		state.websiteId,
		{
			width: FULL_WIDTH,
		},
	);
});

const blockHeightWhileResizing = computed(() => {
	if (!blockHeightOnResize.value) {
		return 'fit-content';
	}

	const isCurrentBlockResized = resizedSectionId.value === props.blockId;
	const blockHeight = blockHeightOnResize.value + props.transparentHeaderHeight;

	return isCurrentBlockResized ? `${blockHeight}px` : 'fit-content';
});
const computedStyles = computed(() => objectToCssVariables(props.data?.settings?.styles) as StyleValue);
const backgroundSrcSet = computed(
	() => getFullWidthSrcset(props.data.background?.origin as string, props.data.background?.path as string, state.websiteId),
);

const ecommerceTranslations = computed(() => {
	if (!getters['ecommerce/isStoreTypeZyro']) {
		return {};
	}

	return getters.ecommerceShoppingCart?.translations ?? {};
});

const isMobileView = computed(() => state.gui.isMobileView);
const isHiddenDesktop = computed(() => !isMobileView.value && !!props.data.desktop?.isHidden);
const isHiddenMobile = computed(() => isMobileView.value && !!props.data.mobile?.isHidden);
const currentLocale = computed(() => state.currentLocale);
const isFixedBlockBackground = computed(() => props.data.attachment === 'fixed');
const sidebarWidth = computed(() => (props.isPreviewMode ? '0px' : `${SIDEBAR_WIDTH}px`));
const isFixedMobileBackground = computed(() => isFixedBlockBackground.value && isMobileView.value);

const openLayoutBlockContextMenu = (event: Event, id: string) => {
	if (isEditingTextBoxElement.value) {
		return;
	}

	event.preventDefault();

	openContextMenu(event);
	setSelectedLayoutBlockId(id);
	setSelectedLayoutElementId(null);
};
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/components/Block";

.block {
	position: relative;
	grid-area: 1 / 1 / -1 / -1;
	height: v-bind(blockHeightWhileResizing);

	&--desktop-hidden {
		display: none;
	}

	&--mobile-hidden {
		display: none;
	}

	&__background {
		&--fixed {
			:deep(.block-background__image) {
				width: $media-mobile-editor;

				// this is required to place fixed image in the center of mobile mode editor
				margin-left: calc((100% + v-bind(sidebarWidth) - $media-mobile-editor) / 2);
			}
		}
	}
}
</style>
