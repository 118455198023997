import { PINIA_STORES } from '@/constants/stores';
import { defineStore } from 'pinia';
import {
	LOCAL_STORAGE_KEY_IS_REFERRAL_NUDGE_CLOSED,
	DAYS_TO_DISABLE_REFERRAL_PROMO,
} from '@/constants/builderConstants';
import {
	getIsExperimentActive,
	EXPERIMENT_IDS,
	EXPERIMENT_VARIANTS,
} from '@/utils/experiments';
import EventLogApi from '@/api/EventLogApi';
import { HRESOURCE_TYPE } from '@/types/resourceTypes';
import { useResourcesStore } from '@/stores/resourcesStore';
import {
	computed,
	ref,
	watch,
} from 'vue';
import { useStore } from 'vuex';
import HBillingApi from '@/api/HBillingApi';
import { useNotifications } from '@/use/useNotifications';
import { captureException } from '@sentry/vue';
import { authRedirectService } from '@/services/http/authRedirectService';
import {
	setIsSiteUpdatedFromLocalStorage,
	getIsSiteUpdatedFromLocalStorage,
} from '@/utils/helpers';

export const getIsReferralDisabled = (timestamp: string | number | null) => {
	if (!timestamp) return false;

	const msDiff = Date.now() - Number(timestamp);

	const differenceInDays = Math.floor(msDiff / (1000 * 60 * 60 * 24));

	return differenceInDays < DAYS_TO_DISABLE_REFERRAL_PROMO;
};

export const useReferralsStore = defineStore(PINIA_STORES.REFERRALS, () => {
	const {
		state,
		getters,
	} = useStore();
	const { notify } = useNotifications();

	const resourcesStore = useResourcesStore();
	const hasStore = computed(() => getters['ecommerce/isStoreTypeZyro']);
	const websiteId = computed<string>(() => state.websiteId);

	const hostingResources = computed(() => resourcesStore.getResourcesByTypes([HRESOURCE_TYPE.HOSTING]));

	const isSiteUpdated = ref(false);

	const isReferralNudgeBannerClosed = ref<boolean>(
		getIsReferralDisabled(localStorage.getItem(LOCAL_STORAGE_KEY_IS_REFERRAL_NUDGE_CLOSED)),
	);

	const isPaymentMethodAdded = ref<boolean>();

	const fetchPaymentMethods = async () => {
		if (isPaymentMethodAdded.value !== undefined) return;

		try {
			const data = await HBillingApi.getPaymentMethods();

			isPaymentMethodAdded.value = !!data?.length;
		} catch (error) {
			notify({
				messageI18nKeyPath: 'builder.paymentMethodsFailedToLoad',
				theme: 'error',
				submitCallback: () => {},
			});

			captureException(error);
		}
	};

	const isReferralNudgeGamificationShown = computed(() => {
		const IsReferralNudgeExperimentVar2Enabled = getIsExperimentActive(
			EXPERIMENT_IDS.REFERRAL_NUDGE,
			EXPERIMENT_VARIANTS[EXPERIMENT_IDS.REFERRAL_NUDGE].VAR_2,
		);

		const result = !hasStore.value
		&& !!hostingResources.value.length
		&& isPaymentMethodAdded.value
		&& isSiteUpdated.value
		&& IsReferralNudgeExperimentVar2Enabled;

		return result;
	});

	const isReferralNudgeBannerShown = computed(() => {
		const IsReferralNudgeExperimentVar1Enabled = getIsExperimentActive(
			EXPERIMENT_IDS.REFERRAL_NUDGE,
			EXPERIMENT_VARIANTS[EXPERIMENT_IDS.REFERRAL_NUDGE].VAR_1,
		);

		const result = !hasStore.value
		&& !!hostingResources.value.length
		&& isPaymentMethodAdded.value
		&& !isReferralNudgeBannerClosed.value
		&& isSiteUpdated.value
		&& IsReferralNudgeExperimentVar1Enabled;

		return result;
	});

	const handleReferralNudgeClose = () => {
		localStorage.setItem(LOCAL_STORAGE_KEY_IS_REFERRAL_NUDGE_CLOSED, Date.now().toString());

		isReferralNudgeBannerClosed.value = true;
	};

	const handleSiteUpdate = () => {
		setIsSiteUpdatedFromLocalStorage(websiteId.value);

		isSiteUpdated.value = true;
	};

	const redirectToBuyReferrals = (location: string) => {
		EventLogApi.logEvent({
			eventName: 'referrals.enter',
			eventProperties: {
				location,
			},
		});

		authRedirectService.redirectToHpanel({
			path: `${import.meta.env.VITE_HPANEL_URL}/referrals`,
			target: '_blank',
		});
	};

	watch(() => websiteId.value, (newId) => {
		isSiteUpdated.value = getIsSiteUpdatedFromLocalStorage(newId);
	});

	return {
		isReferralNudgeBannerShown,
		isReferralNudgeGamificationShown,
		handleReferralNudgeClose,
		fetchPaymentMethods,
		redirectToBuyReferrals,
		handleSiteUpdate,
		isSiteUpdated,
	};
});
