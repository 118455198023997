<template>
	<div
		class="product-template-button"
		:class="{ 'product-template-button--controls-open': isControlBarVisible, }"
	>
		<button
			class="product-template-button__button"
			:class="{
				'product-template-button__button--controls-open': isControlBarVisible,
				'product-template-button__button--active': isSidebarOpen
			}"
			@click="handleSettingsClick"
		>
			<Icon
				name="sell"
				is-filled
			/>
			<PulsingDot
				v-if="guiStore.isProductPageTemplateNotificationShown"
				top="-10%"
				left="40%"
			/>
		</button>
		<BuilderProductPageNotification
			:is-notification-shown="guiStore.isProductPageTemplateNotificationShown"
			@close-click="handleNotificationClose"
		/>
		<ZyroPopupCard
			v-if="areSettingsOpen"
			type="editor"
			:title="$t('builder.productPageTemplate')"
			class="product-template-settings"
			@close="areSettingsOpen = !areSettingsOpen"
		>
			<div
				ref="productSelectRef"
				class="product-template-settings__content"
			>
				<label
					for="product-select"
					class="text-bold-2"
				>
					{{ $t('builder.previewProduct') }}:
				</label>
				<ZyroSelect
					v-qa="'ecommerce-block-product-select'"
					input-id="product-select"
					class="product-template-settings__product-select"
					:options="productsSeo"
					label-key="title"
					:model-value="currentOpenProduct"
					@update:model-value="handleProductSelect"
				>
					<template #option="{ option }">
						<div
							class="product-template-settings__product-select-option
						product-template-settings__product-select-option--list-option"
						>
							<Icon
								dimensions="18px"
								:name="option.is_hidden ? 'visibility_off' : 'visibility'"
							/>
							<span>
								{{ option.title }}
							</span>
						</div>
					</template>

					<template #selected-option="{ option }">
						<div
							class="product-template-settings__product-select-option
						product-template-settings__product-select-option--selected-option"
						>
							{{ option.title }}
						</div>
					</template>
				</ZyroSelect>
				<div class="product-template-settings__disclaimer-title">
					<Icon
						class="product-template-settings__icon"
						dimensions="20px"
						name="info"
					/>
					<i18n-t
						keypath="builder.changesWillApplyToProductPages"
						tag="span"
						class="text-bold-2"
					/>
				</div>
				<p class="product-template-settings__text text-body-2">
					{{ $t('builder.productPageTemplateModalText') }}
				</p>
				<BuilderProductPageNotification
					:is-notification-shown="guiStore.isProductPageTemplateNotificationShown"
					transform-origin="bottom right"
					@close-click="handleNotificationClose"
				/>
			</div>
		</ZyroPopupCard>
	</div>
</template>
<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import { useStore } from 'vuex';
import {
	computed,
	onMounted,
	ref,
} from 'vue';
import PulsingDot from '@/components/global/PulsingDot.vue';
import BuilderProductPageNotification from '@/components/builder-view/BuilderProductPageNotification.vue';
import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import {
	getIsProductTemplateNotificationShown,
	setIsProductTemplateNotificationShownLocalStorage,
} from '@/utils/ecommerce';
import { useGuiStore } from '@/stores/guiStore';
import { useSiteStore } from '@/stores/siteStore';
import {
	EcommerceProduct,
	EcommerceProductSeoSettingsData,
} from '@zyro-inc/site-modules/types';

defineEmits(['menu-button-click']);

defineProps({
	isSidebarOpen: {
		type: Boolean,
		default: false,
	},
});

const {
	state,
	getters,
} = useStore();
const ecommerceStore = useEcommerceStore();
const guiStore = useGuiStore();
const siteStore = useSiteStore();

const areSettingsOpen = ref(false);

const productsSeo = computed(() => ecommerceStore.productsSeo.filter((product) => !product.is_hidden));
const currentOpenProduct = computed(() => productsSeo.value.find(
	(productData) => productData.id === ecommerceStore.currentDynamicPageProductId,
));
const isControlBarVisible = computed(() => !!state.currentElementId || !!state.currentBlockId);
const positionBottom = computed(() => (
	getters['gui/isSetupPaymentProviderNotificationVisible'] ? '100px' : '80px'));

const handleProductSelect = (data: EcommerceProduct | EcommerceProductSeoSettingsData) => {
	ecommerceStore.setCurrentDynamicPageProductId(data.id as string);
};

const handleNotificationClose = () => {
	guiStore.setIsProductPageTemplateNotificationShown(false);

	setIsProductTemplateNotificationShownLocalStorage(siteStore.websiteId);
};

const handleSettingsClick = () => {
	handleNotificationClose();
	areSettingsOpen.value = !areSettingsOpen.value;
};

onMounted(() => {
	guiStore.setIsProductPageTemplateNotificationShown(
		getIsProductTemplateNotificationShown(ecommerceStore.isDynamicPageFlowEnabled, siteStore.websiteId),
	);
});
</script>

<style scoped lang="scss">
$position-right: 16px;
$position-bottom: v-bind(positionBottom);
$button-height: 56px;
$notification-position: calc($position-bottom + $button-height + 16px);

.product-template-button {
	$this: &;

	:deep(.product-page-notification) {
		bottom: $notification-position;
	}

	&--controls-open {
		:deep(.product-page-notification) {
			bottom: calc(#{$notification-position} + 45px);
		}
	}

	&__button {
		background-color: $color-light;
		box-shadow: 0 0 12px 0 rgba(29, 30, 32, 16%);
		padding: 16px;
		color: $color-primary;
		position: fixed;
		right: $position-right;
		bottom: $position-bottom;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease-in-out;

		&--controls-open {
			bottom: calc(#{$position-bottom} + 45px);
		}

		&--active {
			scale: 0.85;
		}
	}

	:deep() {
		.popup-card, .popup-card__content {
			overflow-y: unset;
		}
	}
}

.product-template-settings {
	position: fixed;
	bottom: 0;

	&__content {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&__disclaimer-title {
		display: flex;
		align-items: center;
		margin-top: 8px;
	}

	&__icon {
		margin-right: 4px;
		color: $color-primary;
	}

	&__product-select {
		width: 100%;
		height: 43px;
		border: none;

		:deep() {
			.vs__dropdown-menu {
				z-index: 1000;
				max-height: 220px;
				bottom: 100%;
				top: auto;
				transform: translateY(-10px);
			}

			.vs__selected {
				padding: 0 8px 0 16px;
			}
		}
	}

	:deep(.datalist) {
		background-color: $color-gray-light;
	}

	&__product-select-option {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&--list-option {
			display: flex;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;

			:nth-child(1) {
				max-width: 18px;
				width: 100%;
				flex: 1 0 auto;
			}

			:nth-child(2) {
				width: 250px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		&--selected-option {
			width: 250px;
		}
	}

	&__text {
		color: $color-gray;
	}
}
</style>
