import axios from '@/services/http/axios';

export const NPS_API = `${import.meta.env.VITE_BACKEND_API_URL}/v3/nps`;

export const getLastEvaluation = async (formType: string) => (
	await axios.get<{ daysPassed: number; }>(`${NPS_API}/last-evaluation/${formType}`)
).data;

export const sendNpsScore = async ({
	score,
	comment,
	formType,
	device,
	importedWebsiteUrl,
	siteId,
	checkboxesData = [],
}: {
	score: number,
	comment: string,
	formType: string,
	device: string,
	importedWebsiteUrl: string,
	siteId: string,
	checkboxesData?: string[]
}) => axios.post(`${NPS_API}`, {
	score,
	comment,
	formType,
	device,
	importedWebsiteUrl,
	siteId,
	...(checkboxesData?.length && {
		checkboxes: checkboxesData,
	}),
});
