<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import { useDomainStatus } from '@/use/useDomainStatus';
import { computed } from 'vue';

const { domainStatusConfig } = useDomainStatus();

const icon = computed(() => domainStatusConfig.value?.publishModalIcon || 'error');
const isIconFilled = computed(() => !!domainStatusConfig.value?.isIconFilled);
const buttonText = computed(() => domainStatusConfig.value?.publishModalButtonText);
const buttonTheme = computed(() => domainStatusConfig.value?.buttonTheme || 'primary');
const description = computed(() => domainStatusConfig.value?.publishModalDescription);
const title = computed(() => domainStatusConfig.value?.publishModalTitle);
const handleAction = computed(() => domainStatusConfig.value?.action);

</script>

<template>
	<div
		v-if="title"
		class="published-modal-update-section"
		:class="`published-modal-update-section--${domainStatusConfig
			&& (domainStatusConfig.modalStatusTheme || domainStatusConfig.statusTheme)}`"
	>
		<div class="published-modal-update-section__content">
			<Icon
				:name="icon"
				class="published-modal-update-section__icon"
				dimensions="20px"
				:is-filled="isIconFilled"
			/>
			<div>
				<span class="text-bold-2">
					{{ title }}
					{{ ' ' }}
				</span>
				<span class="text-body-2">
					{{ description }}
				</span>
			</div>
		</div>
		<HostingerButton
			v-if="buttonText"
			size="sm"
			:theme="buttonTheme"
			@click="handleAction"
		>
			{{ buttonText }}
		</HostingerButton>
	</div>
</template>

<style lang="scss" scoped>
.published-modal-update-section {
	$this: &;

	margin-top: 16px;
	background: var(--gray-light);
	border: 1px solid var(--gray-border);
	border-radius: 12px;
	padding: 12px 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 16px;

	&__content {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	&__icon {
		color: var(--gray);
		overflow: visible;
	}

	&--warning,
	&--inactive {
		background-color: var(--color-warning-light);
		border-color: var(--color-warning-charts);

		#{$this}__content {
			color: var(--color-warning-dark-2);
		}

		#{$this}__icon {
			color: var(--color-warning-dark-2);
		}
	}

	&--danger {
		background-color: var(--color-danger-light);
		border-color: var(--color-danger-charts);

		#{$this}__content {
			color: var(--color-danger-dark);
		}

		#{$this}__icon {
			color: var(--color-danger-dark);
		}
	}
}
</style>
