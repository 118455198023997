import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';
import { useSiteStore } from '@/stores/siteStore';
import { useGuiStore } from '@/stores/guiStore';

import {
	setCookie,
	getCookie,
} from '@zyro-inc/site-modules/utils/cookies';
import {
	OPEN_DRAWER,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import {
	PRODUCT_TEMPLATE_ONBOARDING_STEP_ADD_SECTION,
	PRODUCT_TEMPLATE_ONBOARDING_STEPS,
	DRAWER_MANAGE_STORE,
	COOKIE_IS_PRODUCT_TEMPLATE_ONBOARDING_SHOWN,
	productTemplateOnboardingConfig,
} from '@/constants/builderConstants';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { setIsProductTemplateNotificationShownLocalStorage } from '@/utils/ecommerce';

const isOnboardingVisible = ref(false);
const currentProductTemplateOnboardingStep = ref<null | keyof typeof productTemplateOnboardingConfig>(null);
const currentProductTemplateStepIndex = ref<number>(1);

export const useProductTemplateOnboarding = () => {
	const { dispatch } = useStore();
	const siteStore = useSiteStore();
	const guiStore = useGuiStore();
	const ecommerceStore = useEcommerceStore();

	const productTemplateOnboardingStepCount = ref(PRODUCT_TEMPLATE_ONBOARDING_STEPS.length);
	const isProductTemplateOnboardingModalVisible = computed(() => siteStore.siteMeta?.isExistingStoreWithDynamicTemplate
        && !getCookie(COOKIE_IS_PRODUCT_TEMPLATE_ONBOARDING_SHOWN));
	const isLastStep = computed(() => currentProductTemplateStepIndex.value === productTemplateOnboardingStepCount.value);

	const setProductTemplateOnboardingStep = ({ stepIndex }: {stepIndex: number}) => {
		currentProductTemplateStepIndex.value = stepIndex;
		currentProductTemplateOnboardingStep.value = PRODUCT_TEMPLATE_ONBOARDING_STEPS[stepIndex - 1] as
			keyof typeof productTemplateOnboardingConfig;

		if (currentProductTemplateOnboardingStep.value === PRODUCT_TEMPLATE_ONBOARDING_STEP_ADD_SECTION) {
			dispatch(`gui/${OPEN_DRAWER}`, {
				id: null,
			});

			ecommerceStore.handleEditDynamicProductPageClick();
		}
	};

	const finishProductTemplateOnboarding = () => {
		dispatch(`gui/${CLOSE_MODAL}`);
		isOnboardingVisible.value = false;

		setCookie(COOKIE_IS_PRODUCT_TEMPLATE_ONBOARDING_SHOWN, 1, 365);
	};

	const startProductTemplateOnboarding = () => {
		if (guiStore.isMobileScreen) {
			ecommerceStore.handleEditDynamicProductPageClick();

			finishProductTemplateOnboarding();

			return;
		}

		dispatch(`gui/${CLOSE_MODAL}`);

		// Hide product template notification for existing users because they go through onboarding
		guiStore.setIsProductPageTemplateNotificationShown(false);
		setIsProductTemplateNotificationShownLocalStorage(siteStore.websiteId);

		dispatch(`gui/${OPEN_DRAWER}`, {
			id: DRAWER_MANAGE_STORE,
		});

		isOnboardingVisible.value = true;

		setProductTemplateOnboardingStep({
			stepIndex: 1,
		});
	};

	const goToNextProductTemplateOnboardingStep = () => {
		if (currentProductTemplateStepIndex.value === productTemplateOnboardingStepCount.value) {
			finishProductTemplateOnboarding();

			return;
		}

		setProductTemplateOnboardingStep({
			stepIndex: currentProductTemplateStepIndex.value + 1,
		});
	};

	return {
		currentProductTemplateOnboardingStep,
		isProductTemplateOnboardingModalVisible,
		isOnboardingVisible,
		isLastStep,
		productTemplateOnboardingStepCount,
		currentProductTemplateStepIndex,
		startProductTemplateOnboarding,
		finishProductTemplateOnboarding,
		goToNextProductTemplateOnboardingStep,
		setProductTemplateOnboardingStep,
	};
};
