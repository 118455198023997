import { LOCAL_STORAGE_KEY_IS_SITE_UPDATED } from '@/constants/builderConstants';

// eslint-disable-next-line no-promise-executor-return
export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getIsSiteUpdatedFromLocalStorage = (siteId: string): boolean => {
	const storageValue = localStorage.getItem(LOCAL_STORAGE_KEY_IS_SITE_UPDATED);

	if (!storageValue) return false;

	const updatedSiteIds = JSON.parse(storageValue);

	if (Array.isArray(updatedSiteIds)) {
		return updatedSiteIds.includes(siteId);
	}

	return false;
};

export const setIsSiteUpdatedFromLocalStorage = (siteId: string) => {
	const storageValue = localStorage.getItem(LOCAL_STORAGE_KEY_IS_SITE_UPDATED);

	if (!storageValue) {
		localStorage.setItem(LOCAL_STORAGE_KEY_IS_SITE_UPDATED, JSON.stringify([siteId]));

		return;
	}

	const isAlreadyUpdated = getIsSiteUpdatedFromLocalStorage(siteId);

	if (isAlreadyUpdated) {
		return;
	}

	const updatedSiteIds = JSON.parse(storageValue);

	if (Array.isArray(updatedSiteIds)) {
		localStorage.setItem(LOCAL_STORAGE_KEY_IS_SITE_UPDATED, JSON.stringify([
			...updatedSiteIds,
			siteId,
		]));
	}
};
