<template>
	<div
		ref="builderPreviewContainerRef"
		class="builder-root"
	>
		<div class="builder-root__main">
			<BuilderHeaderProductTemplate
				v-if="isProductPageTemplateButtonVisible"
				ref="productPageHeaderRef"
			/>
			<MobileFrame class="builder-root__preview-wrapper">
				<BuilderPreview v-if="website" />
				<HAiChatbot
					v-if="isShowingAiChatbot"
					:class="{ 'ai-chatbot-raised-from-bottom-banner': isRaisedFromBottomBanner }"
					:top-position="HEADER_HEIGHT + 8"
					:conversation-history="conversationHistory"
					:is-responding="isChatbotResponding"
					:is-restarting="isChatbotRestarting"
					:is-feedback-loading="isChatbotFeedbackLoading"
					:texts="chatbotTexts"
					@initialize="chatbotInitialize"
					@respond="chatbotRespond"
					@restart="chatbotRestart"
					@submit-feedback="chatbotSubmitFeedback"
					@function-click="handleFunctionClick"
				>
					<template #custom-content>
						<AiChatbotAiTools v-if="!userStore.areFeaturesLocked" />
					</template>
				</HAiChatbot>
				<BuilderHeatmap v-if="isHeatmapOpen" />
			</MobileFrame>
		</div>

		<BuilderDrawers />

		<ContextMenuLayoutElement />
	</div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { useScrollHandler } from '@/use/useScrollHandler';
import BuilderDrawers from '@/components/builder-drawers/BuilderDrawers.vue';
import BuilderHeatmap from '@/components/builder-view/BuilderHeatmap.vue';
import MobileFrame from '@/components/builder-view/MobileFrame.vue';
import BuilderPreview from '@/components/builder-view/views/BuilderPreview/BuilderPreview.vue';
import ContextMenuLayoutElement from '@/components/context-menu/ContextMenuLayoutElement.vue';
import {
	onMounted,
	watch,
	watchEffect,
	computed,
	ref,
	onBeforeUnmount,
	nextTick,
} from 'vue';
import { useSeo } from '@/use/useSeo';
import { useGamification } from '@/use/useGamification';
import {
	GAMIFICATION_TASK_CONNECT_DOMAIN,
	GAMIFICATION_TASK_CHANGE_SEO,
	HEADER_HEIGHT,
} from '@/constants/builderConstants';
import { HAiChatbot } from '@hostinger/hcomponents';
import AiChatbotAiTools from '@/components/AiChatbotAiTools.vue';
import { useChatbot } from '@/use/useChatbot';
import { useUserStore } from '@/stores/userStore';
import { useRoute } from 'vue-router';
import {
	AI_BUILDER_ROUTE,
	AI_PREVIEW_ROUTE,
	PREVIEW_ROUTE,
	TEMPLATES_ROUTE,
} from '@/constants/routes';
import { useSiteStore } from '@/stores/siteStore';
import { useGamificationStore } from '@/stores/gamificationStore';
import { useDomainStore } from '@/stores/domainStore';
import BuilderHeaderProductTemplate from '@/components/builder-view/headers/BuilderHeaderProductTemplate.vue';

const {
	state,
	getters,
	dispatch,
} = useStore();
const userStore = useUserStore();
const { completeAchievement } = useGamification();
const route = useRoute();
const gamificationStore = useGamificationStore();

const domainStore = useDomainStore();

const {
	chatbotInitialize,
	chatbotRespond,
	conversationHistory,
	isChatbotResponding,
	chatbotRestart,
	isChatbotRestarting,
	chatbotSubmitFeedback,
	isChatbotFeedbackLoading,
	chatbotTexts,
	handleFunctionClick,
} = useChatbot();
const { initializeScrollHandler } = useScrollHandler();
const siteStore = useSiteStore();
const { isHomePageSeoValid } = useSeo();

const productPageHeaderRef = ref<InstanceType<typeof BuilderHeaderProductTemplate> | null>(null);
const builderPreviewContainerRef = ref<null | HTMLElement>(null);
const resizeObserver = ref<ResizeObserver | null>(null);
const productPageHeaderHeight = ref(0);

const isRouteValidForChatbot = computed(() => ![
	PREVIEW_ROUTE,
	TEMPLATES_ROUTE,
	AI_BUILDER_ROUTE,
	AI_PREVIEW_ROUTE,
].includes(route.name as string));
const user = computed(() => state.user);
const isP2PBannerShown = computed(() => state.gui.isP2PBannerShown);
const isSetupPaymentNotificationVisible = computed(() => getters['gui/isSetupPaymentProviderNotificationVisible']);
const isRaisedFromBottomBanner = computed(() => isP2PBannerShown.value || isSetupPaymentNotificationVisible.value);
const isShowingAiChatbot = computed(() => !user.value?.user?.isAccessManager
	&& isRouteValidForChatbot.value);
const isProductPageTemplateButtonVisible = computed(() => getters.isCurrentPageTypeDynamicProduct);
const customDomain = computed(() => domainStore.customDomain);
const isCustomDomainPreview = computed(() => getters.isCustomDomainPreview);
const website = computed(() => siteStore.site);
const isHeatmapOpen = computed(() => state.gui.isHeatmapOpen);

const setProductPageHeaderHeight = () => {
	productPageHeaderHeight.value = productPageHeaderRef.value?.$el.offsetHeight;
};

const initializeProductHeaderResizeObserver = async () => {
	await nextTick();

	setProductPageHeaderHeight();

	resizeObserver.value = new ResizeObserver(() => {
		setProductPageHeaderHeight();
	});

	if (productPageHeaderRef.value) {
		resizeObserver.value?.observe(productPageHeaderRef.value.$el);
	}
};

watch([
	gamificationStore.isGamificationLoaded,
	isHomePageSeoValid,
], () => {
	if (isHomePageSeoValid.value && gamificationStore.isGamificationLoaded) {
		completeAchievement(GAMIFICATION_TASK_CHANGE_SEO);
	}
});

watchEffect(() => {
	if (customDomain.value && !isCustomDomainPreview.value && gamificationStore.isGamificationLoaded) {
		completeAchievement(GAMIFICATION_TASK_CONNECT_DOMAIN);
	}
});

watch(isProductPageTemplateButtonVisible, (newValue, oldValue) => {
	if (newValue !== oldValue && newValue) {
		initializeProductHeaderResizeObserver();
	}
});

onMounted(async () => {
	if (!builderPreviewContainerRef.value) {
		return;
	}

	dispatch('gui/setBuilderPreviewContainerRef', {
		builderPreviewContainerRef: builderPreviewContainerRef.value,
	});

	initializeProductHeaderResizeObserver();
	initializeScrollHandler(builderPreviewContainerRef.value);
});

onBeforeUnmount(() => {
	resizeObserver.value?.disconnect();
});
</script>
<style lang="scss" scoped>
.builder-root {
	display: contents;
	overflow: auto;

	@media screen and (max-width: $media-mobile) {
		margin-top: $header-height-editor;
	}

	&__main {
		position: relative;
		flex: 1;
		height: fit-content;
		background: $color-gray-light;

		.ai-chatbot-raised-from-bottom-banner {
			bottom: 58px;
		}
	}

	&__preview-wrapper {
		padding-top: calc(v-bind(productPageHeaderHeight) * 1px);
	}
}

:deep(.chatbot-wrapper) {
	z-index: 1000;
};
</style>
