import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
	AI_BUILDER_CATEGORY_ID_STORE,
	AI_BUILDER_CATEGORY_ID_BLOG,
	AI_BUILDER_CATEGORY_ID_BUSINESS,
	AI_BUILDER_CATEGORY_ID_PORTFOLIO,
	AI_BUILDER_CATEGORY_ID_OTHER,
	AI_BUILDER_CATEGORY_ID_WEDDINGS,
	AI_BUILDER_CATEGORY_ID_LANDING_PAGE,
	AI_BUILDER_CATEGORY_ID_RESTAURANT,
	TYPE_SUGGESTION_DEBOUNCE_TIME,
} from '@/constants/builderConstants';
import {
	WebsiteType,
	AiBuilderCategory,
} from '@/types/aiBuilderTypes';

import { fetchWebsiteType } from '@/api/AiApi';
import { debounce } from 'lodash';

import { useAiField } from '@/use/useAiField';
import { useAiBuilderStore } from '@/stores/aiBuilderStore';

export const validateFormInput = (input: string) => !!input.trim();

export const useAiBuilderForm = () => {
	const aiBuilderStore = useAiBuilderStore();
	const { t } = useI18n();
	const {
		fieldProgress: descriptionProgress,
		fieldError: descriptionError,
		setFieldValue,
		validateField: validateDescription,
		onFieldBlur: onDescriptionBlur,
		isSubmitClicked: isAiFormSubmitClicked,
	} = useAiField({
		initialValue: aiBuilderStore.websiteDescription,
	});

	const setDescriptionValue = (value: string) => {
		setFieldValue(value);
		aiBuilderStore.setWebsiteDescription(value);
	};

	const AI_BUILDER_CATEGORIES: Record<WebsiteType, AiBuilderCategory> = {
		[AI_BUILDER_CATEGORY_ID_BUSINESS]: {
			label: t('builder.aiBuilderBusinessShowcase'),
			id: AI_BUILDER_CATEGORY_ID_BUSINESS,
			amplitudeName: 'Business',
			onboardingRedirectParamValue: 'business',
		},
		[AI_BUILDER_CATEGORY_ID_STORE]: {
			label: t('builder.addEcommerceStoreDrawer.title'),
			id: AI_BUILDER_CATEGORY_ID_STORE,
			amplitudeName: 'Online store',
			onboardingRedirectParamValue: 'online-store',
		},
		[AI_BUILDER_CATEGORY_ID_LANDING_PAGE]: {
			label: t('builder.landingPage'),
			id: AI_BUILDER_CATEGORY_ID_LANDING_PAGE,
			amplitudeName: 'Landing page',
			onboardingRedirectParamValue: 'landing-page',
		},
		[AI_BUILDER_CATEGORY_ID_BLOG]: {
			label: t('builder.blog.blogDrawer.title'),
			id: AI_BUILDER_CATEGORY_ID_BLOG,
			amplitudeName: 'Blog',
			onboardingRedirectParamValue: 'blog',
		},
		[AI_BUILDER_CATEGORY_ID_PORTFOLIO]: {
			label: t('common.portfolio'),
			id: AI_BUILDER_CATEGORY_ID_PORTFOLIO,
			amplitudeName: 'Portfolio',
			onboardingRedirectParamValue: 'portfolio',
		},
		[AI_BUILDER_CATEGORY_ID_RESTAURANT]: {
			label: t('common.restaurant'),
			id: AI_BUILDER_CATEGORY_ID_RESTAURANT,
			amplitudeName: 'Restaurant',
			onboardingRedirectParamValue: 'restaurant',
		},
		[AI_BUILDER_CATEGORY_ID_WEDDINGS]: {
			label: t('builder.weddings'),
			id: AI_BUILDER_CATEGORY_ID_WEDDINGS,
			amplitudeName: 'Weddings invitation',
			onboardingRedirectParamValue: 'weddings',
		},
		[AI_BUILDER_CATEGORY_ID_OTHER]: {
			label: t('siteSettings.domain.domainConnectionModal.otherProvider'),
			id: AI_BUILDER_CATEGORY_ID_OTHER,
			amplitudeName: 'Other',
			onboardingRedirectParamValue: 'other',
		},
	} as const;

	const isFetchingWebsiteType = ref(false);

	const isValidBrandName = computed(() => validateFormInput(aiBuilderStore.brandName));
	const isValidBrandDescription = computed(() => validateDescription());
	const isWebsiteTypeSuggestionVisible = computed(
		() => aiBuilderStore.websiteType === AI_BUILDER_CATEGORY_ID_STORE,
	);

	const resetFormInputs = () => {
		aiBuilderStore.setBrandName('');
		aiBuilderStore.setWebsiteType(AI_BUILDER_CATEGORY_ID_OTHER);
		setDescriptionValue('');
	};

	const debouncedFetchSuggestedWebsiteType = debounce(async () => {
		if (!isValidBrandDescription.value) {
			return;
		}

		isFetchingWebsiteType.value = true;
		try {
			const { data } = await fetchWebsiteType({
				description: aiBuilderStore.websiteDescription,
				brandName: isValidBrandName.value ? aiBuilderStore.brandName : undefined,
			});

			const capitalizedWebsiteType = data.websiteType.charAt(0).toUpperCase() + data.websiteType.slice(1) as WebsiteType;

			aiBuilderStore.setWebsiteType(capitalizedWebsiteType);
		} catch (error) {
			console.error('Failed to fetch suggested website type', error);

			aiBuilderStore.setWebsiteType(AI_BUILDER_CATEGORY_ID_OTHER);
		} finally {
			isFetchingWebsiteType.value = false;
			aiBuilderStore.setIsWebsiteTypeFetched(true);
		}
	}, TYPE_SUGGESTION_DEBOUNCE_TIME);

	return {
		AI_BUILDER_CATEGORIES,
		isValidBrandName,
		isValidBrandDescription,
		resetFormInputs,
		isWebsiteTypeSuggestionVisible,
		debouncedFetchSuggestedWebsiteType,
		isFetchingWebsiteType,
		descriptionProgress,
		descriptionError,
		setDescriptionValue,
		validateDescription,
		onDescriptionBlur,
		isAiFormSubmitClicked,
	};
};
