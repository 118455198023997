import {
	getIsExperimentActive,
	EXPERIMENT_IDS,
} from '@/utils/experiments';
import {
	computed,
	ref,
} from 'vue';
import { useStorage } from '@vueuse/core';
import { SiteData } from '@hostinger/builder-schema-validator';
import { useSiteStore } from '@/stores/siteStore';
import { useRedirects } from '@/use/useRedirects';
import { useStore } from 'vuex';
import { addGoogleFontQueryLinks } from '@/utils/injectableDomElements/addGoogleFontQueryLinks';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { createStoreWithProducts } from '@/api/EcommerceApi';
import { useSiteTextElementPreview } from '@/use/useSiteTextElementPreview';
import { useTextToSvg } from '@/use/useTextToSvg';
import {
	HTML_LANG_VALUES,
	LOGO_SVG_DEFAULT,
	AI_BUILDER_PREVIEW_COLOR_PALETTES,
} from '@/data/builderData';
import {
	AI_PREVIEW_PALETTE_TRACKING_IDS,
	SITE_HISTORY_KEY,
	SITE_HISTORY_LIMIT,
	AI_BUILDER_LOGO_SIZE,
	AI_BUILDER_DEFAULT_LOGO_HEIGHT,
	AI_BUILDER_DEFAULT_LOGO_WIDTH,
	AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH,
	AI_BUILDER_CATEGORY_ID_BUSINESS,
	AI_PREVIEW_ECOMMERCE_PRODUCT_COUNT,
} from '@/constants/builderConstants';
import {
	updateTemplateWithLogoSvg,
	getTemplateNavigationColor,
} from '@/utils/aiTemplateEditingHelpers';
import {
	DEFAULT_HTML_LANG_VALUE,
	SITE_META_ECOMMERCE_STORE_ID,
	SYSTEM_LOCALE,
	BACKGROUND_TYPES,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useAiBuilderStore } from '@/stores/aiBuilderStore';
import { useAiBuilderForm } from '@/components/ai-builder/useAiBuilderForm';
import { generateSite } from '@/api/AiApi';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';
import { AxiosError } from 'axios';
import {
	AI_PREVIEW_ROUTE,
	BUILDER_ROUTE,
} from '@/constants/routes';
import { usePreviewHeaderNavigation } from '@/use/usePreviewHeaderNavigation';
import { useRoute } from 'vue-router';
import LogEventApi from '@/api/EventLogApi';
import {
	AiBuilderColorPalette,
	GeneratedColorPalette,
} from '@/types/aiBuilderTypes';
import { useSavingStore } from '@/stores/savingStore';

const FONT_FALLBACK = "'Roboto', sans-serif";

interface SiteHistoryRecord {
	language: string;
	siteData: SiteData;
	ecommerceProducts: any;
	timestamp?: number;
	siteId?: string;
	colorPalettePicked?: GeneratedColorPalette;
	colorPaletteGenerated?: GeneratedColorPalette;
}

enum AiPreviewGeneratorState {
	IDLE = 'IDLE',
	GENERATING = 'GENERATING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

const getLanguageCode = (language: string): string | undefined => HTML_LANG_VALUES.find(
	(lang) => lang.title.toLowerCase() === language?.toLowerCase(),
)?.value?.toUpperCase();

export const useAiBuilderPreview = () => {
	const { t } = useI18n();
	const { notify } = useNotifications();
	const generatedSites = useStorage<SiteHistoryRecord[]>(SITE_HISTORY_KEY, []);
	const {
		dispatch,
		getters,
		state,
	} = useStore();
	const siteStore = useSiteStore();
	const ecommerceStore = useEcommerceStore();
	const { getSvg } = useTextToSvg();
	const savingStore = useSavingStore();
	const aiBuilderStore = useAiBuilderStore();
	const route = useRoute();
	const { navigate } = usePreviewHeaderNavigation();
	const { recalculateWebsiteTextHeights } = useSiteTextElementPreview();
	const { redirectToEcommerce } = useRedirects();
	const {
		setDescriptionValue,
		AI_BUILDER_CATEGORIES,
	} = useAiBuilderForm();

	const aiPreviewGeneratorState = ref<AiPreviewGeneratorState>(AiPreviewGeneratorState.IDLE);

	const isAiPreviewLoading = computed(() => aiPreviewGeneratorState.value === AiPreviewGeneratorState.GENERATING);
	const isEditSiteDisabled = computed(() => isAiPreviewLoading.value || aiBuilderStore.isLoadingEcommerceData);
	const generatedSitesCount = computed(() => generatedSites.value.length);
	const generatedSitesBySiteId = computed(() => generatedSites.value.filter((site) => site.siteId === siteStore.websiteId));
	const shouldGenerateLogo = computed(() => aiBuilderStore.brandName.trim().length < AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH);
	const currentVersionSite = computed(() => generatedSites.value?.[aiBuilderStore.currentVersionIndex]);
	const colorPalettePicked = computed(() => currentVersionSite.value?.colorPalettePicked || AI_BUILDER_PREVIEW_COLOR_PALETTES[0]);
	const colorPaletteGenerated = computed(() => currentVersionSite.value?.colorPaletteGenerated
		|| AI_BUILDER_PREVIEW_COLOR_PALETTES[0]);
	const isBuilderInitialized = computed(() => state.hasBuilderInitialized);
	const isAiBuilderPreviewReady = computed<boolean>(() => !aiBuilderStore.isAiBuilderPreviewInitialized
		&& isBuilderInitialized.value
		&& route.name === AI_PREVIEW_ROUTE
		&& !!Object.keys(currentVersionSite.value?.siteData || {}).length);

	const getIsAiBuilderSidebarExperimentActive = () => getIsExperimentActive(EXPERIMENT_IDS.AI_BUILDER_PREVIEW_SIDEBAR);

	const saveGeneratedSite = (site: SiteHistoryRecord) => {
		if (generatedSitesCount.value >= SITE_HISTORY_LIMIT) {
			const differentSiteIdRecord = generatedSites.value.findIndex((record) => record.siteId !== siteStore.websiteId);

			// Prioritize to remove the oldest & non-current siteId record
			if (differentSiteIdRecord !== -1) {
				generatedSites.value.splice(differentSiteIdRecord, 1);
			} else {
				generatedSites.value.pop();
			}
		}

		generatedSites.value = [
			{
				...site,
				timestamp: Date.now(),
				siteId: siteStore.websiteId,
			},
			...generatedSites.value,
		];
	};

	const getSiteWithReplacedColors = ({
		siteData,
		newColorPalette,
		oldColorPalette,
	}: {
		siteData: SiteData,
		newColorPalette: GeneratedColorPalette,
		oldColorPalette: GeneratedColorPalette,
	}) => {
		const siteDataWithNewColors = JSON.parse(
			JSON.stringify(siteData)
				.replaceAll(oldColorPalette.color1, newColorPalette.color1)
				.replaceAll(oldColorPalette.color2, newColorPalette.color2)
				.replaceAll(oldColorPalette.color3, newColorPalette.color3),
		) as SiteData;

		const blockWithGradient = Object.entries(siteDataWithNewColors.languages[SYSTEM_LOCALE].blocks)
			.find(([, block]) => block.background?.gradient && block.background?.current === BACKGROUND_TYPES.GRADIENT);

		if (blockWithGradient) {
			const [blockId, block] = blockWithGradient;
			const newGradientColor = Object.values(newColorPalette.gradients || {})[0]?.gradient || newColorPalette.color3;

			if (block.background?.gradient) {
				block.background.gradient = {
					...block.background.gradient,
					colors: [
						{
							value: newGradientColor,
						},
						{
							value: newColorPalette.color3,
						},
					],
				};
			}

			siteDataWithNewColors.languages[SYSTEM_LOCALE].blocks[blockId] = block;
		}

		return siteDataWithNewColors;
	};

	const setSiteWithNewColors = (newColorPalette: GeneratedColorPalette) => {
		const { site } = siteStore;

		if (!Object.keys(site).length) {
			return;
		}

		const oldColorPalette = colorPalettePicked.value;

		const updatedSiteData = getSiteWithReplacedColors({
			siteData: site as SiteData,
			newColorPalette,
			oldColorPalette,
		});

		generatedSites.value[aiBuilderStore.currentVersionIndex].siteData = updatedSiteData;
		generatedSites.value[aiBuilderStore.currentVersionIndex].colorPalettePicked = newColorPalette;

		siteStore.setSiteData(updatedSiteData);

		dispatch('gui/updatePreviewSiteData', {
			...updatedSiteData,
			siteId: siteStore.websiteId,
		});
	};

	const setNewPreviewSite = async ({ siteVersionIndex }: { siteVersionIndex: number }) => {
		if (state.gui.isMobileView) {
			dispatch('gui/toggleMobileView');
		}

		aiBuilderStore.setCurrentVersionIndex(siteVersionIndex);
		ecommerceStore.resetProductsList();

		const {
			language,
			siteData,
			ecommerceProducts,
		} = currentVersionSite.value || {};

		if (!siteData) {
			throw new Error('Site record does not exist');
		}

		const hasEcommerce = ecommerceProducts?.length;

		if (hasEcommerce) {
			aiBuilderStore.setIsLoadingEcommerceData(true);
		}

		siteStore.setSiteData(siteData);
		dispatch('updateCurrentPageId');
		dispatch('gui/updatePreviewSiteData', {
			...siteStore.site,
			siteId: siteStore.websiteId,
		});

		if (hasEcommerce) {
			const languageCode = getLanguageCode(language) || DEFAULT_HTML_LANG_VALUE.value;

			try {
				const { storeId } = await createStoreWithProducts({
					siteId: siteStore.websiteId,
					products: ecommerceProducts,
					language: languageCode,
				});

				siteStore.setSiteData({
					...siteData,
					meta: {
						...siteData.meta,
						[SITE_META_ECOMMERCE_STORE_ID]: storeId,
					},
				});

				dispatch('updateCurrentPageId');

				await dispatch('gui/updatePreviewSiteData', {
					...siteStore.site,
					siteId: siteStore.websiteId,
				});

				ecommerceStore.resetProductsList();

				await ecommerceStore.fetchInitialEcommerceData({
					shouldAwaitPageCreation: true,
					limit: AI_PREVIEW_ECOMMERCE_PRODUCT_COUNT,
				});
			} catch (error) {
				console.error('Failed to create ecommerce store', error);
			}

			aiBuilderStore.setIsLoadingEcommerceData(false);
		}
	};

	const handleCreateAgainClick = () => {
		aiBuilderStore.setAiFormVisibility(true);

		const {
			websiteDescription: lastWebsiteDescription,
			websiteType: lastWebsiteType,
			brandName: lastBrandName,
			selectedColors: lastSelectedColors,
		} = aiBuilderStore.getLastAiGenerationData();

		setDescriptionValue(lastWebsiteDescription);

		if (lastWebsiteType) {
			aiBuilderStore.setWebsiteType(lastWebsiteType);
		}

		aiBuilderStore.setBrandName(lastBrandName);
		aiBuilderStore.setSelectedColors((lastSelectedColors || {}) as AiBuilderColorPalette);

		LogEventApi.logEvent({
			eventName: 'website_builder.ai_builder.create_new_enter',
		});
	};

	const handleAiFormSubmit = async () => {
		if (aiPreviewGeneratorState.value !== AiPreviewGeneratorState.IDLE) {
			return;
		}

		if (!aiBuilderStore.websiteType) {
			return;
		}

		aiPreviewGeneratorState.value = AiPreviewGeneratorState.GENERATING;

		const websiteType = (aiBuilderStore.isOnlineStoreSuggestionDeclined
			? AI_BUILDER_CATEGORIES[AI_BUILDER_CATEGORY_ID_BUSINESS].amplitudeName
			: AI_BUILDER_CATEGORIES[aiBuilderStore.websiteType].amplitudeName);

		let generatedSiteData;

		aiBuilderStore.setLastAiGenerationData({
			brandName: aiBuilderStore.brandName,
			websiteDescription: aiBuilderStore.websiteDescription,
			websiteType,
			selectedColors: aiBuilderStore.selectedColors,
		});

		LogEventApi.logEvent({
			eventName: 'website_builder.ai_builder.regenerate',
			eventProperties: {
				website_id: siteStore.websiteId,
				adjusted_prompt: aiBuilderStore.isPromptAdjusted,
				business_type: websiteType,
				...(aiBuilderStore.isOnlineStoreSuggestionDeclined && {
					has_refused_online_store_suggestion: aiBuilderStore.isOnlineStoreSuggestionDeclined,
				}),
			},
		});

		try {
			const { data } = await generateSite({
				brandName: aiBuilderStore.brandName,
				websiteType,
				brandDescription: aiBuilderStore.websiteDescription,
				siteId: siteStore.websiteId,
			});

			savingStore.setClientTimestamp(data.clientTimestamp);

			generatedSiteData = data;
			aiPreviewGeneratorState.value = AiPreviewGeneratorState.SUCCESS;
		} catch (error) {
			if (error instanceof AxiosError && error.response?.status === 422) {
				notify({
					message: t('builder.aiBuilderInvalidWebsiteDescription'),
				});
			}

			aiPreviewGeneratorState.value = AiPreviewGeneratorState.IDLE;
			throw error;
		}

		let fontFamiliesToAwait;

		if (generatedSiteData.siteData.fonts) {
			fontFamiliesToAwait = generatedSiteData.siteData.fonts.map((font) => font.family);

			if (getters['fonts/getMetaFont']) {
				addGoogleFontQueryLinks(getters['fonts/getMetaFont']);
			}
		}

		const logoSvg = shouldGenerateLogo.value ? await getSvg({
			text: aiBuilderStore.brandName,
			fontFamily: generatedSiteData.siteData.fonts?.[0]?.family || FONT_FALLBACK,
			fontSize: AI_BUILDER_LOGO_SIZE,
		}) : {
			svg: LOGO_SVG_DEFAULT,
			height: AI_BUILDER_DEFAULT_LOGO_HEIGHT,
			width: AI_BUILDER_DEFAULT_LOGO_WIDTH,
		};

		generatedSiteData.siteData = updateTemplateWithLogoSvg({
			logoSvg,
			color: getTemplateNavigationColor({
				template: generatedSiteData.siteData,
			}),
			templateData: generatedSiteData.siteData,
		}) as SiteData;

		if (generatedSiteData.ecommerceProducts?.length) {
			ecommerceStore.resetProductsList();
		}

		await siteStore.setSiteData(generatedSiteData.siteData);

		await recalculateWebsiteTextHeights({
			fontFamiliesToAwait,
		});

		await dispatch('updateCurrentPageId');

		await dispatch('gui/updatePreviewSiteData', {
			...generatedSiteData.siteData,
			siteId: siteStore.websiteId,
		});

		saveGeneratedSite({
			language: generatedSiteData.language,
			siteData: siteStore.site as SiteData,
			ecommerceProducts: generatedSiteData.ecommerceProducts,
			colorPalettePicked: {
				...generatedSiteData.colorPalette,
				paletteId: `${AI_PREVIEW_PALETTE_TRACKING_IDS.PALETTE_AI}-1`,
			},
			colorPaletteGenerated: {
				...generatedSiteData.colorPalette,
				paletteId: `${AI_PREVIEW_PALETTE_TRACKING_IDS.PALETTE_AI}-1`,
			},
		});

		aiBuilderStore.setCurrentVersionIndex(0);

		if (generatedSiteData.ecommerceProducts?.length) {
			await ecommerceStore.fetchInitialEcommerceData({
				shouldAwaitPageCreation: true,
				limit: AI_PREVIEW_ECOMMERCE_PRODUCT_COUNT,
			});
		}

		aiBuilderStore.setAiFormVisibility(false);

		LogEventApi.logEvent({
			eventName: 'website_builder.ai_builder.regenerated',
		});

		aiBuilderStore.setIsPromptAdjusted(false);

		aiPreviewGeneratorState.value = AiPreviewGeneratorState.IDLE;
	};

	const handleEditClick = async ({ isEcommerceRedirectEnabled = false }) => {
		LogEventApi.logEvent({
			eventName: 'website_builder.preview.edit',
			...(getIsAiBuilderSidebarExperimentActive() && currentVersionSite.value?.colorPalettePicked && {
				eventProperties: {
					palette: currentVersionSite.value.colorPalettePicked.paletteId,
				},
			}),
		});

		if (state.gui.isMobileView) {
			dispatch('gui/toggleMobileView');
		}

		if (isEcommerceRedirectEnabled) {
			await redirectToEcommerce();

			return;
		}

		// If not ecommerce site was selected, clean up state
		ecommerceStore.resetProductsList();

		if (!route.params.siteId) {
			throw new Error('URL is missing site id');
		}

		navigate({
			name: BUILDER_ROUTE,
			params: {
				siteId: route.params.siteId as string,
			},
		});
	};

	/**
	 * Deletes generated site versions that match the current site id.
	 */
	const deleteGeneratedSiteVersionBySiteId = () => {
		if (generatedSitesBySiteId.value.length) {
			generatedSites.value = generatedSites.value.filter((site) => site.siteId !== siteStore.websiteId);
		}
	};

	// When page refreshes or loads we need to ensure that selected colors are applied to the site.
	// Logic is based on the fact that very first generated site is saved with colorPaletteGenerated this guarantees that
	// whenever preview page loads, site that is shown is always the one with generated palette applied.
	const initializeAiBuilderPreview = async () => {
		// Important! This onMounted lifecycle hook must be only triggered in AI preview route.
		// Reason for this is that it directly mutates site store which can result in corruption of user's site
		if (!isAiBuilderPreviewReady.value) {
			return;
		}

		ecommerceStore.resetProductsList();

		aiBuilderStore.setIsAiBuilderPreviewInitialized(true);

		const siteDataWithUpdatedColors = getSiteWithReplacedColors({
			siteData: currentVersionSite.value.siteData,
			newColorPalette: colorPalettePicked.value,
			oldColorPalette: colorPaletteGenerated.value,
		});

		siteStore.setSiteData(siteDataWithUpdatedColors);
		dispatch('updateCurrentPageId');
	};

	return {
		isAiPreviewLoading,
		isEditSiteDisabled,
		getIsAiBuilderSidebarExperimentActive,
		saveGeneratedSite,
		setNewPreviewSite,
		handleCreateAgainClick,
		handleAiFormSubmit,
		handleEditClick,
		generatedSites,
		currentVersionSite,
		colorPalettePicked,
		colorPaletteGenerated,
		generatedSitesBySiteId,
		deleteGeneratedSiteVersionBySiteId,
		setSiteWithNewColors,
		getSiteWithReplacedColors,
		initializeAiBuilderPreview,
	};
};
