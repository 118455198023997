<template>
	<ZyroDrawer
		class="gamification-drawer"
		@back="closeInnerSidebar"
	>
		<template #header-actions>
			<div class="gamification-drawer__bg-container" />
		</template>
		<template #body>
			<div class="gamification-drawer__header">
				<div class="gamification-drawer__header-container">
					<div class="gamification-drawer__title-container">
						<h2 class="text-heading-1">
							{{ title }}
						</h2>
					</div>
					<p class="text-body-2">
						{{ subtitle }}
					</p>
				</div>
			</div>
			<div class="gamification-drawer__content">
				<div class="gamification-drawer__main-actions">
					<div class="gamification-drawer__progress-box">
						<h2 class="gamification-drawer__progress-box-title">
							{{ t('builder.gamificationProgressBoxTitle') }}
						</h2>
						<div>
							<CircularProgressBar
								:percentage="progressPercentage"
								size="large"
								class="sidebar-controls__progress-bar"
							>
								{{ completedAchievementsCount }}/{{ achievements.length }}
							</CircularProgressBar>
						</div>
					</div>
					<GamificationDrawerReferralBanner v-if="referralsStore.isReferralNudgeGamificationShown" />
					<GamificationDrawerTimer v-else-if="isGamificationTimerVisible" />
					<GamificationTaskList
						show-icon
						:achievements
					/>
				</div>
				<GamificationTaskList
					v-if="ecommerceTips.length"
					:achievements="ecommerceTips"
					:title="t('builder.gamificationOnlineStoreSetupTitle')"
					show-icon
					location="editor_gamification_drawer"
				/>
				<GamificationTaskList
					:achievements="otherTips"
					:title="t('builder.gamificationOtherTipsTitleV2')"
				/>
				<NpsRateFeature
					hide-border
					class="gamification-drawer__nps"
					:feature-name="$t('builder.gamificationNpsTitle')"
					:type="NPS_TYPE_FEATURE_GAMIFICATION"
				/>
			</div>
		</template>
	</ZyroDrawer>
</template>

<script setup lang="ts">
import {
	NPS_TYPE_FEATURE_GAMIFICATION,
	GAMIFICATION_EVENT_NAMES,
	TIME_DENOMINATOR_DAY,
	REDIRECT_PARAM_VALUES,
} from '@/constants/builderConstants';
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import NpsRateFeature from '@/components/ui/NpsRateFeature.vue';
import GamificationTaskList from '@/components/onboarding/GamificationTaskList.vue';
import {
	computed,
	onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import EventLogApi from '@/api/EventLogApi';
import { useGamification } from '@/use/useGamification';
import { useSidebar } from '@/use/useSidebar';
import {
	useRoute,
	useRouter,
} from 'vue-router';
import GamificationDrawerTimer from '@/components/GamificationDrawerTimer.vue';
import GamificationDrawerReferralBanner from '@/components/GamificationDrawerReferralBanner.vue';
import { useTimestamp } from '@vueuse/core';
import getAssetLink from '@/utils/getAssetLink';
import CircularProgressBar from '@/components/reusable-components/CircularProgressBar.vue';
import { useGamificationStore } from '@/stores/gamificationStore';
import { useReferralsStore } from '@/stores/referralsStore';

const { t } = useI18n();
const {
	areAllAchievementsCompleted,
	completedAchievementsCount,
	achievements,
	otherTips,
	ecommerceTips,
} = useGamification();
const { closeInnerSidebar } = useSidebar();
const currentTimestamp = useTimestamp();
const router = useRouter();
const route = useRoute();
const gamificationStore = useGamificationStore();

const referralsStore = useReferralsStore();

const progressPercentage = computed(() => (completedAchievementsCount.value / achievements.value.length) * 100);
const title = computed(() => (areAllAchievementsCompleted.value
	? t('builder.gamificationDrawerTitleCompleted')
	: t('builder.gamificationDrawerTitleV2')));
const subtitle = computed(() => (areAllAchievementsCompleted.value
	? t('builder.gamificationDrawerDescriptionCompleted')
	: t('builder.gamificationDrawerDescriptionV2')));

EventLogApi.logEvent({
	eventName: GAMIFICATION_EVENT_NAMES.ENTER,
});

if (!areAllAchievementsCompleted.value) {
	window.hj('event', 'website_builder.gamefication.enter');
}

const isGamificationTimerVisible = computed(() => {
	const elapsedTime = (gamificationStore?.createdAt || currentTimestamp.value) + TIME_DENOMINATOR_DAY;
	const isGamificationTimerDone = elapsedTime < currentTimestamp.value;

	return areAllAchievementsCompleted.value || !isGamificationTimerDone;
});

const imageBackdropUrl = `url(${getAssetLink('/assets/images/gamification-drawer-bg.png')})`;

onMounted(() => {
	const {
		drawer,
		...restQuery
	} = route.query;

	if (drawer !== REDIRECT_PARAM_VALUES.GAMIFICATION) {
		return;
	}

	router.replace({
		...route,
		query: restQuery,
	});
});
</script>

<style lang="scss" scoped>
.gamification-drawer {
	overflow-x: hidden;
	overflow-y: auto;

	:deep(.drawer__close) {
		z-index: 1;

		&:not(:hover) {
			color: var(--color-light);
		}
	}

	:deep(.drawer-body) {
		overflow: visible;
	}

	:deep(.drawer) {
		height: auto;
	}

	&__content {
		position: relative;
		background-color: var(--color-light);
		border-radius: 16px;
	}

	&__header {
		flex: 0;
		margin: -16px -16px 0;
		padding: 0 16px;
		width: auto;
		position: relative;
		color: var(--color-light);
	}

	&__bg-container {
		height: 280px;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-image: v-bind(imageBackdropUrl);
		border-radius: 0 0 48px 48px;
	}

	&__main-actions {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px;
		margin-bottom: 40px;
		box-shadow: 0 0 12px 0 #1D1E2029;
		border-radius: 16px;
	}

	&__header-container {
		margin-bottom: 24px;
	}

	&__progress-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__progress-box-title {
		font-size: 20px;
		line-height:32px;
	}

	&__image {
		width: 32px;
		height: 32px;
		margin-left: 8px;
	}

	&__title-container {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}

	&__nps {
		border-top: 1px solid var(--gray-border);
		margin-top: 24px;
	}
}
</style>
