<script setup lang="ts">
import {
	computed,
	ref,
	onMounted,
} from 'vue';
import SegmentedProgressBar from '@/components/global/SegmentedProgressBar.vue';
import {
	MODAL_UPGRADE_TO_BUSINESS_PLAN,
	ECOMMERCE_FUNNEL_LOCATIONS,
	AI_GENERATE_MAX_LENGTH,
} from '@/constants/builderConstants';
import { OPEN_MODAL } from '@/store/builder/gui';
import { useI18n } from 'vue-i18n';
import CollapsibleContainer from '@/components/CollapsibleContainer.vue';
import ZyroFieldTextArea from '@/components/global/ZyroFieldTextArea.vue';
import EventLogApi from '@/api/EventLogApi';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';
import FeatureLockBanner from '@/components/FeatureLockBanner.vue';
import { useAiWriterStore } from '@/stores/aiWriterStore';
import { useUserStore } from '@/stores/userStore';
import { useAiToolsStore } from '@/stores/aiToolsStore';
import { useAiWriterDrawerStore } from '@/stores/aiWriterDrawerStore';
import { useStore } from 'vuex';
import { useAiField } from '@/use/useAiField';

type Props = {
	userPrompt: string;
	isLoading?: boolean;
	location?: string;
}
const props = defineProps<Props>();
const emit = defineEmits<{
	'update:user-prompt': [string],
	'generate': []
}>();

const aiWriterStore = useAiWriterStore();
const aiToolsStore = useAiToolsStore();
const aiWriterDrawerStore = useAiWriterDrawerStore();
const userStore = useUserStore();

const { t } = useI18n();
const { dispatch } = useStore();

const {
	fieldValue,
	fieldError,
	fieldProgress,
	validateField,
	setFieldValue,
	onFieldBlur,
	isSubmitClicked,
} = useAiField({
	initialValue: props.userPrompt,
});

const isExampleShown = ref(false);

const isDisabled = computed(() => aiWriterStore.isAiTextGeneratorDailyLimitReached);

const toggleIsExampleShown = () => {
	isExampleShown.value = !isExampleShown.value;
};

const handleGenerateClick = () => {
	isSubmitClicked.value = true;

	if (!validateField()) {
		return;
	}

	if (userStore.areFeaturesLocked) {
		dispatch(`gui/${OPEN_MODAL}`, {
			name: MODAL_UPGRADE_TO_BUSINESS_PLAN,
			settings: {
				location: ECOMMERCE_FUNNEL_LOCATIONS.AI_TEXT_GENERATOR_FORM,
			},
		});

		return;
	}

	aiWriterDrawerStore.setUserPrompt(fieldValue.value);
	emit('update:user-prompt', fieldValue.value);

	emit('generate');

	EventLogApi.logEvent({
		eventName: 'website_builder.ai_assist.generate',
		eventProperties: {
			...(props.location && {
				location: props.location,
			}),
		},
	});
};

onMounted(() => {
	if (aiToolsStore.getAiToolsPrompt) {
		emit('update:user-prompt', aiToolsStore.getAiToolsPrompt);

		aiToolsStore.clearAiToolsPrompt();
	}
});
</script>
<template>
	<form
		class="ai-text-generator"
		@submit.prevent="handleGenerateClick"
	>
		<ZyroFieldTextArea
			:model-value="fieldValue"
			class="ai-text-generator__text-area"
			qa-selector="ai-text-generator-prompt-input"
			theme="hostinger"
			:placeholder="$t('builder.AITextGeneratorInputPlaceholder')"
			:label="$t('builder.textDescription')"
			:min-height="96"
			:maxlength="AI_GENERATE_MAX_LENGTH"
			:error="fieldError"
			is-resizable
			autofocus
			@update:model-value="setFieldValue($event)"
			@text-area-blur="onFieldBlur"
		/>
		<div class="ai-text-generator__progress">
			<SegmentedProgressBar
				:progress="fieldProgress.progress"
				:colors="fieldProgress.colors"
			/>
			<div class="ai-text-generator__progress-text">
				<p class="text-body-3">
					{{ fieldProgress.label }}
				</p>
				<span class="text-body-3">
					{{ fieldValue.length }}/{{ AI_GENERATE_MAX_LENGTH }}
				</span>
			</div>
		</div>
		<div class="ai-text-generator__example-button-container">
			<HostingerButton
				:title="$t('common.seeExamples')"
				button-type="plain"
				size="sm"
				class="ai-text-generator__example-button"
				@click="toggleIsExampleShown"
			>
				<template #icon-left>
					<Icon
						name="lightbulb"
						class="ai-text-generator__icon"
						is-filled
						dimensions="16px"
					/>
				</template>
				<span>{{ isExampleShown ? $t('common.hideExamples') : $t('common.seeExamples') }}</span>
				<template #icon-right>
					<Icon
						name="chevron_right"
						class="ai-text-generator__icon"
						dimensions="16px"
						:direction="isExampleShown ? 'right' : undefined"
					/>
				</template>
			</HostingerButton>
			<CollapsibleContainer
				:is-visible="isExampleShown"
				max-height="200px"
			>
				<div class="ai-text-generator__example-text text-body-3">
					<p>
						{{ $t('builder.aiWriterDescribeText') }}
					</p>
					<ul>
						<li>{{ $t('builder.aiWriterTextExample0') }}</li>
						<li>{{ $t('builder.aiWriterTextExample1') }}</li>
					</ul>
				</div>
			</CollapsibleContainer>
		</div>
		<HostingerButton
			v-qa="'ai-text-generator-button'"
			class="ai-text-generator__button"
			:is-disabled="isDisabled || isLoading"
			type="submit"
		>
			<template #icon-left>
				<ZyroLoader
					v-if="isLoading"
					size="24px"
					color="var(--color-light)"
				/>
				<Icon
					v-else
					is-custom
					name="stars"
					is-filled
				/>
			</template>
			{{ $t(`builder.${isLoading ? 'creatingText' : 'createText'}`) }}
		</HostingerButton>
		<div
			v-if="userStore.areFeaturesLocked"
			class="ai-text-generator__feature-lock-banner"
		>
			<FeatureLockBanner />
		</div>
		<p
			v-else-if="aiWriterStore.isAiTextGeneratorDailyLimitReached"
			class="ai-text-generator__limit-disclaimer"
		>
			{{ t('builder.AITextGeneratorPopupDailyLimitReachedDisclaimer') }}
		</p>
	</form>
</template>

<style lang="scss" scoped>
.ai-text-generator {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media screen and (max-width: $media-mobile) {
		width: 100%;
	}

	&__progress-text {
		display: flex;
		justify-content: space-between;
		color: var(--color-gray);
		margin-top: 4px;
	}

	&__text-area {
		width: 100%;
		margin-bottom: 0;

		:deep(textarea) {
			border: 1px solid $color-gray-border;
			padding: 12px 16px;
		}
	}

	&__progress {
		width: 100%;
		margin: 8px 0 0;
	}

	&__button {
		padding: 8px 24px 8px 16px;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	&__limit-disclaimer {
		font-size: 12px;
		line-height: 1.66;
		color: $color-gray;
		margin-top: 8px;
	}

	&__feature-lock-banner {
		margin-top: 16px;
	}

	&__error-message {
		margin-top: 8px;
		overflow: hidden;
		font-size: 13px;
		color: $color-primary;
		text-align: left;
		text-overflow: ellipsis;
		user-select: text;
	}

	&__example-button-container {
		text-align: left;
		margin-right: auto;
		margin-bottom: 16px;
	}

	&__example-button {
		color: $color-primary;
		border: none;
		padding: 0;

		:deep(.hostinger-button__text) {
			margin: 0 4px;
			font-weight: 400;
		}

		&:focus, &:hover {
			color: $color-primary;
			background-color: $color-light;
			border: none;
		}
  }

	&__example-text {
		display: flex;
		flex-direction: column;
		gap: 8px;
		color: $color-gray;
		padding: 12px 16px;
		border: 1px solid $color-gray-border;
		border-radius: 4px;
		user-select: text;

		& li {
			list-style-position: inside;
		}
	}
}
</style>
