import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { captureException } from '@sentry/vue';
import { useNotifications } from '@/use/useNotifications';
import { generateText } from '@/api/AiApi';
import { PINIA_STORES } from '@/constants/stores';

export const useAiWriterStore = defineStore(PINIA_STORES.AI_WRITER, () => {
	const isTextBeingGenerated = ref(false);
	const isAiTextGeneratorDailyLimitReached = ref(false);

	const { t } = useI18n();
	const { notify } = useNotifications();

	const getAiGeneratedText = async ({ userPrompt }: { userPrompt: string }): Promise<{ text?: string }> => {
		isTextBeingGenerated.value = true;

		try {
			const { data } = await generateText({
				prompt: userPrompt,
			});

			return data;
		} catch (error) {
			if (error && (error as { error: number }).error === 429) {
				isAiTextGeneratorDailyLimitReached.value = true;
				notify({
					message: t('builder.AITextGeneratorPopupDailyLimitReachedDisclaimer'),
				});

				return {};
			}

			notify({
				message: t('builder.AITextGenerationFailed'),
			});
			captureException(error);

			return {};
		} finally {
			isTextBeingGenerated.value = false;
		}
	};

	const setIsTextBeingGenerated = (value: boolean) => {
		isTextBeingGenerated.value = value;
	};

	return {
		getAiGeneratedText,
		isTextBeingGenerated,
		isAiTextGeneratorDailyLimitReached,
		setIsTextBeingGenerated,
	};
});
