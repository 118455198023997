export const BILLING_PERIODS = {
	QUADRENNIALLY: 'quadrennially',
	ANNUALLY: 'annually',
	BIENNIALLY: 'biennially',
	MONTHLY: 'monthly',
} as const;

export const WEBSITE_STATES = {
	DELETED: 'deleted',
	ACTIVE: 'active',
};

export const HOSTING_PLANS = {
	STARTER: 'hostinger_starter',
	BUSINESS: 'hostinger_business',
	PREMIUM: 'hostinger_premium',
	CLOUD_ECONOMY: 'cloud_economy',
	CLOUD_ENTERPRISE: 'cloud_enterprise',
	CLOUD_PROFESSIONAL: 'cloud_professional',
} as const;

export const HOSTING_PLANS_PRIORITY = {
	[HOSTING_PLANS.CLOUD_ENTERPRISE]: 1,
	[HOSTING_PLANS.CLOUD_PROFESSIONAL]: 2,
	[HOSTING_PLANS.CLOUD_ECONOMY]: 3,
	[HOSTING_PLANS.BUSINESS]: 4,
	[HOSTING_PLANS.PREMIUM]: 5,
	[HOSTING_PLANS.STARTER]: 6,
} as const;
