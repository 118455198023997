<template>
	<div
		v-qa="`gamification-task-item-${item.id}`"
		class="gamification-task-item"
		:class="{ 'gamification-task-item--active': item.clickAction }"
		@click="handleItemClicked(item)"
	>
		<Icon
			v-if="showIcon"
			class="gamification-task-item__icon"
			:class="{
				'gamification-task-item__icon--success': item.isCompleted,
				'gamification-task-item__icon--pending': item.isPendingCompletion
			}"
			:name="getIcon(item)"
			:is-filled="item.isCompleted"
		/>

		<div class="gamification-task-item__text-container">
			<div :class="{ 'gamification-task-item__text-full-width': !showIcon }">
				<p class="text-body-2">
					<slot />
				</p>
				<p
					v-if="item.drawerNote"
					class="gamification-task-item__note text-body-2"
				>
					{{ item.drawerNote }}
				</p>
			</div>

			<Icon
				v-if="item.clickAction"
				name="chevron_right"
				class="gamification-task-item__arrow"
				data-qa="gamification-task-item-arrow"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import EventLogApi from '@/api/EventLogApi';
import Icon from '@/components/global/Icon.vue';
import { useStore } from 'vuex';
import { CLOSE_DRAWER } from '@/store/builder/gui';
import { GAMIFICATION_EVENT_NAMES } from '@/constants/builderConstants';
import { Achievement } from '@/types/gamification';

interface Props {
	isLastItem: boolean;
	item: Achievement;
	showIcon? :boolean;
	location?: string;
}

const props = withDefaults(defineProps<Props>(), {
	showIcon: true,
});

const { dispatch } = useStore();

const handleItemClicked = (item: Achievement) => {
	if (!item.clickAction) {
		return;
	}

	dispatch(`gui/${CLOSE_DRAWER}`);
	item.clickAction();

	EventLogApi.logEvent({
		eventName: GAMIFICATION_EVENT_NAMES.TASK_ENTER[item.id],
		...(props.location ? {
			eventProperties: {
				location: props.location,
			},
		} : {}),
	});
};

const getIcon = (item: Achievement) => {
	if (item.isPendingCompletion) {
		return 'check_circle_outline';
	}

	return item.isCompleted ? 'check_circle' : 'circle';
};
</script>

<style lang="scss" scoped>
.gamification-task-item {
	$this: &;

	position: relative;
	display: flex;
	width: 100%;
	padding: 11px 16px;
	border: 1px solid var(--color-gray-border);
	border-radius: 12px;

	&__icon {
		display: inline-flex;
		justify-content: center;
		margin: 0 12px 0 0;
		color: $color-gray-border;

		&--success {
			color: $color-success;
		}

		&--pending {
			color: $color-warning;
		}
	}

	&__note {
		color: $color-gray;
		max-width: 260px;
		margin-top: 8px;
	}

	&__text-container {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__text-full-width {
		padding: 0 8px;
	}

	&__arrow {
		color: $color-gray;
		transition: transform 0.3s ease;
		transform: translateX(0);
	}

	&--active {
		cursor: pointer;

		&:hover {
			background-color: $color-gray-light;

			#{$this}__arrow {
				transform: translateX(8px);
			}
		}
	}
}
</style>
