<script setup lang="ts">
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import getAssetLink from '@/utils/getAssetLink';
import { useProductTemplateOnboarding } from '@/use/useProductTemplateOnboarding';

const {
	startProductTemplateOnboarding,
	finishProductTemplateOnboarding,
} = useProductTemplateOnboarding();

</script>

<template>
	<ZyroModal
		max-width="520px"
		height="fit-content"
		content-padding="0"
		use-flex-for-content
		class="template-migration-modal"
		@close-modal="finishProductTemplateOnboarding"
	>
		<div class="template-migration-modal__content">
			<h2 class="template-migration-modal__title text-heading-2">
				{{ $t('builder.productPageTemplateIntroductionModalTitle') }}
			</h2>

			<p class="template-migration-modal__text text-body-2">
				{{ $t('builder.productPageTemplateIntroductionModalDescription') }}
			</p>

			<div class="template-migration-modal__video-container">
				<div class="template-migration-modal__video-mask">
					<video
						v-qa="'template-migration-modal-video'"
						autoplay
						loop
						muted
						class="template-migration-modal__video"
						:poster="getAssetLink('/assets/images/ecommerce-product-template.png')"
					>
						<source
							:src="getAssetLink(`/assets/videos/product-page-template-modal-video.mp4`)"
							type="video/mp4"
						>
					</video>
				</div>
			</div>

			<p class="template-migration-modal__text text-body-3">
				{{ $t('builder.productPageTemplateIntroductionModalNote') }}
			</p>

			<HostingerButton
				v-qa="'template-migration-modal-cta'"
				class="template-migration-modal__cta"
				@click="startProductTemplateOnboarding"
			>
				{{ $t('common.learnMore') }}
			</HostingerButton>
		</div>
	</ZyroModal>
</template>

<style lang="scss" scoped>
.template-migration-modal {
	display: flex;
	flex-direction: column;

	&__content {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 24px;
	}

	&__title {
		margin-right: 32px;
	}

	&__text {
		display: inline-block;
		color: $color-gray;
	}

	&__cta {
		margin-left: auto;
		margin-top: 16px;
	}

	&__image,
	&__video-container {
		margin-top: 16px;

		@media screen and (max-width: $media-mobile) {
			margin-top: 32px;
			margin-left: 0;
		}
	}

	&__video-mask {
		display: flex;
		border-radius: 12px;
		overflow: hidden;
	}

	&__video,
	&__image {
		width: 472px;
		height: 266px;

		@media screen and (max-width: $media-mobile) {
			width: 100%;
			height: auto;
		}
	}

}
</style>
