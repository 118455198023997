<script setup lang="ts">
import {
	computed,
	ref,
	ComponentPublicInstance,
} from 'vue';

import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import EditButton from '@/components/builder-controls/edit-button/EditButton.vue';

import { useGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridButton';
import { useElementEditPopup } from '@/use/useElementEditPopup';
import { useStore } from 'vuex';
import {
	LINK_TYPE,
	SiteElementGridButton,
	SitePage,
} from '@hostinger/builder-schema-validator';
import { useSiteStore } from '@/stores/siteStore';
import { getIsDynamicProductPageEnabled } from '@zyro-inc/site-modules/utils/ecommerce/siteModulesUtils';
import { storeToRefs } from 'pinia';
import { PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT } from '@zyro-inc/site-modules/constants/siteModulesConstants';

const props = defineProps<{
	data: SiteElementGridButton;
	elementId: string;
	isActive?: boolean;
	isPreviewMode?: boolean;
}>();

const { dispatch } = useStore();
const siteStore = useSiteStore();
const {
	siteBlocks,
	sitePages,
	defaultLocale,
	site,
} = storeToRefs(siteStore);

const previewButtonNavigate = async (e: MouseEvent) => {
	e.preventDefault();

	if (props.data.settings.isFormButton || !props.data.href) {
		return;
	}

	const [path, anchor] = props.data.href.split('#');

	const pageSlug = path.split('/').pop();

	if (props.data.linkType === LINK_TYPE.PAGE && props.data.linkedPageId) {
		const isDynamicProductPageEnabled = getIsDynamicProductPageEnabled({
			defaultLocalePages: site.value.languages[defaultLocale.value]?.pages,
		});

		const isDynamicProductPage = Object.entries(sitePages.value)
		// eslint-disable-next-line max-len
			.some(([key, { type }]: [string, SitePage]) => type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT && key === props.data.linkedPageId);

		if (isDynamicProductPageEnabled && isDynamicProductPage) {
			dispatch('updateCurrentLocale', defaultLocale.value);
		}

		dispatch('updateCurrentPageSlug', pageSlug);
		dispatch('updateCurrentPageId', props.data.linkedPageId);

		return;
	}

	if (props.data.linkType === LINK_TYPE.ANCHORED_SECTION && props.data.linkedPageId) {
		const pageId = Object.entries(sitePages.value).find(([, { slug }]) => slug === pageSlug)?.[0];
		const blockId = Object.entries(siteBlocks.value).find(([, { htmlId }]) => htmlId === anchor)?.[0];

		await dispatch('updateCurrentPageId', pageId);

		if (!blockId) {
			return;
		}

		const element = document.querySelector(`[data-block-id="${blockId}"]`) as HTMLElement;

		if (!element) {
			return;
		}

		window.requestAnimationFrame(() => {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		});

		return;
	}

	window.open(props.data.href, '_blank');
};

const {
	tagName,
	href,
	target,
	rel,
	type,
	content,
	fontSizeMobile,
	fontSizeDesktop,
	fontFamily,
	fontWeight,
	borderWidth,
	borderRadius,
	backgroundColor,
	fontColor,
	borderColor,
	backgroundColorHover,
	fontColorHover,
	borderColorHover,
	mobileElementWidth,
	mobileElementHeight,
} = useGridButton(props, {
	href: computed(() => props.data.href),
});

const buttonRef = ref<ComponentPublicInstance<HTMLElement> | null>(null);

const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});
</script>

<template>
	<GridButton
		ref="buttonRef"
		v-qa="'builder-gridelement-gridbutton'"
		:data-element-ref="props.elementId"
		:tag-name="tagName"
		class="layout-element__component layout-element__component--GridButton"
		:href="href"
		:target="target"
		:rel="rel"
		:type="type"
		:content="content"
		:font-size-mobile="fontSizeMobile"
		:font-size-desktop="fontSizeDesktop"
		:font-family="fontFamily"
		:font-weight="fontWeight"
		:border-width="borderWidth"
		:border-radius="borderRadius"
		:background-color="backgroundColor"
		:font-color="fontColor"
		:border-color="borderColor"
		:background-color-hover="backgroundColorHover"
		:font-color-hover="fontColorHover"
		:border-color-hover="borderColorHover"
		:mobile-element-width="mobileElementWidth"
		:mobile-element-height="mobileElementHeight"
		is-in-builder
		@click="(e: MouseEvent) => isPreviewMode ? previewButtonNavigate(e) : e.preventDefault()"
		@drag.prevent
		@dragstart.prevent
	/>
	<ElementEditControls
		v-if="!isPreviewMode"
		:target-ref="buttonRef?.$el"
		:element-id="props.elementId"
		:is-element-active="props.isActive"
		:enter-edit-mode-button-title="$t('builder.editButton.title')"
	>
		<template #edit-mode-popup>
			<EditButton @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>
</template>
