<template>
	<div
		v-if="!!currentBlockBackground?.current"
		class="zyro-background-selector"
	>
		<ZyroSegmentControl
			class="zyro-background-selector__tabs"
			:controls="tabs"
			:is-background-dark="isSegmentControlBackgroundDark"
			:active-control="currentTab"
			@update:active-control="changeTab"
		/>
		<ZyroFieldColorPicker
			v-if="['color', 'gradient'].includes(currentTab.id)"
			has-gradient
			:is-open="isColorPickerOpen"
			:label="$t('builder.sectionColor')"
			:color="currentBlockBackground.color"
			:gradient="currentBlockBackground.gradient"
			:current="currentBlockBackground.current"
			placement="left"
			class="zyro-background-selector__color-picker"
			:portal-selector="SELECTOR_DATA_PORTAL_APP"
			:is-opacity-disabled="isOpacityDisabled"
			@update-color="handleBackgroundColorUpdate"
			@update-gradient="handleBackgroundGradientUpdate"
			@toggle="isColorPickerOpen = !isColorPickerOpen"
			@click-outside="isColorPickerOpen = false"
		/>
		<VideoSelector v-if="isBackgroundVideo" />
		<ZyroImageSelector
			v-if="currentTab.id === BACKGROUND_TYPES.IMAGE"
			:origin="currentBlockBackground.origin"
			:path="currentBlockBackground.path"
			object-fit="contain"
			@update="handleBackgroundImageUpdate"
		/>

		<template v-if="isOverlayOpacityEnabled">
			<ZyroLabel class="background-overlay-label">
				{{ $t('builder.editBlockTabBackground.overlayOpacity') }}
			</ZyroLabel>
			<ZyroRange
				v-qa="`backgroundsettings-overlayopacity-${overlayOpacity}`"
				:model-value="overlayOpacity"
				units="%"
				min="0"
				step="1"
				max="100"
				@update:model-value="updateOverlayOpacity"
			/>
		</template>
	</div>
</template>

<script setup lang="ts">
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroRange from '@/components/global/ZyroRange.vue';
import ZyroFieldColorPicker from '@/components/global/ZyroFieldColorPicker.vue';
import VideoSelector from '@/components/global/VideoSelector.vue';
import ZyroImageSelector from '@/components/global/ZyroImageSelector.vue';
import ZyroSegmentControl from '@/components/global/ZyroSegmentControl.vue';
import { useGamification } from '@/use/useGamification';
import {
	GAMIFICATION_TASK_UPDATE_IMAGE,
	SELECTOR_DATA_PORTAL_APP,
} from '@/constants/builderConstants';
import { updateAiGeneratedImagePath } from '@/utils/urlValidators';
import { useI18n } from 'vue-i18n';
import {
	computed,
	ref,
} from 'vue';
import {
	SiteBackgroundGradient,
	SiteBackgroundType,
} from '@hostinger/builder-schema-validator';
import { useStore } from 'vuex';
import {
	BACKGROUND_TYPES,
	BLOCK_TYPE_STICKY_BAR,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import EventLogApi from '@/api/EventLogApi';
import { defaultPexelsVideos } from '@/use/defaultPexelsVideo';

type Props = {
	isSegmentControlBackgroundDark?: boolean;
	isOpacityDisabled?: boolean;
}

type BackgroundImageInput = {
	url: string;
	origin: string;
	path: string;
	alt?: string;
};

const props = withDefaults(defineProps<Props>(), {
	isSegmentControlBackgroundDark: false,
	isOpacityDisabled: false,
});

const { t } = useI18n();
const { completeAchievement } = useGamification();
const {
	getters,
	dispatch,
	state,
} = useStore();

const currentBlockId = computed(() => state.currentBlockId);
const isCurrentBlockStickybar = computed(() => getters.currentBlock.type === BLOCK_TYPE_STICKY_BAR);
const currentBlockBackground = computed(() => getters.currentBlock.background);
const colorTabId = computed(() => {
	if (currentBlockBackground.value?.current === BACKGROUND_TYPES.GRADIENT) {
		return BACKGROUND_TYPES.GRADIENT;
	}

	return BACKGROUND_TYPES.COLOR;
});

const tabs = computed(() => [
	{
		title: t('common.color'),
		id: colorTabId.value,
		localId: BACKGROUND_TYPES.COLOR,
	},
	{
		title: t('common.image'),
		id: BACKGROUND_TYPES.IMAGE,
	},
	...(!isCurrentBlockStickybar.value ? [
		{
			title: t('common.video'),
			id: BACKGROUND_TYPES.VIDEO,
		},
	] : []),
]);

const currentTabId = ref<string>(currentBlockBackground.value?.current || BACKGROUND_TYPES.COLOR);
const isColorPickerOpen = ref(false);
const isBackgroundImage = computed(() => currentBlockBackground.value?.current === BACKGROUND_TYPES.IMAGE);
const isBackgroundVideo = computed(() => currentBlockBackground.value?.current === BACKGROUND_TYPES.VIDEO);
const isOverlayOpacityEnabled = computed(() => (isBackgroundImage.value || isBackgroundVideo.value) && !props.isOpacityDisabled);
const hasVideoSrc = computed(() => currentBlockBackground.value?.video?.videoSrc);
const currentTab = computed(() => (tabs.value.find((tab) => tab.id === currentTabId.value) || tabs.value[0]));
const overlayOpacity = computed(() => {
	if (!currentBlockBackground.value?.['overlay-opacity']) {
		return 0;
	}

	return Math.round(Number.parseFloat(currentBlockBackground.value['overlay-opacity']) * 100);
});

const updateOverlayOpacity = (newValue: number) => {
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			background: {
				...currentBlockBackground.value,
				'overlay-opacity': `${(newValue / 100).toFixed(2)}`,
			},
		},
		merge: true,
	});
};

const handleBackgroundGradientUpdate = (gradient: SiteBackgroundGradient) => {
	const colorTab = tabs.value.find((tab) => tab.localId === BACKGROUND_TYPES.COLOR);

	if (!colorTab || !currentBlockBackground.value) {
		return;
	}

	currentTabId.value = BACKGROUND_TYPES.GRADIENT;
	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			background: {
				gradient,
				color: undefined,
				current: BACKGROUND_TYPES.GRADIENT,
			},
		},
		merge: true,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.section_settings.section_background_change',
		eventProperties: {
			type: BACKGROUND_TYPES.GRADIENT,
		},
	});
};

const handleBackgroundColorUpdate = (color: string) => {
	const colorTab = tabs.value.find((tab) => tab.localId === BACKGROUND_TYPES.COLOR);

	if (!colorTab || !currentBlockBackground.value) {
		return;
	}

	currentTabId.value = BACKGROUND_TYPES.COLOR;

	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			background: {
				color,
				gradient: undefined,
				current: BACKGROUND_TYPES.COLOR,
			},
		},
		merge: true,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.section_settings.section_background_change',
		eventProperties: {
			type: BACKGROUND_TYPES.COLOR,
		},
	});
};

const handleBackgroundImageUpdate = ({
	url,
	origin,
	path,
	alt,
}:BackgroundImageInput) => {
	const validPath = updateAiGeneratedImagePath(url, path);

	currentTabId.value = BACKGROUND_TYPES.IMAGE;

	dispatch('updateBlockData', {
		blockId: currentBlockId.value,
		blockData: {
			background: {
				image: url,
				origin,
				path: validPath,
				current: BACKGROUND_TYPES.IMAGE,
				alt: alt || undefined,
			},
		},
		merge: true,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.section_settings.section_background_change',
		eventProperties: {
			type: BACKGROUND_TYPES.IMAGE,
		},
	});

	completeAchievement(GAMIFICATION_TASK_UPDATE_IMAGE);
};

const changeTab = (value: {id: SiteBackgroundType, title: string}) => {
	currentTabId.value = value.id;

	const VIDEO_BACKGROUND_DEFAULT_VALUES = {
		video: {
			videoSrc: defaultPexelsVideos[0]?.video_files.at(-1)?.link,
			videoThumbnailSrc: defaultPexelsVideos[0]?.image,
		},
	};

	if (currentBlockBackground.value?.current) {
		dispatch('updateBlockData', {
			blockId: currentBlockId.value,
			blockData: {
				background: {
					current: value.id,
					...(value.id === BACKGROUND_TYPES.VIDEO && !hasVideoSrc.value && VIDEO_BACKGROUND_DEFAULT_VALUES),
				},
			},
			merge: true,
		});
	}
};
</script>

<style lang="scss" scoped>
.zyro-background-selector {
	width: 100%;

	&__color-picker {
		padding-bottom: 8px;
	}

	&__tabs {
		margin-bottom: 16px;
	}

	&__wrapper {
		display: flex;
	}
}

.background-overlay-label,
.background-overlay-separator {
	margin: 16px 0 8px;
}
</style>
