<script lang="ts" setup>
import AiBuilderLoader from '@/components/ai-builder/AiBuilderLoader.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import SegmentedProgressBar from '@/components/global/SegmentedProgressBar.vue';
import ZyroFieldTextArea from '@/components/global/ZyroFieldTextArea.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import {
	CLOSE_MODAL,
	OPEN_MODAL,
} from '@/store/builder/gui';
import {
	MODAL_UPGRADE_TO_BUSINESS_PLAN,
	ECOMMERCE_FUNNEL_LOCATIONS,
	AI_GENERATE_MAX_LENGTH,
	MODAL_GENERATE_SECTION,
	AI_SECTION_GENERATE_MAX_LENGTH,
} from '@/constants/builderConstants';
import { useAiBuilderLoader } from '@/use/useAiBuilderLoader';
import FeatureLockBanner from '@/components/FeatureLockBanner.vue';
import {
	computed,
	onMounted,
	ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useUserStore } from '@/stores/userStore';
import { useAiToolsStore } from '@/stores/aiToolsStore';
import { useAiField } from '@/use/useAiField';

type Props = {
	isGenerating: boolean;
	generateModalTitle: string;
	exampleDescriptions: Array<{
		title: string;
		text: string;
	}>;
	buttonDataQa: string,
	textAreaPlaceholder: string;
	textAreaLabel: string;
	generateButtonText: string;
}

const props = withDefaults(defineProps<Props>(), {
	textAreaPlaceholder: '',
	generateButtonText: '',
	buttonDataQa: '',
});

const { t } = useI18n();
const {
	dispatch,
	state,
} = useStore();
const userStore = useUserStore();
const { steps } = useAiBuilderLoader();

const aiToolsStore = useAiToolsStore();

const {
	fieldValue,
	fieldError,
	fieldProgress,
	setFieldValue,
	onFieldBlur,
	validateField,
	isSubmitClicked,
} = useAiField({
	initialValue: '',
});

const isExamplesVisible = ref(false);

const maxDescriptionLength = computed(() => {
	if (state.gui.activeModalName === MODAL_GENERATE_SECTION) {
		return AI_SECTION_GENERATE_MAX_LENGTH;
	}

	return AI_GENERATE_MAX_LENGTH;
});

const emit = defineEmits(['generate-content']);

const handleGenerate = () => {
	isSubmitClicked.value = true;

	if (!validateField()) {
		return;
	}

	if (userStore.areFeaturesLocked) {
		dispatch(`gui/${OPEN_MODAL}`, {
			name: MODAL_UPGRADE_TO_BUSINESS_PLAN,
			settings: {
				location: ECOMMERCE_FUNNEL_LOCATIONS.GENERATE_CONTENT_MODAL,
			},
		});

		return;
	}

	emit('generate-content', fieldValue.value);
};

onMounted(() => {
	if (aiToolsStore.getAiToolsPrompt) {
		setFieldValue(aiToolsStore.getAiToolsPrompt);

		aiToolsStore.clearAiToolsPrompt();
	}
});

</script>

<template>
	<Teleport
		v-if="!isGenerating"
		to="body"
	>
		<ZyroModal
			class="generate-page-modal"
			is-for-payments
			max-width="600px"
			height="auto"
			label="beta"
			:content-padding="'40px'"
			@close-modal="dispatch(`gui/${CLOSE_MODAL}`)"
		>
			<h2
				class="text-heading-2 generate-page-modal__title"
				align="middle"
			>
				{{ generateModalTitle }}
			</h2>

			<div class="content">
				<p class="text-bold-2">
					{{ textAreaLabel }}
				</p>
				<ZyroFieldTextArea
					v-model="fieldValue"
					v-qa="'generate-content-modal-textarea'"
					class="content__textarea"
					theme="hostinger"
					bold
					:placeholder="textAreaPlaceholder"
					:error="fieldError"
					:min-height="120"
					is-resizable
					:maxlength="maxDescriptionLength"
					@update:model-value="setFieldValue($event)"
					@text-area-blur="onFieldBlur"
				/>
				<SegmentedProgressBar
					is-progress-kept
					:progress="fieldProgress.progress"
					:colors="fieldProgress.colors"
				/>
				<div class="content__progress-text">
					<p class="text-body-3">
						{{ fieldProgress.label }}
					</p>
					<span class="text-body-3">
						{{ fieldValue.length }}/{{ maxDescriptionLength }}
					</span>
				</div>

				<div class="content__examples">
					<HostingerButton
						v-qa="'generate-content-modal-see-examples-button'"
						:title="t('common.seeExamples')"
						class="content__examples-button"
						button-type="plain"
						@click="isExamplesVisible = !isExamplesVisible"
					>
						<template #icon-left>
							<Icon
								name="lightbulb"
								is-filled
								dimensions="16px"
							/>
						</template>

						<span>{{ isExamplesVisible ? t('common.hideExamples') : t('common.seeExamples') }}</span>

						<template #icon-right>
							<Icon
								name="chevron_right"
								dimensions="16px"
								:direction="isExamplesVisible ? 'right' : undefined"
							/>
						</template>
					</HostingerButton>

					<Transition name="fade">
						<ul
							v-if="isExamplesVisible"
							class="content__examples-list"
						>
							<li
								v-for="example in exampleDescriptions"
								:key="example.title"
							>
								<h4 class="text-bold-3">
									{{ t(example.title) }}
								</h4>
								<p class="text-body-3">
									{{ t(example.text) }}
								</p>
							</li>
						</ul>
					</Transition>
				</div>

				<div class="action-button">
					<HostingerButton
						v-qa="props.buttonDataQa"
						@click="handleGenerate"
					>
						<template #icon-left>
							<Icon
								is-custom
								name="stars"
							/>
						</template>
						{{ generateButtonText }}
					</HostingerButton>
				</div>

				<FeatureLockBanner
					v-if="userStore.areFeaturesLocked"
					class="generate-page-modal__feature-lock-banner"
				/>
			</div>
		</ZyroModal>
	</Teleport>
	<AiBuilderLoader
		v-else
		has-background
		is-fixed
		:is-loading="isGenerating"
		:steps="steps.aiPageGenerator"
	/>
</template>

<style lang="scss" scoped>
.action-button {
	margin-top: 24px;
	text-align: center;
}

.content {
	margin-top: 24px;

	&__progress-text {
		display: flex;
		justify-content: space-between;
		color: var(--color-gray);
		margin-top: 4px;
	}

	&__textarea {
		margin: 8px 0;

		:deep(.zyro-textarea) {
			border-radius: 8px;
		}
	}

	&__examples {
		margin-top: 2px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@media screen and (max-width: 460px) {
			margin-top: 0;
			align-items: flex-start;
		}
	}

	&__examples-button {
		color: var(--color-primary);
		font-size: 12px;
		line-height: 20px;
		padding: 0;
		font-weight: 400;

		&:hover, &:active, &:focus {
			background-color: transparent;
		}
	}

	&__examples-list {
		margin-top: 4px;
		border-radius: 4px;
		border: 1px solid var(--color-gray-border);
		color: $color-gray;
		padding: 12px 16px;
		list-style: none;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		display: flex;
	}
}

.generate-page-modal {
	&__feature-lock-banner {
		margin-top: 24px;
	}

	@media screen and (max-width: 460px) {
		&__title {
			margin-top: 24px;
		}

		:deep(.modal__content) {
			padding: 24px;
		}
	}
}
</style>
