<template>
	<div
		class="toast"
		:class="`toast--${theme}`"
		:[DATA_ATTRIBUTE_SELECTOR]="DATA_ATTRIBUTE_SELECTOR_TOAST"
	>
		<div class="toast__content">
			<div class="toast__icon-wrap">
				<Icon
					:name="themeData.iconName"
					is-custom
					class="toast__icon"
					:dimensions="themeData.iconDimensions"
				/>
			</div>
			<div class="toast__text">
				<p
					v-if="heading"
					v-qa="'toast-heading'"
					class="text-bold-1 toast__text-heading"
				>
					{{ heading }}
				</p>
				<p
					v-if="message"
					v-qa="'toast-message'"
					class="text-body-2"
				>
					{{ message }}
				</p>
			</div>
			<HostingerButton
				v-qa="'toast-btn-close'"
				button-type="plain"
				size="sm"
				class="toast__close"
				:title="$t('common.close')"
				@click="$emit('close')"
			>
				<template #icon>
					<Icon name="close" />
				</template>
			</HostingerButton>
		</div>
		<div
			v-if="submitLabel"
			class="toast__footer"
		>
			<HostingerButton
				v-if="isDiscardButtonShown"
				v-qa="'toast-btn-discard'"
				button-type="text"
				class="toast__discard-button"
				@click="$emit('close')"
			>
				{{ discardLabel || t('common.notNow') }}
			</HostingerButton>
			<HostingerButton
				v-qa="'toast-btn-action'"
				class="toast__submit-button"
				v-bind="submitButtonStyles"
				@click="$emit('submit')"
			>
				{{ submitLabel || t('common.tryAgain') }}
			</HostingerButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';

import {
	THEME_ERROR,
	THEME_SUCCESS,
	THEME_WARNING,
	THEME_INFO,
	THEME_LOGO,
	ToastType,
	DATA_ATTRIBUTE_SELECTOR,
	DATA_ATTRIBUTE_SELECTOR_TOAST,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { HostingerButtonProps } from '@/types/sharedPropTypes';

interface Props {
	isDiscardButtonShown?: boolean;
	message: string;
	isCloseButtonShown?: boolean;
	theme?: ToastType;
	discardLabel?: string;
	submitLabel?: string;
	heading?: string;
	submitButtonStyles?: HostingerButtonProps;
}

const props = withDefaults(defineProps<Props>(), {
	isDiscardButtonShown: true,
	isCloseButtonShown: true,
	theme: 'error',
});

const { t } = useI18n();

defineEmits<{
	close: [],
	submit: [],
}>();

const THEMES_CONFIG: Record<ToastType, { iconName: string, iconDimensions: string }> = {
	[THEME_ERROR]: {
		iconName: 'toast-error-circle',
		iconDimensions: '24px',
	},
	[THEME_SUCCESS]: {
		iconName: 'toast-success-circle',
		iconDimensions: '24px',
	},
	[THEME_WARNING]: {
		iconName: 'toast-warning-circle',
		iconDimensions: '24px',
	},
	[THEME_INFO]: {
		iconName: 'toast-info-circle',
		iconDimensions: '24px',
	},
	[THEME_LOGO]: {
		iconName: 'hostinger-builder',
		iconDimensions: '32px',
	},
};

const themeData = computed(() => THEMES_CONFIG[props.theme]);

</script>

<style lang="scss" scoped>
.toast {
	$this: &;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 422px;
	padding: 16px;
	color: $color-dark;
	background-color: $color-light;
	border-radius: 8px;
	border: 1px solid $color-gray-border;
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);

	&:not(:first-child) {
		margin-top: 16px;
	}

	@media screen and (max-width: $media-mobile) {
		max-width: 200px;
		padding: 8px;
	}

	&__content {
		position: relative;
		display: flex;
		width: 100%;

		@media screen and (max-width: $media-mobile) {
			flex-direction: column;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-right: 36px;

		@media screen and (max-width: $media-mobile) {
			padding: 0 8px;
		}
	}

	&__text-heading {
		color: $color-dark;
		margin-bottom: 4px;
	}

	&--error {
		#{$this}__icon {
			color: $color-danger;
		}
	}

	&--success {
		#{$this}__icon {
			color: $color-success;
		}
	}

	&--warning {
		#{$this}__icon {
			color: $color-warning-dark;
		}
	}

	&--info {
		#{$this}__icon {
			color: $color-gray;
		}
	}

	&--logo {
		#{$this}__icon {
			color: $color-primary;
		}

		#{$this}__icon-wrap {
			margin-right: 24px;
		}
	}

	&__icon-wrap {
		display: flex;
		margin-right: 18px;
	}

	&__close {
		position: absolute;
		top: -8px;
		right: -8px;
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin-top: 16px;

		@media screen and (max-width: $media-mobile) {
			flex-direction: column;
		}
	}

	&__discard-button {
		margin-right: 8px;
		padding: 6px 16px;
		font-weight: 700;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 1.25px;
		text-transform: none;
		color: $color-primary;
		border-radius: 4px;

		&:hover {
			color: $color-primary-dark;
			background-color: $color-gray-light;
		}

		@media screen and (max-width: $media-mobile) {
			margin-right: 0;
			display: none;
		}
	}
}
</style>
