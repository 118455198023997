<template>
	<Transition name="scale">
		<div
			v-if="isNotificationShown"
			v-qa="'product-page-notification'"
			class="product-page-notification"
		>
			<div class="product-page-notification__header">
				<p class="text-body-2">
					{{ $t('builder.productPageTemplateNotification') }}
				</p>
				<HostingerButton
					v-qa="'modal-btn-close'"
					button-type="plain"
					:title="$t('common.close')"
					class="product-page-notification__button"
					@click="handleNotificationClose"
				>
					<template #icon>
						<Icon name="close" />
					</template>
				</HostingerButton>
			</div>
			<HImage
				class="product-page-notification__image"
				src="/images/product-template-example.png"
			/>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import HImage from '@/components/global/HImage.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';

defineProps<{
	isNotificationShown: boolean,
}>();
const emit = defineEmits<{
	'close-click': []
}>();

const handleNotificationClose = () => (
	emit('close-click')
);
</script>

<style scoped lang="scss">
.product-page-notification {
	position: absolute;
	top: calc(100% + 16px);
	right: 24px;
	background-color: $color-light;
	max-width: 340px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-radius: 16px;
	width: 100%;
	box-shadow: 0 6px 14px 0 rgba($color-dark, 10%);
	border: 1px solid $color-gray-border;
	transform-origin: top right;

	@media screen and (max-width: $media-mobile) {
		position: fixed;
		top: unset;
		left: unset;
		right: 16px;
		width: calc(100% - 32px); // minus side gaps 2 x 16px
		transform-origin: bottom right;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__button {
		margin-top: -6px;
		margin-right: -6px;
		color: $color-gray;
	}

	&__image {
		width: 100%;
	}
}

.scale-enter-active {
  animation: scale-in 0.4s;
}

.scale-leave-active {
  animation: scale-in 0.4s reverse;
}

@keyframes scale-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
</style>
