<template>
	<HostingerButton
		ref="triggerButton"
		v-qa="'edit-items-list-trigger'"
		:title="$t('common.settings')"
		button-type="plain"
		theme="highlight"
		@click="isPopupOpen = !isPopupOpen"
	>
		<template #icon>
			<Icon
				name="settings"
				dimensions="16px"
			/>
		</template>
	</HostingerButton>
	<Popup
		v-if="isPopupOpen"
		:target-ref="$refs.triggerButton?.$el"
		:portal-selector="SELECTOR_DATA_PORTAL_MODAL"
		placement="right-start"
		@click-outside="isPopupOpen = false"
	>
		<ul
			v-qa="`blog-category-popup`"
			class="popup-content"
		>
			<li>
				<HostingerButton
					v-qa="`edit-items-list-popup-button-edit`"
					button-type="plain"
					class="popup-content__button"
					@click="$emit('edit'), isPopupOpen = false"
				>
					<template #icon-left>
						<Icon
							name="edit"
							dimensions="20px"
						/>
					</template>
					{{ $t('common.edit') }}
				</HostingerButton>
			</li>
			<li>
				<HostingerButton
					v-qa="`edit-items-list-popup-button-duplicate`"
					button-type="plain"
					class="popup-content__button"
					@click="$emit('duplicate'), isPopupOpen = false"
				>
					<template #icon-left>
						<Icon
							name="file_copy"
							dimensions="20px"
						/>
					</template>
					{{ $t('common.duplicate') }}
				</HostingerButton>
			</li>
			<li>
				<HostingerButton
					v-qa="`edit-items-list-popup-button-delete`"
					button-type="plain"
					class="popup-content__button"
					@click="$emit('delete'), isPopupOpen = false"
				>
					<template #icon-left>
						<Icon
							name="delete"
							dimensions="20px"
						/>
					</template>
					{{ $t('common.delete') }}
				</HostingerButton>
			</li>
		</ul>
	</Popup>
</template>

<script setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Popup from '@/components/global/Popup.vue';
import { ref } from 'vue';
import { SELECTOR_DATA_PORTAL_MODAL } from '@/constants/builderConstants';

defineEmits([
	'edit',
	'duplicate',
	'delete',
]);

const isPopupOpen = ref(false);
</script>

<style lang="scss" scoped>
.popup-content {
	padding: 8px;
	overflow: hidden;
	list-style: none;
	background: $color-light;
	border-radius: $border-radius-large;
	box-shadow: 0 5px 5px rgba($color-dark, 0.05), 0 10px 30px rgba($color-dark, 0.2);

	&__button {
		width: 100%;
		font-weight: 400;
	}
}
</style>
