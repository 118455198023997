import axios from '@/services/http/axios';
import { AI_BUILDER_THEME } from '@/constants/builderConstants';
import {
	SiteBlock,
	SiteData,
	SiteElement,
} from '@hostinger/builder-schema-validator';
import {
	GeneratedColorPalette,
	AiBlogPostResponse,
	AiImageGenerationResponse,
	WebsiteType,
	AiBuilderColorPalette,
	UsageResponse,
} from '@/types/aiBuilderTypes';
import { Template } from '@/types/templateTypes';

export const AI_API = `${import.meta.env.VITE_BACKEND_API_URL}/v1/ai`;

export const generateSite = ({
	brandName,
	brandDescription,
	websiteType,
	templateId,
	fonts,
	siteId,
	generateImages = true,
	isHeroImageAiGenerated = false,
	colors,
	domain,
	txtRecord,
	hostingReferenceId,
}: {
	brandName: string
	brandDescription: string
	websiteType?: WebsiteType
	siteId?: string
	templateId?: string
	fonts?: string[]
	generateImages?: boolean
	isHeroImageAiGenerated?: boolean
	colors?: AiBuilderColorPalette,
	domain?: string
	txtRecord?: string
	hostingReferenceId?: string
}) => axios.post<{
	language: string;
	siteData: SiteData;
	ecommerceProducts: any;
	clientTimestamp: number;
	colorPalette: GeneratedColorPalette;
	descriptionLanguage: string;
	websiteType: WebsiteType;
	theme?: string;
	usage?: UsageResponse;
	siteId?: string;
}>(`${AI_API}/generate-site-paid`, {
	brandName,
	websiteDescription: brandDescription,
	websiteType,
	templateId,
	theme: AI_BUILDER_THEME,
	fonts,
	siteName: brandName,
	generateImages,
	isHeroImageAiGenerated,
	...(siteId && {
		siteId,
	}),
	colors,
	domain,
	txtRecord,
	hostingReferenceId,
});

export const fetchWebsiteType = ({
	description,
	brandName,
}: {
	description?: string
	brandName?: string
}) => axios.post<{ websiteType: string }>(`${AI_API}/website-type`, {
	description,
	...(brandName && {
		brandName,
	}),
});

export const callGeneratePage = ({
	pageDescription,
	siteId,
}: {
	pageDescription: string
	siteId: string
}) => axios.post<{ siteData: SiteData}>(`${AI_API}/generate-page`, {
	pageDescription,
	siteId,
}, {
	isRetryNeeded: true,
});

export const generateSection = ({ description }: { description: string }) => axios.post<{
	section: SiteBlock,
	elements: SiteElement[]
}>(`${AI_API}/generate-section`, {
	description,
});

export const templateSearch = ({
	input,
	locale,
}: {
	input: string
	locale: string
}) => axios.post<Template[]>(`${AI_API}/template-search`, {
	input,
	locale,
});

export const generateImages = ({
	description,
	width,
	height,
	siteId,
	count,
}: {
	description: string
	width: number
	height: number
	siteId: string
	count: number
}) => axios.post<AiImageGenerationResponse>(`${AI_API}/generate-images`, {
	description,
	width,
	height,
	siteId,
	count,
});

export const generateBlog = ({
	tone,
	length,
	description,
}: {
	tone: string
	length: string
	description: string
}) => axios.post<AiBlogPostResponse>(`${AI_API}/generate-blog-post`, {
	description,
	tone,
	length,
});

export const getSiteMatchingColorPaletteName = ({ description }: { description: string}) => axios.post(`${AI_API}/color-palette`, {
	description,
});

export const generateText = ({ prompt }: { prompt: string }) => axios.post<{ text: string }>(`${AI_API}/generate-text`, {
	prompt,
});

export const createImageAltTag = ({ imageUrl }: { imageUrl: string }) => axios.post(`${AI_API}/seo/alt-tag`, {
	assetUrl: imageUrl,
});

export const createSeo = ({
	brandDescription,
	keywords,
}: {
	brandDescription: string
	keywords?: string[]
}) => axios.post(`${AI_API}/seo`, {
	text: brandDescription,
	keywords,
});

export const generateTemplateSite = ({
	brandName,
	brandDescription,
	templateId,
	siteId,
	domain,
	txtRecord,
	hostingReferenceId,
}: {
	templateId: string,
	brandName: string,
	brandDescription: string,
	hostingReferenceId?: string,
	txtRecord?: string,
	domain?: string | null,
	siteId?: string,
}) => axios.post<{
	siteData: SiteData,
	clientTimestamp: number,
	siteId: string,
	ecommerceProducts: any
}>(`${AI_API}/generate-template-site`, {
	brandName,
	websiteDescription: brandDescription,
	templateId,
	siteId,
	txtRecord,
	hostingReferenceId,
	...(domain && {
		domain,
	}),
}, {
	isRetryNeeded: true,
});
