<template>
	<GridShape
		ref="shapeRef"
		:data-element-ref="props.elementId"
		class="layout-element__component layout-element__component--GridShape"
		:svg="props.data.svg"
		:style="cssVars"
	/>

	<ElementEditControls
		v-if="!isPreviewMode"
		:target-ref="shapeRef?.$el"
		:element-id="props.elementId"
		:is-element-active="isActive"
		:enter-edit-mode-button-title="$t('builder.editShape.title')"
	>
		<template #additional-edit-buttons>
			<VerticalSeparator />
			<ControlsTooltip :title="$t('builder.helpResources.changeColors')">
				<ColorPicker
					:is-open="isShapeColorPickerOpen"
					:color="props.data.color"
					class="edit-actions-top__button"
					@toggle="isShapeColorPickerOpen = !isShapeColorPickerOpen"
					@update-color="updateShapeColor"
					@click-outside="isShapeColorPickerOpen = false"
				>
					<HostingerButton
						button-type="plain"
						theme="highlight"
					>
						<template #icon>
							<ColorPickerButton :color="props.data.color" />
						</template>
					</HostingerButton>
				</ColorPicker>
			</ControlsTooltip>
		</template>
		<template #edit-mode-popup>
			<EditShape @close="closeElementEditPopupHandler" />
		</template>
	</ElementEditControls>
</template>

<script setup>
import {
	computed,
	ref,
} from 'vue';
import GridShape from '@zyro-inc/site-modules/components/elements/shape/GridShape.vue';

import EditShape from '@/components/builder-controls/edit-shape/EditShape.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ColorPicker from '@/components/global/color-picker/ColorPicker.vue';
import ColorPickerButton from '@/components/global/color-picker/ColorPickerButton.vue';
import ControlsTooltip from '@/components/ControlsTooltip.vue';
import VerticalSeparator from '@/components/global/VerticalSeparator.vue';

import { useShape } from '@/use/useShape';
import { useElementEditPopup } from '@/use/useElementEditPopup';

const props = defineProps({
	elementId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
	isPreviewMode: {
		type: Boolean,
		required: false,
	},
});

const shapeRef = ref(null);
const isShapeColorPickerOpen = ref(false);

const { updateShapeColor } = useShape();

const cssVars = computed(() => ({
	'--shape-color': `${props.data.color}`,
}));

const { closeElementEditPopupHandler } = useElementEditPopup({
	elementId: props.elementId,
});

</script>
