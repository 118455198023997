<script setup lang="ts">
import {
	ref,
	computed,
	watch,
} from 'vue';

import { useStore } from 'vuex';
import { PUBLISH_BUTTON_SELECTOR } from '@/components/onboarding/onboardingSelectors';
import {
	P2P_DAYS_UNTIL_SITE_DELETED_URGENT,
	REDIRECT_PARAM_KEYS,
} from '@/constants/builderConstants';

import HostingerButton from '@/components/global/HostingerButton.vue';
import BuilderHeaderPublishButtonContent from '@/components/builder-view/headers/BuilderHeaderPublishButtonContent.vue';
import { usePublish } from '@/use/usePublish';
import BuilderHeaderPublishPopup from '@/components/builder-view/headers/BuilderHeaderPublishPopup.vue';
import Icon from '@/components/global/Icon.vue';
import { useI18n } from 'vue-i18n';
import { authRedirectService } from '@/services/http/authRedirectService';
import { useSitePublish } from '@/use/useSitePublish';
import { useSavingStore } from '@/stores/savingStore';

const {
	state,
	getters,
	dispatch,
} = useStore();
const { daysLeftUntilExpiration } = usePublish();
const { t } = useI18n();
const {
	handlePublishSite,
	isP2PLockPopupVisible,
} = useSitePublish();
const savingStore = useSavingStore();

// Any because $el is not defined in the type HTMLElement type
const publishButtonRef = ref<any>(null);

const isSiteBeingPublished = computed(() => state.gui.isSiteBeingPublished);
const isCurrentPageTypeBlog = computed(() => getters.isCurrentPageTypeBlog);
const websiteStatus = computed(() => getters.websiteStatus);
const disabled = computed(() => isSiteBeingPublished.value || savingStore.isSaving || websiteStatus.value === 'SUSPENDED');
const user = computed(() => state.user.user);

const p2pLockPopupFeatures = computed(() => [
	t('builder.payToPublishPopup.feature0'),
	t('builder.payToPublishPopup.feature1'),
	t('builder.payToPublishPopup.feature2'),
	t('builder.payToPublishPopup.feature3'),
	t('builder.payToPublishPopup.feature4'),
	t('builder.payToPublishPopup.feature5'),
]);

const updateFonts = () => {
	dispatch('updateFonts');
};

const isP2pSiteAlmostExpired = computed(() => daysLeftUntilExpiration.value <= P2P_DAYS_UNTIL_SITE_DELETED_URGENT);

watch(isSiteBeingPublished, async (value) => {
	if (value) {
		updateFonts();

		if (isCurrentPageTypeBlog.value) {
			window.hj('identify', user.value?.id, {
				'builder.blog.publish_post': true,
			});
		}
	}
});

const redirectToBuyHosting = () => {
	authRedirectService.redirectToHpanel({
		path: `${import.meta.env.VITE_HPANEL_URL}/buy-website-builder`,
		params: {
			[REDIRECT_PARAM_KEYS.SECTION]: 'buy_website_builder',
		},
	});
};
</script>

<template>
	<div class="publish">
		<HostingerButton
			ref="publishButtonRef"
			v-qa="'builder-header-btn-publishsite'"
			:disabled="disabled"
			:data-popper-reference="PUBLISH_BUTTON_SELECTOR"
			class="publish__button"
			@click="handlePublishSite"
		>
			<BuilderHeaderPublishButtonContent />
		</HostingerButton>
		<BuilderHeaderPublishPopup
			v-if="isP2PLockPopupVisible"
			:target-ref="publishButtonRef.$el"
			:width="360"
			@click-outside="isP2PLockPopupVisible = false"
		>
			<template #title>
				{{ $t('builder.payToPublishPopup.title') }}
			</template>
			<template #content>
				<ul class="p2p-lock-popup-features">
					<li
						v-for="item in p2pLockPopupFeatures"
						:key="item"
						class="text-body-2 p2p-lock-popup-features__item"
					>
						<Icon
							name="check"
							class="p2p-lock-popup-features__check-icon"
						/>
						<div>
							<p class="p2p-lock-popup-features__item-name">
								{{ item }}
							</p>
						</div>
					</li>
				</ul>
			</template>

			<template #footer>
				<div class="p2p-lock-popup-features__footer">
					<div class="p2p-lock-popup-features__expiration">
						<Icon
							name="error"
							:color="isP2pSiteAlmostExpired ? 'warning-dark' : 'gray'"
							is-filled
							dimensions="24px"
							class="p2p-lock-popup-features__expiration-icon"
						/>
						<i18n-t
							tag="p"
							keypath="builder.payToPublishPopup.expiration"
							class="p2p-lock-popup-features__expiration-text text-body-2"
						>
							<span class="z-font-weight-bold">
								{{ daysLeftUntilExpiration }} {{ daysLeftUntilExpiration > 1 ? t('common.days') : t('common.day') }}
							</span>
						</i18n-t>
					</div>
					<HostingerButton
						v-qa="'builder-header-popup-btn-p2pbuyhosting'"
						@click="redirectToBuyHosting"
					>
						{{ $t('common.getPlan') }}
					</HostingerButton>
				</div>
			</template>
		</BuilderHeaderPublishPopup>
	</div>
</template>

<style lang="scss" scoped>
.publish {
	&__button {
		flex-shrink: 0;
		padding-right: 24px;
		padding-left: 24px;

		// Reduce size for tablet
		@media screen and (max-width: $media-mobile) {
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	&__popup-edit-mobile {
		margin-bottom: 16px;
	}

	&__popup-btn-publish {
		margin-left: auto
	}
}

.p2p-lock-popup-features {
		display: flex;
		flex-direction: column;
		gap: 8px;
		list-style: none;
		margin: 8px 0;

	&__expiration {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: 16px;
	}

	&__expiration-icon {
		flex-shrink: 0;
	}

	&__item {
		display: flex;
		align-items: center;
	}

	&__check-icon {
		color: $color-success;
		margin-right: 8px
	}
}
</style>
