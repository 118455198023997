<script setup lang="ts">
import {
	computed,
	onMounted,
	ref,
} from 'vue';
import Icon from '@/components/global/Icon.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useProductTemplateOnboarding } from '@/use/useProductTemplateOnboarding';
import {
	EcommerceProduct,
	EcommerceProductSeoSettingsData,
} from '@zyro-inc/site-modules/types';
import { OPEN_MODAL } from '@/store/builder/gui';
import {
	MODAL_PRODUCT_PAGE_TEMPLATE,
	PRODUCT_TEMPLATE_ONBOARDING_STEP_PREVIEW,
} from '@/constants/builderConstants';
import { useStore } from 'vuex';

import PulsingDot from '@/components/global/PulsingDot.vue';
import BuilderProductPageNotification from '@/components/builder-view/BuilderProductPageNotification.vue';
import BuilderProductPageTemplateOnboardingStep from '@/components//BuilderProductPageTemplateOnboardingStep.vue';

import {
	getIsProductTemplateNotificationShown,
	setIsProductTemplateNotificationShownLocalStorage,
} from '@/utils/ecommerce';
import { useGuiStore } from '@/stores/guiStore';
import { useSiteStore } from '@/stores/siteStore';
import EventLogApi from '@/api/EventLogApi';

const { dispatch } = useStore();
const ecommerceStore = useEcommerceStore();
const guiStore = useGuiStore();
const siteStore = useSiteStore();
const {
	isOnboardingVisible,
	currentProductTemplateOnboardingStep,
} = useProductTemplateOnboarding();

const productSelectRef = ref<HTMLElement | null>(null);

const productsSeo = computed(() => ecommerceStore.productsSeo);
const currentOpenProduct = computed(() => productsSeo.value.find(
	(productData) => productData.id === ecommerceStore.currentDynamicPageProductId,
));

const isProductTemplateOnboardingStepPreviewVisible = computed(() => isOnboardingVisible.value
	&& currentProductTemplateOnboardingStep.value === PRODUCT_TEMPLATE_ONBOARDING_STEP_PREVIEW);

const isProductPageTemplateNotificationVisible = computed(() => guiStore.isProductPageTemplateNotificationShown
	&& !isOnboardingVisible.value);

const handleProductSelect = (data: EcommerceProduct | EcommerceProductSeoSettingsData) => {
	ecommerceStore.setCurrentDynamicPageProductId(data.id as string);

	EventLogApi.logEvent({
		eventName: 'website_builder.ecomm_selected_product',
	});
};

const handleNotificationClose = () => {
	guiStore.setIsProductPageTemplateNotificationShown(false);

	setIsProductTemplateNotificationShownLocalStorage(siteStore.websiteId);
};

const handleProductSelectOpen = () => {
	if (guiStore.isProductPageTemplateNotificationShown) {
		handleNotificationClose();
	}
};

const openModal = () => {
	handleNotificationClose();

	dispatch(`gui/${OPEN_MODAL}`, {
		name: MODAL_PRODUCT_PAGE_TEMPLATE,
	});

	EventLogApi.logEvent({
		eventName: 'website_builder.ecomm_product_template.info',
	});
};

onMounted(() => {
	guiStore.setIsProductPageTemplateNotificationShown(
		getIsProductTemplateNotificationShown(ecommerceStore.isDynamicPageFlowEnabled, siteStore.websiteId),
	);
});
</script>

<template>
	<div
		v-qa="'header-product-template'"
		class="header-product-template text-body-2"
	>
		<p class="header-product-template__text-wrapper">
			<Icon
				class="header-product-template__icon"
				dimensions="20px"
				name="info"
			/>
			<i18n-t
				keypath="builder.changesWillApplyToProductPagesLonger"
				tag="span"
			>
				<button
					class="header-product-template__button"
					@click="openModal"
				>
					{{ $t('common.learnMore') }}
				</button>
			</i18n-t>
		</p>
		<div
			ref="productSelectRef"
			class="header-product-template__select-wrapper"
		>
			<label for="product-select">
				{{ $t('builder.previewProduct') }}:
			</label>
			<div class="header-product-template__product-select-wrapper">
				<ZyroSelect
					v-qa="'ecommerce-block-product-select'"
					input-id="product-select"
					class="header-product-template__product-select"
					:options="productsSeo"
					label-key="title"
					:model-value="currentOpenProduct"
					:disabled="!productsSeo.length"
					:placeholder="!productsSeo.length ? $t('builder.noProductsPlaceholder') : ''"
					@update:model-value="handleProductSelect"
					@open="handleProductSelectOpen"
				>
					<template #option="{ option }">
						<div
							class="header-product-template__product-select-option
						header-product-template__product-select-option--list-option"
						>
							<Icon
								dimensions="18px"
								:name="option.is_hidden ? 'visibility_off' : 'visibility'"
							/>
							<span>{{ option.title }}</span>
						</div>
					</template>
					<template #selected-option="{ option }">
						<div
							class="header-product-template__product-select-option
						header-product-template__product-select-option--selected-option"
						>
							{{ option.title }}
						</div>
					</template>
				</ZyroSelect>
				<PulsingDot
					v-if="isProductPageTemplateNotificationVisible"
					top="80%"
					left="45%"
				/>
				<BuilderProductPageTemplateOnboardingStep
					v-if="isProductTemplateOnboardingStepPreviewVisible"
					left="50%"
					top="100%"
					placement="bottom"
					:use-popup-offset="false"
					:offset="{
						mainAxis: 5,
						crossAxis: 0,
					}"
				/>
			</div>
			<BuilderProductPageNotification
				:is-notification-shown="isProductPageTemplateNotificationVisible"
				@close-click="handleNotificationClose"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.header-product-template {
	position: fixed;
	display: flex;
	top: $header-height-editor;
	justify-content: space-between;
	width: calc(100% - $sidebar-width-editor);
	padding: 4px 16px;
	align-items: center;
	background-color: $color-light;
	border-bottom: 1px solid $color-gray-border;
	z-index: $z-index-controls-popup-card;

	@media screen and (max-width: $media-mobile) {
		display: none;
	}

	&__text-wrapper {
		display: flex;
		align-items: start;
		gap: 4px;
	}

	&__icon {
		margin-top: 2px;
		margin-right: 4px;
		color: $color-primary;
	}

	&__button {
		color: $color-primary;
		text-decoration: underline;
		cursor: pointer;
	}

	&__select-wrapper {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	&__product-select-wrapper {
		position: relative;
	}

	&__product-select {
		width: 320px;
		height: 43px;
		border: none;

		:deep() {
			.vs__dropdown-menu {
				max-height: 220px;
			}

			.datalist__no-results, .vs__selected, .vs__search {
				padding: 0 8px 0 16px;
			}

			.vs__selected, .vs__search {
				margin: 4px 0;
				height: 35px;
			}

			.vs__selected {
				position: absolute;
				top: 0;
				bottom: 0;
			}

			.vs__selected-options {
				overflow: hidden;
				height: 43px;
			}
		}

		@media screen and (max-width: $media-tablet) {
			width: 208px;
		}

		@media screen and (max-width: 370px) {
			width: 150px;
			flex-grow: 1;
		}
	}

	:deep(.datalist) {
		background-color: $color-gray-light;
	}

	&__product-select-option {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&--list-option {
			display: flex;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;
			width: 100%;

			:nth-child(1) {
				max-width: 18px;
				width: 100%;
				flex: 1 0 auto;
			}

			:nth-child(2) {
				width: calc(100% - 18px);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		&--selected-option {
			width: 240px;
		}

		@media screen and (max-width: $media-tablet) {
			&--selected-option {
				width: 120px;
			}
		}

		@media screen and (max-width: 370px) {
			&--list-option {
				width: 100px;
			}

			&--selected-option {
				width: 90px;
			}
		}
	}
}
</style>
