<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';

import { useSidebar } from '@/use/useSidebar';
import { useGamification } from '@/use/useGamification';

import {
	ref,
	computed,
	onMounted,
} from 'vue';
import { useStore } from 'vuex';

import {
	DRAWER_MANAGE_STORE_PRODUCTS,
	ECOMMERCE_REDIRECT_PATHS,
	DRAWER_MANAGE_STORE_ITEM_PRODUCT_PAGE_TEMPLATE,
	PRODUCT_TEMPLATE_ONBOARDING_STEP_TEMPLATE,
	NPS_TYPE_FEATURE_PRODUCT_PAGE_TEMPLATE,
	COOKIE_IS_PRODUCT_PAGE_TEMPLATE_NPS_CLOSED,
} from '@/constants/builderConstants';
import PageSettingsPopup from '@/components/builder-modals/modals/PageSettingsPopup.vue';
import ManageEcommerceSettings from '@/components/builder-drawers/drawers/partials/ecommerceStoreDrawer/ManageEcommerceSettings.vue';
import GamificationTaskList from '@/components/onboarding/GamificationTaskList.vue';
import BuilderProductPageTemplateOnboardingStep from '@/components//BuilderProductPageTemplateOnboardingStep.vue';
import { useRedirects } from '@/use/useRedirects';
import EventLogApi from '@/api/EventLogApi';
import { TabsType } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useI18n } from 'vue-i18n';
import { useEcommerceAdminStore } from '@/stores/ecommerceAdminStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useProductTemplateOnboarding } from '@/use/useProductTemplateOnboarding';
import { useNpsStore } from '@/stores/npsStore';
import {
	getCookie,
	setCookie,
} from '@zyro-inc/site-modules/utils/cookies';

interface StoreManagementItem {
	id?: string;
	title: string;
	iconName: string;
	action: () => void;
}

const {
	dispatch,
	state,
} = useStore();
const npsStore = useNpsStore();
const { t } = useI18n();
const { redirectToEcommerce } = useRedirects();
const {
	completedEcommerceTipsCount,
	ecommerceTips,
} = useGamification();
const {
	closeInnerSidebar,
	closeSidebar,
} = useSidebar();
const {
	currentProductTemplateOnboardingStep,
	isOnboardingVisible,
} = useProductTemplateOnboarding();
const ecommerceAdminStore = useEcommerceAdminStore();
const ecommerceStore = useEcommerceStore();

const pageToOpenId = ref<string>();
const tabToOpen = ref<TabsType>();
const isNpsShown = ref(true);

const areEcommerceGamificationTipsShown = computed(() => ecommerceTips.value.length !== completedEcommerceTipsCount.value);
const handleEditProductTemplateClick = () => {
	if (state.gui.isMobileScreen) {
		closeSidebar();
	}

	ecommerceStore.handleEditDynamicProductPageClick();
};

const storeManagementItems = computed<StoreManagementItem[]>(() => [
	...(ecommerceStore.isDynamicPageFlowEnabled
		? [
			{
				id: DRAWER_MANAGE_STORE_ITEM_PRODUCT_PAGE_TEMPLATE,
				title: t('builder.productPageTemplate'),
				iconName: 'space_dashboard',
				action: () => {
					handleEditProductTemplateClick();

					EventLogApi.logEvent({
						eventName: 'website_builder.ecomm_product_template.enter',
						eventProperties: {
							location: 'side_bar_ecom',
						},
					});
				},
			},
		]
		: []
	),
	{
		title: t('common.products'),
		iconName: 'sell',
		action: () => {
			if (ecommerceStore.isDynamicPageFlowEnabled) {
				ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
					isOpen: true,
					path: ECOMMERCE_REDIRECT_PATHS.PRODUCTS,
				});

				return;
			}

			dispatch('gui/TOGGLE_DRAWER', DRAWER_MANAGE_STORE_PRODUCTS);
		},
	},
	{
		title: t('common.categories'),
		iconName: 'grid_view',
		action: () => {
			ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
				isOpen: true,
				path: ECOMMERCE_REDIRECT_PATHS.CATEGORIES,
			});
		},
	},
	{
		title: t('common.orders'),
		iconName: 'download',
		action: () => {
			ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
				isOpen: true,
				path: ECOMMERCE_REDIRECT_PATHS.ORDERS,
			});
		},
	},
	{
		title: t('common.appointments'),
		iconName: 'calendar_month',
		action: () => {
			ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
				isOpen: true,
				path: ECOMMERCE_REDIRECT_PATHS.BOOKINGS,
			});
		},
	},
	{
		title: t('common.discounts'),
		iconName: 'percent',
		action: () => {
			ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
				isOpen: true,
				path: ECOMMERCE_REDIRECT_PATHS.DISCOUNTS,
			});
		},
	},
	{
		title: t('builder.ecommerceShopSettings'),
		iconName: 'settings',
		action: () => {
			ecommerceAdminStore.setIsEcommerceAdminIframeOpen({
				isOpen: true,
				path: ECOMMERCE_REDIRECT_PATHS.STORE_SETTINGS,
			});
		},
	},
]);

const handleManageStoreClick = async () => {
	await EventLogApi.logEvent({
		eventName: 'website_builder.ecomm_store.enter',
	});

	redirectToEcommerce();
};

const getIsProductTemplateOnboardingStepVisible = ({ item }: {item: StoreManagementItem}) => item.id
	&& item.id === DRAWER_MANAGE_STORE_ITEM_PRODUCT_PAGE_TEMPLATE
	&& isOnboardingVisible.value
	&& currentProductTemplateOnboardingStep.value === PRODUCT_TEMPLATE_ONBOARDING_STEP_TEMPLATE;

const openNps = () => {
	npsStore.setNpsData({
		newQuestion: t('builder.productPageTemplateNpsTitle'),
		isVisible: true,
		formType: NPS_TYPE_FEATURE_PRODUCT_PAGE_TEMPLATE,
	});
};

const hideNps = () => {
	const COOKIE_EXPIRATION_DAYS = 20 * 365; // 20 years

	isNpsShown.value = false;
	setCookie(COOKIE_IS_PRODUCT_PAGE_TEMPLATE_NPS_CLOSED, 1, COOKIE_EXPIRATION_DAYS);
};

onMounted(() => {
	const isNpsHidden = Number.parseInt(getCookie(COOKIE_IS_PRODUCT_PAGE_TEMPLATE_NPS_CLOSED), 10) === 1;

	isNpsShown.value = !isNpsHidden;
});
</script>

<template>
	<ZyroDrawer
		class="manage-ecommerce-drawer"
		:header-title="$t('builder.onlineStore')"
		@back="closeInnerSidebar"
	>
		<template #body>
			<GamificationTaskList
				v-if="areEcommerceGamificationTipsShown"
				class="manage-ecommerce-drawer__gamification-list"
				:achievements="ecommerceTips"
				:title="t('builder.gamificationSetUpYourStore')"
				:add-top-margin="false"
				location="editor_ecom_drawer"
				:is-main-list="true"
				show-icon
			/>
			<div class="manage-ecommerce-drawer__list">
				<div
					v-if="areEcommerceGamificationTipsShown"
					class="manage-ecommerce-drawer__info"
				>
					<p class="manage-ecommerce-drawer__title text-bold-2">
						{{ $t('builder.builderHeaderManage') }}
					</p>
				</div>
				<div class="manage-ecommerce-drawer__items">
					<div
						v-for="(item, index) in storeManagementItems"
						:id="item.id"
						:key="index"
						class="manage-ecommerce-drawer__item-wrapper"
					>
						<div
							v-qa="`manage-ecommerce-item-${item.iconName}`"
							class="manage-ecommerce-drawer__item"
							@click="item.action"
						>
							<Icon
								class="manage-ecommerce-drawer__item-icon"
								:name="item.iconName"
								dimensions="20px"
							/>
							<p class="manage-ecommerce-drawer__item-title text-body-2">
								{{ item.title }}
							</p>

							<Icon
								class="manage-ecommerce-drawer__item-arrow"
								name="chevron_right"
								dimensions="24px"
							/>

							<BuilderProductPageTemplateOnboardingStep
								v-if="getIsProductTemplateOnboardingStepVisible({ item })"
								left="80%"
								top="50%"
								placement="right"
								:offset="{
									mainAxis: 90,
									crossAxis: 120,
								}"
							/>
						</div>
						<Transition name="slide-top-to-bottom">
							<div
								v-if="index === 0 && isNpsShown && ecommerceStore.isDynamicPageFlowEnabled"
								class="product-template-nps text-body-2"
							>
								<div>
									<Icon
										name="add_reaction"
										dimensions="20px"
									/>
								</div>
								<i18n-t
									keypath="builder.shareFeedbackAboutProductTemplate"
									tag="p"
								>
									<a
										class="product-template-nps__button"
										@click="openNps"
									>
										{{ $t("builder.shareFeedback") }}
									</a>
								</i18n-t>
								<HostingerButton
									v-qa="'product-template-nps-close'"
									class="product-template-nps__close"
									button-type="plain"
									@click="hideNps"
								>
									<Icon
										name="close"
										dimensions="20px"
									/>
								</HostingerButton>
							</div>
						</Transition>
					</div>
				</div>
			</div>
		</template>

		<template #footer>
			<div class="manage-ecommerce-drawer__footer">
				<HostingerButton
					v-qa="'manage-ecommerce-drawer-btn-manage'"
					@click="handleManageStoreClick"
				>
					{{ $t('builder.editStore.title') }}
				</HostingerButton>
				<ManageEcommerceSettings />
			</div>
			<PageSettingsPopup
				v-if="pageToOpenId"
				:page-id="pageToOpenId"
				:open-tab="tabToOpen"
				@close="pageToOpenId = ''"
			/>
		</template>
	</ZyroDrawer>
</template>

<style lang="scss" scoped>
.manage-ecommerce-drawer {
	&__info {
		margin: 24px 0;
	}

	&__description {
		color: $color-gray;
		font-size: 14px;
		line-height: 1.7;
		margin-top: 4px;
	}

	&__item {
		position: relative;
		display: flex;
		height: 56px;
		align-items: center;
		padding: 12px 8px;
		cursor: pointer;

		&:hover,
		&:focus {
			background-color: $color-gray-light;
			transition: background-color 0.3s ease 0s;
		}

		&:last-child {
			margin-left: auto;
		}
	}

	&__item-wrapper {
		border-bottom: 1px solid $color-gray-border;
	}

	&__item-icon {
		margin-right: 8px;
	}

	&__item-title {
		margin-right: 16px;
	}

	&__item-arrow {
		margin-left: auto;
		color: $color-gray-dark;
	}

	&__gamification-list {
		margin-bottom: 16px;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	:deep() {
		.drawer-body {
			&__content {
				padding-top: 0;
			}

			&__header {
				padding: 0 16px 20px;
			}

			&__header-title {
				margin: 0
			}
		}

		.gamification-tips {
			&__title {
				font-size: 14px;;
			}
		}
	}
}

.product-template-nps {
	margin: 10px 0 16px;
	display: flex;
	gap: 8px;
	align-items: flex-start;
	background-color: $color-gray-light;
	border-radius: 8px;
	padding: 8px;
	position: relative;

	&__button {
		color: $color-primary;
		text-decoration: underline;
		cursor: pointer;
	}

	&__close {
		color: $color-gray;
	}

	&::before {
		content: '';
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		border-bottom: 10px solid $color-gray-light;
		position: absolute;
		top: -10px;
		left: 50%;
		transform: translateX(-50%);
	}
}
</style>
