<template>
	<div class="sessions-over-time-chart">
		<div
			v-if="isEmptyOverlayShown"
			class="empty-overlay"
		>
			<ZyroSvgDeprecated
				name="clock"
				class="empty-overlay__icon"
			/>
			<p class="text-bold-1 sessions-over-time-chart__title">
				{{ $t('builder.analyticsNoDataYetTitle') }}
			</p>
			<p class="text-body-2 empty-overlay__subtitle">
				{{ isDomainConnectShown
					? $t('builder.analyticsVisitorGetDomainMessage') : $t('builder.analyticsVisitorDataUpdatedDailyCheckLater')
				}}
			</p>
			<HostingerButton
				v-if="isDomainConnectShown"
				v-qa="'sessions-over-time-chart__get-domain-button'"
				class="sessions-over-time-chart__get-domain-button"
				theme="primary"
				@click="handleGetDomain"
			>
				{{ $t('builder.getDomainMessage') }}
			</HostingerButton>
		</div>
		<AnalyticsChartCard>
			<template #title>
				<div class="text-bold-1 sessions-over-time-chart__title">
					{{ $t('builder.analyticsSessionsOverTimeTitle') }}
				</div>
			</template>
			<template #subtitle>
				<div class="text-body-2 sessions-over-time-chart__subtitle">
					{{ $t('builder.analyticsSessionsOverTimeSubtitle') }}
				</div>
			</template>
			<VueApexCharts
				v-if="isApexChartsReady"
				type="bar"
				:options="options"
				:series="series"
				height="356"
			/>
		</AnalyticsChartCard>
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
	onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';

import VueApexCharts from 'vue3-apexcharts';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import AnalyticsChartCard from '@/components/site-settings/pages/analytics/AnalyticsChartCard.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useDomainStore } from '@/stores/domainStore';
import { NEXT_BEST_ACTION } from '@hostinger/hdomains-status';
import { useRedirects } from '@/use/useRedirects';
import { useSiteStore } from '@/stores/siteStore';
import EventLogApi from '@/api/EventLogApi';

interface Props {
	totalVisitors: [string, number][];
	isEmptyOverlayShown: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	totalVisitors: () => [],
	isEmptyOverlayShown: true,
});

const { t } = useI18n();

const domainStore = useDomainStore();
const siteStore = useSiteStore();
const { redirectToHPanelAddDomain } = useRedirects();

const isApexChartsReady = ref(false);

const isDomainConnectShown = computed(() => domainStore.domainNextBestAction === NEXT_BEST_ACTION.CONNECT_DOMAIN_TEMPORARY);

const handleGetDomain = () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.connect_domain_manual.enter',
		eventProperties: {
			location: 'analytics',
		},
	});

	redirectToHPanelAddDomain({
		currentDomain: domainStore.customDomain,
		siteId: siteStore.websiteId,
	});
};

onMounted(() => {
	isApexChartsReady.value = true;
});

const options = ref({
	chart: {
		id: 'sessions-over-time',
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	colors: ['rgb(88, 59, 182)'],
	plotOptions: {
		bar: {
			borderRadius: 4,
			columnWidth: '30%',
		},
	},
	dataLabels: {
		enabled: false,
	},
	xaxis: {
		type: 'datetime',
		categories: [],
		labels: {
			showDuplicates: false,
			format: 'dd MMM',
		},
		axisBorder: {
			show: false,
		},
		axisTicks: {
			show: false,
		},
		crosshairs: {
			show: false,
		},
	},
	yaxis: {
		forceNiceScale: true,
		min: 0,
		tickAmount: 5,
	},
	states: {
		active: {
			filter: {
				type: 'none',
			},
		},
		hover: {
			filter: {
				type: 'none',
			},
		},
	},
});

const series = computed(() => ([
	{
		name: t('common.visitors'),
		data: props.totalVisitors,
	},
]));

</script>

<style lang="scss" scoped>
.sessions-over-time-chart {
	position: relative;

	&__title {
		margin-bottom: 8px;
	}

	&__subtitle {
		margin-bottom: 24px;
		color: $color-gray;
	}

	&__get-domain-button {
		margin-top: 12px;
	}
}

.empty-overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border: 1px solid $color-gray-border;
	border-radius: 12px;
    background: rgba(255, 255, 255, 80%);

	&__subtitle {
		margin: 0 30px;
		color: $color-gray;
		text-align: center;
	}

	&__icon {
		width: 48px;
		height: 48px;
		margin-bottom: 24px;
		color: $color-gray;
	}
}

:deep(.apexcharts-bar-area) {
	transition: fill 0.2s ease;
}

:deep(.apexcharts-series) {
	&:hover {
		.apexcharts-bar-area {
			fill: $color-primary-light;

			&:hover {
				fill: $color-primary;
			}
		}
	}
}
</style>
