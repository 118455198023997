import {
	useRoute,
	useRouter,
} from 'vue-router';
import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
	generateSite,
	fetchWebsiteType,
} from '@/api/AiApi';
import { useUserStore } from '@/stores/userStore';
import { useTextToSvg } from '@/use/useTextToSvg';
import EventLogApi from '@/api/EventLogApi';
import { LOGO_SVG_DEFAULT } from '@/data/builderData';
import { addGoogleFontQueryLinks } from '@/utils/injectableDomElements/addGoogleFontQueryLinks';
import { useAiBuilderForm } from '@/components/ai-builder/useAiBuilderForm';
import { useResourcesStore } from '@/stores/resourcesStore';
import { useSiteTextElementPreview } from '@/use/useSiteTextElementPreview';
import { useSiteStore } from '@/stores/siteStore';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useNotifications } from '@/use/useNotifications';
import { useAssets } from '@/use/useAssets';
import { useAiBuilderPreview } from '@/use/useAiBuilderPreview';
import googleFonts from '@zyro-inc/site-modules/data/mostPopularGoogleFonts.json';
import { useRouteQuery } from '@vueuse/router';
import { useAiBuilderStore } from '@/stores/aiBuilderStore';
import {
	AI_PREVIEW_ROUTE,
	TEMPLATES_ROUTE,
} from '@/constants/routes';
import {
	updateTemplateWithLogoSvg,
	getTemplateNavigationColor,
	removeTemplateLogoSvg,
} from '@/utils/aiTemplateEditingHelpers';
import { useUserAuthorizationState } from '@/use/useUserAuthorizationState';
import { useRedirects } from '@/use/useRedirects';
import {
	SYSTEM_LOCALE,
	THEME_LOGO,
	THEME_SUCCESS,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useStore } from 'vuex';
import {
	captureException,
	addBreadcrumb,
} from '@sentry/vue';
import {
	AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH,
	AI_BUILDER_LOGO_SIZE,
	AI_BUILDER_DEFAULT_LOGO_WIDTH,
	AI_PREVIEW_PALETTE_TRACKING_IDS,
	AI_BUILDER_DEFAULT_LOGO_HEIGHT,
	AI_BUILDER_FAILED_GENERATION_BEFORE_REDIRECT_COUNT,
	LOCAL_STORAGE_KEY_AI_PREVIEW_ENABLED,
	TYPE_BLOCK_BACKGROUND,
	HPANEL_REDIRECTS_PATHS,
	AI_BUILDER_CATEGORY_ID_STORE,
	FLOW_QUERY_CART,
} from '@/constants/builderConstants';
import { SiteData } from '@hostinger/builder-schema-validator';
import { AxiosError } from 'axios';
import { useSavingStore } from '@/stores/savingStore';
import {
	AiBuilderQueryParams,
	WebsiteType,
} from '@/types/aiBuilderTypes';

const FONT_FALLBACK = "'Roboto', sans-serif";

export const triggerWebsiteSavedNotification = (dispatch: any) => {
	const { redirectToHPanel } = useRedirects();

	dispatch('notifications/notify', {
		theme: THEME_SUCCESS,
		headingI18nKeyPath: 'builder.aiBuilderWebsiteSaved',
		messageI18nKeyPath: 'builder.aiBuilderAccessWebsiteList',
		submitLabelI18nKeyPath: 'builder.goToWebsiteList',
		isDiscardButtonShown: false,
		submitButtonStyles: {
			buttonType: 'text',
			theme: 'primary',
		},
		submitCallback: () => {
			redirectToHPanel({
				path: 'websites',
			});
		},
		isAutoCloseDisabled: false,
	});
};

export const useAiBuilder = () => {
	const {
		state,
		dispatch,
		getters,
	} = useStore();
	const aiBuilderStore = useAiBuilderStore();
	const ecommerceStore = useEcommerceStore();
	const userStore = useUserStore();
	const siteStore = useSiteStore();
	const resourcesStore = useResourcesStore();
	const savingStore = useSavingStore();

	const route = useRoute();
	const router = useRouter();
	const { t } = useI18n();
	const { redirectToHPanel } = useRedirects();
	const domainQuery = useRouteQuery('domain');

	const { AI_BUILDER_CATEGORIES } = useAiBuilderForm();
	const { saveGeneratedSite } = useAiBuilderPreview();
	const { getSvg } = useTextToSvg();
	const { notify } = useNotifications();

	const {
		isUserWithActivePlan,
		isUserPayToPublish,
		isOnlyOneStarterPlanUser,
		getHostingReferenceIdForSiteCreation,
	} = useUserAuthorizationState();
	const {
		uploadSvgAsset,
		assets,
		assetsToDelete,
		deleteMediaAsset,
	} = useAssets();
	const { recalculateWebsiteTextHeights } = useSiteTextElementPreview();

	const siteId = ref(route.query.siteId);
	const isChangingTemplate = ref(route.query.isChangingTemplate);
	const failedSiteGenerationsBeforeRedirect = ref(0);

	const oldLogoAssetData = computed(() => (assets.value as any).find((asset: { url?: string }) => asset.url?.includes?.('logo')));
	const sitePrimaryFont = computed(() => getters.siteFonts?.primary || FONT_FALLBACK);

	const getShouldGenerateLogo = (brandName: string) => brandName.trim().length < AI_BUILDER_MAXIMUM_LOGO_CHARACTER_LENGTH;

	const removeOldLogoAsset = async () => {
		if (!oldLogoAssetData.value) {
			return;
		}

		assetsToDelete.value = [
			{
				...oldLogoAssetData.value,
			},
		] as any;

		await deleteMediaAsset();

		assetsToDelete.value = [];
	};

	const uploadTemplateLogo = async ({ websiteData }: {
		websiteData: SiteData,
	}) => {
		const { logoSvg } = websiteData.languages[SYSTEM_LOCALE].blocks.header.settings;

		if (!logoSvg) {
			return;
		}

		const logoFile = new File([logoSvg], 'ai-logo.svg', {
			type: 'image/svg+xml',
		});

		try {
			const uploadedSvgAsset = await uploadSvgAsset({
				file: logoFile,
			});

			await dispatch('updateBlockData', {
				blockId: 'header',
				blockData: {
					settings: {
						logoImagePath: uploadedSvgAsset.path,
						logoImageOrigin: 'assets',
					},
				},
				merge: true,
			});
		} catch (error) {
			captureException(error);
			notify({
				message: t('builder.templateLogoUploadFailed'),
			});
		}

		const websiteDataWithoutSvgLogo = removeTemplateLogoSvg({
			templateData: siteStore.site,
		});

		await dispatch('overwriteWebsiteData', {
			websiteData: websiteDataWithoutSvgLogo,
			websiteId: siteId.value,
		});
	};

	const getGeneratedSite = async ({
		brandName,
		brandDescription,
		fonts,
	}: {
		brandName: string,
		brandDescription: string,
		fonts: string[],
	}) => {
		aiBuilderStore.setIsSiteBeingGenerated(true);
		aiBuilderStore.setHasGenerationFailed(false);

		try {
			const {
				txtRecord,
				hostingReferenceId,
				flow,
			} = route.query as AiBuilderQueryParams;

			const isDirectCartRedirect = flow === FLOW_QUERY_CART;
			const referenceId = isDirectCartRedirect
				? hostingReferenceId
				: getHostingReferenceIdForSiteCreation(hostingReferenceId);

			// for data prefilling (domain) flow, website type is not yet fetched here
			if (!aiBuilderStore.isWebsiteTypeFetched) {
				const { data } = await fetchWebsiteType({
					description: brandDescription,
					brandName,
				});

				const capitalizedWebsiteType = data.websiteType.charAt(0).toUpperCase() + data.websiteType.slice(1) as WebsiteType;

				aiBuilderStore.setWebsiteType(capitalizedWebsiteType);
				aiBuilderStore.setIsWebsiteTypeFetched(true);
			}

			aiBuilderStore.setLastAiGenerationData({
				brandName,
				websiteDescription: brandDescription,
				websiteType: (AI_BUILDER_CATEGORIES[aiBuilderStore.websiteType].amplitudeName),
				...(aiBuilderStore.selectedColors.color1 && {
					selectedColors: aiBuilderStore.selectedColors,
				}),
			});

			if (referenceId && !siteId.value) {
				aiBuilderStore.setSelectedHostingReferenceId(referenceId);
			}

			const { data } = await generateSite({
				brandName,
				brandDescription,
				fonts,
				domain: domainQuery.value as string,
				txtRecord: txtRecord as string,
				...(siteId.value && {
					siteId: siteId.value as string,
				}),
				...(aiBuilderStore.selectedColors.color1 && {
					colors: aiBuilderStore.selectedColors,
				}),
				...(aiBuilderStore.websiteType && {
					websiteType: AI_BUILDER_CATEGORIES[aiBuilderStore.websiteType].amplitudeName,
				}),
				...(!siteId.value && referenceId && {
					hostingReferenceId: referenceId,
				}),
			});

			if (data.siteId) {
				siteId.value = data.siteId;

				await dispatch('assets/fetchAssets', data.siteId);
			}

			return data;
		} catch (error) {
			captureException(error, {
				tags: {
					errorType: 'AI Builder generation error',
				},
			});

			if (failedSiteGenerationsBeforeRedirect.value >= AI_BUILDER_FAILED_GENERATION_BEFORE_REDIRECT_COUNT) {
				notify({
					messageI18nKeyPath: 'builder.aiFailedGenerationRedirectToastMessage',
					headingI18nKeyPath: 'builder.aiFailedGenerationRedirectToastHeading',
					submitLabelI18nKeyPath: 'builder.aiFailedGenerationRedirectCTA',
					theme: THEME_LOGO,
					isDiscardButtonShown: false,
					submitCallback: () => {
						router.push({
							name: TEMPLATES_ROUTE,
							query: {
								...route.query,
								...(siteId.value ? {
									siteId: siteId.value,
								} : {}),
							},
						});
					},
				});
			} else if (error instanceof AxiosError) {
				if (error.response?.status === 422) {
					notify({
						message: t('builder.aiBuilderInvalidWebsiteDescription'),
					});
				} else if (error.response?.data?.message === 'Website limit reached' && error.response?.data?.error === 400) {
					notify({
						message: t('builder.aiBuilderWebsiteLimitReached'),
						submitLabelI18nKeyPath: 'siteSettings.myWebsites',
						submitCallback: () => redirectToHPanel({
							path: HPANEL_REDIRECTS_PATHS.WEBSITES,
						}),
						isDiscardButtonShown: false,
					});
				}
			} else {
				notify({
					message: t('builder.aiBuilderGenerationFailed'),
				});
			}

			aiBuilderStore.setIsSiteBeingGenerated(false);
			aiBuilderStore.setHasGenerationFailed(true);

			if (isUserWithActivePlan.value) {
				failedSiteGenerationsBeforeRedirect.value += 1;
			}

			return null;
		} finally {
			// Remove domain query param from URL
			// Prevents error that occurs when user presses back button after going to builder
			// and then tries to generate another site under same domain
			const currentUrl = new URL(window.location.href);

			domainQuery.value = null;

			currentUrl.searchParams.delete('domain');
			window.history.replaceState({}, '', currentUrl);
		}
	};

	const createWebsite = async ({
		clientTimestamp,
		hasOnlineStore,
	}: {
		hasOnlineStore?: boolean,
		clientTimestamp?: number,
	} = {}) => {
		const clientTimestampPromise = !clientTimestamp ? Promise.resolve() : savingStore.setClientTimestamp(clientTimestamp);

		const parallelPromises = [
			removeOldLogoAsset(),
			clientTimestampPromise,
			uploadTemplateLogo({
				websiteData: siteStore.site as SiteData,
			}),
		];

		await Promise.all(parallelPromises);

		if (hasOnlineStore) {
			await ecommerceStore.fetchInitialEcommerceData({
				shouldAwaitPageCreation: true,
			});
		}

		await savingStore.saveWebsite({
			saveWhenImpersonating: !isChangingTemplate.value,
			isTimerStarted: false,
		});
	};

	const continueToBuilder = async ({
		hasOnlineStore,
		clientTimestamp,
	}: {
		hasOnlineStore?: boolean,
		clientTimestamp?: number,
	} = {}) => {
		// isGoingToBuilder triggers isLoading state. Loading state controls form visibility.
		// Setting to true to prevent AI Builder's form from showing right after site generation
		aiBuilderStore.setIsGoingToBuilder(true);

		await createWebsite({
			hasOnlineStore,
			clientTimestamp,
		});

		dispatch('updateCurrentPageId');

		if (!state.user?.user?.id) {
			await dispatch('user/getUser', {
				force: true,
			});
			await resourcesStore.fetchResources();
			await dispatch('initHResourcesData');
		}

		localStorage.setItem(LOCAL_STORAGE_KEY_AI_PREVIEW_ENABLED, String(true));

		router.push({
			name: AI_PREVIEW_ROUTE,
			params: {
				siteId: (route.query.siteId || siteId.value) as string,
				...(route.query.location ? {
					location: route.query.location as string,
				} : {}),
			},
		});
	};

	const getSiteWithUpdatedLogo = ({
		logoSvg,
		siteData,
	}: {
		logoSvg: Awaited<ReturnType<typeof getSvg>>,
		siteData: SiteData,
	}) => {
		if (!logoSvg?.svg) {
			return siteData;
		}

		const siteDataWithUpdatedLogo = updateTemplateWithLogoSvg({
			logoSvg,
			color: getTemplateNavigationColor({
				template: siteData,
			}),
			templateData: siteData,
		});

		return siteDataWithUpdatedLogo;
	};

	const generateTemplates = async ({
		brandName,
		brandDescription,
	}: {
		brandName: string,
		brandDescription: string,
	}) => {
		const fonts = googleFonts.items.map((font) => font.family);

		const generatedSiteData = await getGeneratedSite({
			brandName,
			brandDescription,
			fonts,
		});

		addBreadcrumb({
			category: 'SITE_GENERATE',
			message: 'right after AI site generation',
			data: {
				googleFonts: fonts,
				brandName,
				brandDescription,
				generatedFonts: generatedSiteData?.siteData?.fonts,
				generatedStylesFont: generatedSiteData?.siteData?.styles?.font,
			},
		});

		if (!generatedSiteData) {
			aiBuilderStore.setIsSiteBeingGenerated(false);

			return;
		}

		const logoSvg = getShouldGenerateLogo(brandName)
			? await getSvg({
				text: brandName,
				fontFamily: (generatedSiteData.siteData.fonts || [])?.[0].family || sitePrimaryFont.value,
				fontSize: AI_BUILDER_LOGO_SIZE,
			})
			: {
				svg: LOGO_SVG_DEFAULT,
				height: AI_BUILDER_DEFAULT_LOGO_HEIGHT,
				width: AI_BUILDER_DEFAULT_LOGO_WIDTH,
			};

		const siteWithUpdatedLogo = getSiteWithUpdatedLogo({
			logoSvg,
			siteData: generatedSiteData.siteData,
		});

		dispatch('overwriteWebsiteData', {
			websiteData: siteWithUpdatedLogo,
			websiteId: siteId.value,
		});

		const fontFamiliesToAwait = (generatedSiteData.siteData.fonts || []).map((font) => font.family);

		if (getters['fonts/getMetaFont']) {
			addGoogleFontQueryLinks(getters['fonts/getMetaFont']);
		}

		await recalculateWebsiteTextHeights({
			fontFamiliesToAwait,
		});

		await dispatch('gui/updatePreviewSiteData', {
			...siteStore.site,
			siteId: siteStore.websiteId,
		});

		if (isUserPayToPublish.value) {
			await EventLogApi.setUserProperty('is_builder_p2p', 'true');
			await EventLogApi.setUserProperty('is_builder_paid', 'false');
		}

		const isGradientUsed = Object.values(generatedSiteData.siteData.languages[SYSTEM_LOCALE].blocks)
			.some((block) => block.background?.current === TYPE_BLOCK_BACKGROUND.Gradient);

		const generatedSiteTypeId = (Object.keys(AI_BUILDER_CATEGORIES) as WebsiteType[])
			.find((categoryId) => AI_BUILDER_CATEGORIES[categoryId].amplitudeName === generatedSiteData.websiteType);

		if (generatedSiteTypeId) {
			aiBuilderStore.setWebsiteType(generatedSiteTypeId);
		}

		await EventLogApi.logEvent({
			eventName: 'website_builder.ai_builder.created',
			eventProperties: {
				...(route.query.ref && {
					location: route.query.ref,
				}),
				...(generatedSiteData.usage && {
					color_mentioned: generatedSiteData.usage.isColorMentioned,
					font_mentioned: generatedSiteData.usage.isFontMentioned,
					is_non_online_store_with_product_list: generatedSiteData.usage.isNonOnlineStoreWithProductList,
					has_video_background: generatedSiteData.usage.hasVideoBackground,
				}),
				builder_type: 'v2',
				theme: generatedSiteData.theme,
				gradient_used: isGradientUsed,
				website_id: siteId.value,
				business_type: AI_BUILDER_CATEGORIES[aiBuilderStore.websiteType].amplitudeName,
				is_p2p_user: isUserPayToPublish.value,
				is_features_lock_enabled: userStore.areFeaturesLocked,
			},
		});

		await router.replace({
			query: {
				hostingReferenceId: aiBuilderStore.selectedHostingReferenceId,
				// if user is p2p or starter plan user - add siteId so that when they go back they overwrite the site
				...((isUserPayToPublish.value || isOnlyOneStarterPlanUser.value) && {
					siteId: siteId.value,
					isChangingTemplate: String(true),
				}),
			},
		});

		saveGeneratedSite({
			language: generatedSiteData.descriptionLanguage,
			siteData: siteStore.site as SiteData,
			ecommerceProducts: generatedSiteData.ecommerceProducts,
			colorPalettePicked: {
				...generatedSiteData.colorPalette,
				paletteId: `${AI_PREVIEW_PALETTE_TRACKING_IDS.PALETTE_AI}-1`,
			},
			colorPaletteGenerated: {
				...generatedSiteData.colorPalette,
				paletteId: `${AI_PREVIEW_PALETTE_TRACKING_IDS.PALETTE_AI}-1`,
			},
		});

		await continueToBuilder({
			hasOnlineStore: generatedSiteTypeId === AI_BUILDER_CATEGORIES[AI_BUILDER_CATEGORY_ID_STORE].amplitudeName,
			clientTimestamp: generatedSiteData.clientTimestamp,
		});

		aiBuilderStore.setIsSiteBeingGenerated(false);
	};

	return {
		generateTemplates,
		getGeneratedSite,
		continueToBuilder,
	};
};
