import type { AxiosError } from 'axios';
import {
	setTag,
	init,
	replayIntegration,
	browserTracingIntegration,
	captureException,
	addBreadcrumb,
	dedupeIntegration,
} from '@sentry/vue';
import type { Event as SentryEvent } from '@sentry/vue';
import type { App } from 'vue';
import {
	COOKIE_GOOGLE_ANALYTICS,
	COOKIE_E2E_TEST_ID,
	COOKIE_HOSTINGER_DEVICE_ID,
	HEADER_X_CORRELATION_ID,
	IGNORED_AXIOS_CODES,
	IGNORED_SENTRY_ERRORS,
} from '@/constants/builderConstants';
import {
	getAuthToken,
	getParsedToken,
} from '@/utils/auth';
import { getCookie } from '@zyro-inc/site-modules/utils/cookies';
import {
	getNormalisedRequestConfigProperties,
	getNormalisedErrorResponseProperties,
} from '@/services/http/getNormalisedAxiosProperties';

const COOKIES_TO_SENTRY_TAGS = [
	COOKIE_GOOGLE_ANALYTICS,
	COOKIE_E2E_TEST_ID,
	COOKIE_HOSTINGER_DEVICE_ID,
];

let shouldIgnoreSentryErrors = false;

const mapExceptionValuesFromSentryEvent = (event: SentryEvent) => event?.exception?.values?.map(({ value }) => value || '') || [];

export const getIsIgnoredErrorValue = (event: SentryEvent) => {
	const errorValues = mapExceptionValuesFromSentryEvent(event);

	return errorValues.some((value) => IGNORED_SENTRY_ERRORS.includes(value));
};

const setSentryTagsFromCookies = () => {
	try {
		COOKIES_TO_SENTRY_TAGS.forEach((cookieKey) => {
			const cookieValue = getCookie(cookieKey);

			if (cookieValue) {
				setTag(cookieKey, cookieValue);
			}
		});
	} catch (error) {
		console.error(error);
	}
};

export const setSentryTagIsFirst24HourUser = () => {
	try {
		const createdAtDate = getParsedToken().profile_details?.created_at;

		if (!createdAtDate) {
			return;
		}

		const createdAtUnixTimestamp = new Date(createdAtDate || '').getTime();
		const nowUnixTimestamp = Date.now();
		const hoursSinceCreation = (nowUnixTimestamp - createdAtUnixTimestamp) / 1000 / 60 / 60;
		const isFirst24HourUser = hoursSinceCreation < 24;

		setTag('isFirst24HourUser', isFirst24HourUser);
	} catch (error) {
		console.error(error);
	}
};

const ERROR_IGNORE_PARTIALS = [
	'Failed to fetch dynamically imported module',
	'Unable to preload CSS',
];

export const initErrorLogger = (app?: App<Element>) => {
	if (!import.meta.env.VITE_SENTRY_DSN) {
		return;
	}

	init({
		app,

		release: import.meta.env.VITE_BUILD_NUMBER || 'builder@dev',
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_SENTRY_ENV || 'local',
		integrations: [
			dedupeIntegration(),
			replayIntegration(),
			browserTracingIntegration(),
		],
		tracesSampleRate: 0.05,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 0.024,
		// https://stackoverflow.com/questions/63020810/what-is-best-way-in-javascript-to-stop-throwing-resizeobserver-loop-limit-excee
		ignoreErrors: [
			'Navigation cancelled from', // Navigation cancelled errors are mostly expected, when handling various final-urls in router beforeEnter hook or spamming router.push method (clicking button fast)
			'ResizeObserver loop limit exceeded',
			'ResizeObserver loop completed with undelivered notifications',
			'Network Error', // we ignore this because most of errors are because client's IP is blacklisted
		],
		beforeSend(event, hint) {
			if (getIsIgnoredErrorValue(event)) {
				return null;
			}

			// @ts-ignore
			if (hint.originalException?.name === 'AxiosError') {
				return null; // axios errors are reported through logAxiosError
			}

			// @ts-ignore
			const ignoreBasedOnPartial = ERROR_IGNORE_PARTIALS.some((pattern) => hint.originalException?.message?.includes(pattern));

			if (ignoreBasedOnPartial) {
				return null; // do not send error to sentry when user browser failed to fetch dynamically imported module
			}

			if (shouldIgnoreSentryErrors) {
				return null;
			}

			return event;
		},
	});
	setSentryTagsFromCookies();
	setSentryTagIsFirst24HourUser();
};

export const logError = (error: Error | string) => {
	if (typeof error === 'string') {
		captureException(new Error(error));

		return;
	}

	captureException(error);
};

export const addAxiosErrorBreadcrumb = (axiosError: AxiosError) => {
	try {
		const {
			response,
			config,
		} = axiosError;
		const {
			code,
			message,
		} = response?.data as any || {};

		const correlationId = config?.headers?.[HEADER_X_CORRELATION_ID];

		addBreadcrumb({
			message: JSON.stringify({
				code,
				message,
				correlationId,
				errorMessage: axiosError.message,
			}),
			category: 'xhr-error',
			level: 'info',
		});
	} catch (error) {
		captureException(new Error('addAxiosErrorBreadcrumb error', {
			cause: error,
		}));
	}
};

const clearRequestTags = () => {
	setTag('serviceName', '');
	setTag('backendPathname', '');
	setTag('errorResponseMessage', '');
	setTag('axiosErrorMessage', '');
	setTag('axiosErrorCode', '');
	setTag('responseStatus', '');
	setTag('responseCode', '');
	setTag(
		HEADER_X_CORRELATION_ID,
		'',
	);
};

export const ignoreSentryErrorsForTimeout = (timeoutInMs: number) => {
	shouldIgnoreSentryErrors = true;
	setTimeout(() => {
		shouldIgnoreSentryErrors = false;
	}, timeoutInMs);
};

export const logAxiosError = (axiosError: AxiosError) => {
	try {
		const {
			response,
			config,
		} = axiosError;
		const {
			code,
			message,
			status,
		} = getNormalisedErrorResponseProperties(response);

		const authToken = getAuthToken();
		const isErrorIgnoredGlobally = (!authToken && status === 401) // no jwt token
		|| message === 'jwt malformed' // token exists but does not have correct format
		|| IGNORED_AXIOS_CODES.includes(axiosError.code || ''); // network error / request aborted

		if (isErrorIgnoredGlobally) {
			ignoreSentryErrorsForTimeout(1000);

			return;
		}

		const isStatusWhitelisted = status && config?.whitelistedStatusCodes?.includes(status);

		if (isStatusWhitelisted) {
			return;
		}

		const isCodeWhitelisted = code && config?.whitelistedErrorCodes?.includes(code);

		if (isCodeWhitelisted) {
			return;
		}

		const isWhitelistedBasedOnFunction = config?.getIsResponseWhitelisted?.(axiosError);

		if (isWhitelistedBasedOnFunction) {
			return;
		}

		if (!config?.url) {
			logError('No request url provided');

			return;
		}

		const {
			serviceName,
			backendPathname,
		} = getNormalisedRequestConfigProperties(config);

		setTag('serviceName', serviceName);
		setTag('backendPathname', backendPathname);
		setTag('errorResponseMessage', message);
		setTag('axiosErrorMessage', axiosError.message);
		setTag('axiosErrorCode', axiosError.code);
		setTag('responseStatus', status);
		setTag('responseCode', code);
		setTag(
			HEADER_X_CORRELATION_ID,
			config?.headers?.[HEADER_X_CORRELATION_ID],
		);

		logError(`${serviceName} ${backendPathname} request error`);
	} catch (error) {
		logError(new Error('logAxiosError error', {
			cause: error,
		}));
	}

	ignoreSentryErrorsForTimeout(1000);
	clearRequestTags();
};
