<script setup lang="ts">
import {
	computed,
	ref,
	onBeforeMount,
} from 'vue';
import { useStore } from 'vuex';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroModal from '@/components/global/ZyroModal.vue';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { MODAL_WEBSITE_EDIT } from '@/constants/builderConstants';
import ZyroInput from '@/components/global/ZyroInput.vue';
import ZyroLabel from '@/components/global/ZyroLabel.vue';
import ZyroSelect from '@/components/global/ZyroSelect.vue';
import { HTML_LANG_VALUES } from '@/data/builderData';
import { ILanguage } from '@/types/seoTypes';
import { useSiteStore } from '@/stores/siteStore';

const HTML_LANG_SELECT_ID = 'html-lang-select';
const HTML_LANG_SELECT_INPUT_ID = 'edit-modal-input-lang';
const HTML_TITLE_INPUT_ID = 'edit-modal-input-title';

const siteStore = useSiteStore();
const { state } = useStore();
const isMobileScreen = computed(() => state.gui.isMobileScreen);

const {
	getters,
	dispatch,
} = useStore();

const metaTitle = ref(getters.currentLanguageData.metaTitle ?? getters.siteMeta.metaTitle);

const metaHtmlLanguage = ref<ILanguage | Record<string, never>>({
	title: '',
	value: '',
});

const handleCloseClick = () => {
	dispatch(`gui/${CLOSE_MODAL}`, {
		name: MODAL_WEBSITE_EDIT,
	});
};

const handleSave = () => {
	if (getters.isCurrentSystemLocale) {
		siteStore.setSiteMetaData({
			key: 'metaTitle',
			value: metaTitle.value,
		});
	}

	dispatch('setLocaleMeta', {
		metaTitle: metaTitle.value,
	});

	siteStore.setMetaHtmlLanguage({
		value: metaHtmlLanguage.value.value,
	});

	handleCloseClick();
};

onBeforeMount(() => {
	const currentHtmlLanguage = HTML_LANG_VALUES.find(({ value }) => value === getters.currentLanguageData.metaHtmlLanguage);

	if (currentHtmlLanguage) {
		metaHtmlLanguage.value = currentHtmlLanguage;
	}
});

</script>

<template>
	<ZyroModal
		class="website-edit-modal"
		max-width="600px"
		height="auto"
		no-footer-border
		is-overflow-visible
		@close-modal="handleCloseClick"
	>
		<template #title>
			<span
				class="website-edit-modal__heading"
				:class="isMobileScreen ? 'text-heading-2' : 'text-heading-1'"
			>
				{{ $t('builder.websiteEditModalTitle') }}
			</span>
		</template>
		<div class="website-edit-modal__content">
			<div class="website-edit-modal__content-input-group">
				<ZyroLabel :for="HTML_TITLE_INPUT_ID">
					{{ $t('builder.websiteEditModalBrandInputLabel') }}
				</ZyroLabel>
				<ZyroInput
					v-model="metaTitle"
					:input-id="HTML_TITLE_INPUT_ID"
					qa-selector="input-title"
					class="input"
					type="text"
				/>
				<p class="website-edit-modal__content-example">
					{{ $t('builder.websiteEditModalBrandInputLabelExamples') }}
				</p>
			</div>
			<div class="website-edit-modal__content-input-group">
				<ZyroLabel :for="HTML_LANG_SELECT_INPUT_ID">
					{{ $t('builder.websiteEditModalLangInputLabel') }}
				</ZyroLabel>
				<ZyroSelect
					:id="HTML_LANG_SELECT_ID"
					v-qa="'select-lang'"
					:input-id="HTML_LANG_SELECT_INPUT_ID"
					data-qa="website-edit-modal-select-lang"
					label-key="title"
					:options="HTML_LANG_VALUES"
					:model-value="metaHtmlLanguage"
					@update:model-value="metaHtmlLanguage = $event"
				/>
			</div>
		</div>

		<template #footer>
			<div class="website-edit-modal__footer">
				<HostingerButton
					v-qa="'website-edit-modal__footer-btn-cancel'"
					button-type="text"
					@click="handleCloseClick"
				>
					{{ $t('common.cancel') }}
				</HostingerButton>
				<HostingerButton
					data-qa="btn-save"
					@click="handleSave"
				>
					{{ $t('common.save') }}
				</HostingerButton>
			</div>
		</template>
	</ZyroModal>
</template>

<style scoped lang="scss">
.website-edit-modal {
	padding: 16px;
    overflow: visible;

	&__heading {
		margin: 0;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&-input-group {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		&-example {
			font-size: 12px;
			line-height: 20px;
		}
	}

	&__footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		gap: 16px;
	}
}
</style>
