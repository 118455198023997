<template>
	<div
		ref="blockControlsRef"
		class="block-controls"
		:class="{
			'block-controls--hovered': isBlockHovered || isProductTemplateOnboardingStepAddSectionVisible,
			'block-controls--is-selected': isBlockSelected,
			'block-controls--is-last': isLastBlock,
		}"
	>
		<AddBlockButton
			v-if="isAddBlockButtonVisible"
			v-qa="`block-controls-add-block`"
			:is-product-template-onboarding-step-add-section-visible="isProductTemplateOnboardingStepAddSectionVisible"
			class="block-controls__add-block"
			:block-id="blockId"
		/>

		<div
			v-qa="`block-selection-overlay`"
			class="block-controls__selection"
		>
			<BlockGridElementInfoOverlay
				use-border
				:disable-pill-overflow="isFirstBlock"
				:is-pill-disabled="isPillDisabled"
				:info="blockSelectionInfo"
				:block-id="blockId"
				:is-block-selected="isBlockSelected"
				:is-block-hovered="isBlockHovered"
			/>
		</div>

		<div class="block-controls__resize-handle-container">
			<ZyroTooltip
				position="top"
				toggle-event="hover"
				:hide-tooltip="isMobileMode"
				:use-portal="false"
				content-position="absolute"
				:forced-position="{
					right: '-35px',
					bottom: '30px',
					'white-space': 'nowrap'
				}"
			>
				<template #trigger>
					<ResizeBlockLayoutHandle
						v-if="isResizeHandleVisible"
						class="block-controls__resize-handle"
						:block-id="blockId"
						:block-height="getBlockDOMHeight()"
						@lock-hovered-block="handleHoveredBlockLock"
						@set-edit-control-visibility="$emit('set-edit-control-visibility', $event)"
					/>
				</template>
				<p class="block-controls__resize-handle-text text-body-2">
					{{ $t("builder.sectionControlLine.text") }}
				</p>
			</ZyroTooltip>
		</div>
	</div>
</template>

<script setup>
import { useStore } from 'vuex';
import {
	ref,
	computed,
} from 'vue';
import { useI18n } from 'vue-i18n';

import { BLOCK_TYPE_ECOMMERCE_PRODUCT } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import BlockGridElementInfoOverlay from '@/components/block-grid/BlockGridElementInfoOverlay.vue';
import AddBlockButton from '@/components/builder-controls/control-line/AddBlockButton.vue';
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';
import ResizeBlockLayoutHandle from '@/components/builder-controls/control-line/ResizeBlockLayoutHandle.vue';
import { useHoveredBlock } from '@/use/useHoveredBlock';
import { useProductTemplateOnboarding } from '@/use/useProductTemplateOnboarding';
import { useSiteStore } from '@/stores/siteStore';
import { PRODUCT_TEMPLATE_ONBOARDING_STEP_ADD_SECTION } from '@/constants/builderConstants';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	targetRef: {
		type: HTMLElement,
		default: null,
	},
	isFirstBlock: {
		type: Boolean,
		default: false,
	},
	isLastBlock: {
		type: Boolean,
		default: false,
	},
	isResizeHandleVisible: {
		type: Boolean,
		default: false,
	},
	isAddBlockButtonVisible: {
		type: Boolean,
		default: true,
	},
	sectionTitle: {
		type: String,
		default: '',
	},
	blockSelectInfoColor: {
		type: String,
		default: 'var(--color-azure-dark)',
	},
	transparentHeaderHeight: {
		type: Number,
		default: 0,
	},
	isPillDisabled: {
		type: Boolean,
		default: false,
	},
});

defineEmits([
	'unhide-block',
	'set-edit-control-visibility',
]);

const {
	getters,
	state,
} = useStore();
const siteStore = useSiteStore();
const { t } = useI18n();

const {
	isHoveredBlockLocked,
	hoveredBlock,
} = useHoveredBlock();

const {
	isOnboardingVisible,
	currentProductTemplateOnboardingStep,
} = useProductTemplateOnboarding();

const blockControlsRef = ref(null);

const isMobileMode = computed(() => getters['gui/isMobileMode']);
const isMobileScreen = computed(() => state.gui.isMobileScreen);

const currentBlockId = computed(() => state.currentBlockId);
const block = computed(() => siteStore.siteBlocks[props.blockId]);
const isCurrentBlockTypeEcommerce = computed(() => block.value && block.value.type === BLOCK_TYPE_ECOMMERCE_PRODUCT);
const isProductTemplateOnboardingStepAddSectionVisible = computed(() => isCurrentBlockTypeEcommerce.value
	&& isOnboardingVisible.value
	&& currentProductTemplateOnboardingStep.value === PRODUCT_TEMPLATE_ONBOARDING_STEP_ADD_SECTION);

const isBlockSelected = computed(() => currentBlockId.value === props.blockId);

const isBlockHovered = computed(() => hoveredBlock.value?.id === props.blockId);

const isAddBlockButtonVisible = computed(() => {
	if (getters.isCurrentPageTypeLegacyEcommerceProduct) {
		return false;
	}

	return isMobileScreen.value || props.isAddBlockButtonVisible;
});

const isResizeHandleVisible = computed(() => (props.isResizeHandleVisible && isBlockHovered.value));

const transparentHeaderHeightCSSVar = computed(() => `${props.transparentHeaderHeight}px`);

const blockSelectionInfo = computed(() => ({
	y1: 0,
	y2: 0,
	x1: 0,
	x2: 0,
	title: props.sectionTitle || t('common.section'),
	color: props.blockSelectInfoColor,
}));

const getBlockDOMHeight = () => Number.parseInt(props.targetRef?.clientHeight, 10) || 0;

const handleHoveredBlockLock = (event) => {
	isHoveredBlockLocked.value = event;
};
</script>

<style lang="scss" scoped>

.block-controls {
	$this: &;

	display: none;
	cursor: pointer;

	&--hovered,
	&--is-selected,
	&:hover {
		display: grid;

		#{$this}__selection {
			display: grid;
		}
	}

	&--hovered {
		#{$this}__add-block {
			display: grid;
		}
	}

	&--is-last {
		#{$this}__resize-handle {
			@media screen and (max-width: $media-mobile) {
				left: 5px;
				right: auto;
			}
		}

	}

	&__add-block {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: $z-index-controls-section-line;
		display: none;
		justify-content: center;
		pointer-events: none;
		transform: translateY(50%);
	}

	&__selection {
		position: absolute;
		top: v-bind(transparentHeaderHeightCSSVar);
		left: 0;
		z-index: $z-index-controls-block-selection;
		display: none;
		width: 100%;
		height: 100%;
		max-height: calc(100% - v-bind(transparentHeaderHeightCSSVar));
		pointer-events: none;
	}

	&__resize-handle-container {
		position: absolute;
		z-index: $z-index-controls-edit; // button container should be above block edit level
		align-self: end;
		justify-self: center;
		width: 100%;
		max-width: 1200px;
		left: 50%;
		transform: translateX(-50%);
	}

	&__resize-handle {
		position: absolute;
		right: 0;
		z-index: $z-index-controls-edit;

		@media screen and (max-width: $media-mobile) {
			right: 5px;
		}
	}

	&__resize-handle-text {
		user-select: none;
		margin: 0;
	}
}

.zyro-mb-preview {
	.block-controls {
		&__resize-handle {
			right: 5px;

			@media screen and (max-width: $media-mobile) {
				right: auto;
			}
		}
	}
}
</style>
