/* eslint-disable import/no-cycle */
import { generateToken } from '@/api/AuthApi';
import { useAuthStore } from '@/stores/authStore';
import {
	getRedirectUrlToEcommerce,
	getRedirectUrlToHPanel,
} from '@/use/useRedirects';

const APPS = {
	HPANEL: 'hpanel',
	ECOMMERCE: 'website_builder_ecommerce_admin',
	LOGO_MAKER: 'logo_maker',
} as const;

/** This service is used to pass fresh JWT to another hostinger app on every redirect, to have same auth logic in every project */
export const authRedirectService = {
	redirectToHpanel: async ({
		path = '',
		params = {},
		target = '_self',
	} = {}) => {
		const authStore = useAuthStore();

		const redirectObject: {
			path: string,
			params: Record<string, string>
		} = {
			path,
			params: {
				...params,
			},
		};

		try {
			authStore.setIsAuthRedirecting(true);
			const { data } = await generateToken(APPS.HPANEL);

			redirectObject.params.jwt = data.data.token;

			window.open(getRedirectUrlToHPanel(redirectObject), target);
		} catch {
			window.open(getRedirectUrlToHPanel(redirectObject), target);
		}

		authStore.setIsAuthRedirecting(false);
	},
	redirectToEcommerce: async ({
		path = '',
		params = {},
		siteId = '',
	} = {}) => {
		const authStore = useAuthStore();

		const redirectObject: {
			path: string,
			params: Record<string, string>,
			siteId: string,
		} = {
			path,
			siteId,
			params: {
				...params,
			},
		};

		try {
			authStore.setIsAuthRedirecting(true);
			const { data } = await generateToken(APPS.ECOMMERCE);

			redirectObject.params.jwt = data.data.token;

			window.open(getRedirectUrlToEcommerce(redirectObject), '_self');
		} catch {
			window.open(getRedirectUrlToEcommerce(redirectObject), '_self');
		}
	},
	redirectToLogoMaker: async ({ ref } = {
		ref: '',
	}) => {
		const authStore = useAuthStore();

		const url = new URL('', import.meta.env.VITE_LOGO_MAKER_URL);

		url.searchParams.append('ref', ref);

		try {
			authStore.setIsAuthRedirecting(true);
			const { data } = await generateToken(APPS.LOGO_MAKER);

			url.searchParams.append('jwt', data.data.token);

			window.open(url, '_blank');
		} catch {
			window.open(url, '_blank');
		}

		authStore.setIsAuthRedirecting(false);
	},
};
