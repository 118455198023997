<template>
	<ZyroDrawer
		class="multipage-drawer"
		:header-title="$t('builder.siteMenu.title')"
		:header-description="$t('builder.siteMenu.description')"
		@back="closeInnerSidebar"
	>
		<template #body>
			<div
				v-qa="'builder-sidemenu-websitemenu'"
				class="multipage-drawer-content__content"
				:class="isDialogOpen && 'multipage-drawer-content__content--no-scroll'"
			>
				<DrawerList
					v-qa="'buildersidebar-multipage-visiblepages'"
					:navigation-items="rootEditableItems"
					:title="t('builder.siteMenu.mainNavigation')"
					:group-id="NAVIGATION_GROUP_ROOT"
					:move-callback="handleItemMove"
					@open-page-settings-popup="setActiveDrawerPageSettingsPopup"
					@edit="edit"
					@draggable-end="handleItemsSort"
					@item-click="handleItemClick"
				>
					<template #placeholder>
						<EmptyPagesBlock
							:title="t('builder.siteMenu.noMainPages')"
							:subtitle="t('builder.siteMenu.addMainPage')"
						/>
					</template>
				</DrawerList>

				<div class="other-pages">
					<div class="text-bold-2">
						{{ t('builder.siteMenu.otherPages') }}
					</div>

					<div
						class="other-pages__item-wrapper"
						:class="{ 'other-pages__item-wrapper--active': isCurrentPagePrivate }"
						@click="openPrivatePage"
					>
						<div class="other-pages__toggle-title">
							<Icon
								dimensions="20px"
								:name="siteStore.isPrivateModeActive ? 'vpn_lock' : 'public'"
								class="other-pages__toggle-title-icon"
							/>
							<p class="text-body-2 other-pages__toggle-title__title">
								{{ t('builder.privateMode.pageTitle') }}
							</p>
						</div>
						<div class="other-pages__toggle-wrapper">
							<ZyroTooltip
								position="bottom"
								toggle-event="hover"
								mode="dark"
								size="small"
								is-on-drawer
							>
								<template #trigger>
									<Icon
										name="info"
										dimensions="20px"
									/>
								</template>
								<p class="text-body-2">
									{{ $t('builder.privateMode.publishWebsite') }}
								</p>
							</ZyroTooltip>
							<ZyroFieldToggle
								id="other-pages-toggle"
								v-qa="'multipage-drawer-content-private-mode-toggle'"
								class="other-pages__toggle"
								:model-value="siteStore.isPrivateModeActive"
								@click.stop
								@update:model-value="($event) => updateIsPrivateModeActive({
									isPrivateModeActive: $event,
									location: 'sidebar'
								})"
							/>
						</div>
					</div>
					<div
						v-if="ecommerceStore.isDynamicPageFlowEnabled && getters['ecommerce/isStoreTypeZyro']"
						v-qa="'buildersidebar-multipage-product-template'"
						class="other-pages__item-wrapper"
						:class="{ 'other-pages__item-wrapper--active': isCurrentPageTypeDynamicProduct }"
						@click="openProductPageTemplate"
					>
						<div class="other-pages__toggle-title">
							<Icon
								dimensions="20px"
								name="space_dashboard"
								class="other-pages__toggle-title-icon"
							/>
							<p class="text-body-2 other-pages__toggle-title__title">
								{{ t('builder.productPageTemplate') }}
							</p>
						</div>
						<Icon
							dimensions="20px"
							name="chevron_right"
						/>
					</div>
				</div>

				<DrawerList
					:navigation-items="hiddenEditableItems"
					:title="t('builder.siteMenu.hiddenFromNavigation')"
					:group-id="NAVIGATION_GROUP_HIDDEN"
					:move-callback="handleItemMove"
					:subtitle="t('builder.siteMenu.hiddenFromNavigationDescription')"
					@open-page-settings-popup="setActiveDrawerPageSettingsPopup"
					@edit="edit"
					@draggable-end="handleItemsSort"
					@item-click="handleItemClick"
				>
					<template #placeholder>
						<EmptyPagesBlock
							:title="t('builder.siteMenu.noHiddenPages')"
							:subtitle="t('builder.siteMenu.addHiddenPages')"
						/>
					</template>
					<template #item-tooltip="{ targetRef }">
						<Popup
							v-if="isLogoHintVisible"
							:target-ref="targetRef?.$el"
							:portal-selector="SELECTOR_DATA_PORTAL_BUILDER_PREVIEW"
							auto-update
						>
							<div class="logo-hint">
								<div class="logo-hint__container">
									<p class="logo-hint__heading">
										{{ $t('builder.editableItemsList.hiddenLogo') }}
									</p>
									<p class="logo-hint__content">
										{{ $t('builder.editableItemsList.logoHint') }}
									</p>
									<Icon
										name="close"
										class="logo-hint__close-button"
										dimensions="20px"
										@click="closeLogoHintTooltip"
									/>
								</div>
							</div>
						</Popup>
					</template>
				</DrawerList>
			</div>
			<PageSettingsPopup
				v-if="activeDrawerPageSettingsPopup"
				:key="activeDrawerPageSettingsPopup.id"
				:page-id="activeDrawerPageSettingsPopup.id"
				:open-tab="activeDrawerPageSettingsPopup.tab"
				@close="activeDrawerPageSettingsPopup = null"
			/>
		</template>
		<template #footer>
			<div class="multipage-drawer-content__footer">
				<HostingerButton
					v-qa="'builderheader-menuitemhome-addnewpage'"
					@click="openAddPageModal"
				>
					<template #icon-left>
						<Icon name="add" />
					</template>
					{{ t('common.addPage') }}
				</HostingerButton>
				<div class="multipage-drawer-content__footer-right-side">
					<ZyroTooltip
						position="top"
						toggle-event="hover"
						:use-portal="false"
						content-position="absolute"
						size="small"
						:forced-position="{
							left: '-65px',
							bottom: '48px',
							'white-space': 'nowrap'
						}"
					>
						<template #trigger>
							<HostingerButton
								v-qa="'builderheader-menuitemhome-addnewfolder'"
								button-type="plain"
								class="multipage-drawer-content__footer-dropdown-button"
								:title="t('common.addADropdown')"
								@click="addFolder"
							>
								<template #icon>
									<Icon name="playlist_add" />
								</template>
							</HostingerButton>
						</template>
						<p class="text-body-2">
							{{ t('common.addADropdown') }}
						</p>
					</ZyroTooltip>
					<ZyroTooltip
						position="top"
						toggle-event="hover"
						:use-portal="false"
						content-position="absolute"
						size="small"
						:forced-position="{
							left: '-20px',
							bottom: '48px',
							'white-space': 'nowrap'
						}"
					>
						<template #trigger>
							<HostingerButton
								v-qa="'builderheader-menuitemhome-addnewlink'"
								button-type="plain"
								:title="t('common.addLink')"
								icon="link-add"
								@click="openModal({ name: MODAL_LINK_SETTINGS })"
							>
								<template #icon>
									<Icon name="add_link" />
								</template>
							</HostingerButton>
						</template>
						<p class="text-body-2">
							{{ t('common.addLink') }}
						</p>
					</ZyroTooltip>
				</div>
			</div>
		</template>
	</ZyroDrawer>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroDrawer from '@/components/global/ZyroDrawer.vue';
import ZyroTooltip from '@/components/global/ZyroTooltip.vue';

import {
	ref,
	computed,
	onMounted,
} from 'vue';
import { useStore } from 'vuex';
import DrawerList from '@/components/builder-controls/multipage-drawer/DrawerList.vue';
import EmptyPagesBlock from '@/components/builder-controls/multipage-drawer/EmptyPagesBlock.vue';
import ZyroFieldToggle from '@/components/global/ZyroFieldToggle.vue';
import {
	GUI_NAMESPACE,
	OPEN_MODAL,
} from '@/store/builder/gui';
import {
	MODAL_ADD_PAGE,
	GAMIFICATION_TASK_CHANGE_LOGO,
	SELECTOR_DATA_PORTAL_BUILDER_PREVIEW,
	MODAL_LINK_SETTINGS,
	REDIRECT_PARAM_VALUES,
	NAVIGATION_GROUP_HIDDEN,
	NAVIGATION_GROUP_ROOT,
} from '@/constants/builderConstants';
import {
	SiteNavItem,
	NAVIGATION_TYPE,
} from '@hostinger/builder-schema-validator';
import PageSettingsPopup from '@/components/builder-modals/modals/PageSettingsPopup.vue';
import { useNavigationEditableItems } from '@/use/navigation/useNavigationEditableItems';
import { useDrawerPageSettingsPopup } from '@/use/useDrawerPageSettingsPopup';
import { useI18n } from 'vue-i18n';
import { useStorage } from '@vueuse/core';
import Popup from '@/components/global/Popup.vue';
import { useGamification } from '@/use/useGamification';
import { useSidebar } from '@/use/useSidebar';
import { usePrivatePage } from '@/use/usePrivatePage';
import { useSiteStore } from '@/stores/siteStore';
import EventLogApi from '@/api/EventLogApi';
import {
	useRoute,
	useRouter,
} from 'vue-router';
import { useEcommerceStore } from '@/stores/ecommerceStore';

const {
	dispatch,
	getters,
} = useStore();
const siteStore = useSiteStore();
const { t } = useI18n();

const router = useRouter();
const route = useRoute();
const {
	rootEditableItems,
	hiddenEditableItems,
	handleItemsSort,
} = useNavigationEditableItems();
const {
	activeDrawerPageSettingsPopup,
	setActiveDrawerPageSettingsPopup,
} = useDrawerPageSettingsPopup();
const {
	isGamificationVisible,
	getAchievementById,
} = useGamification();
const { closeInnerSidebar } = useSidebar();
const {
	openPrivatePage,
	updateIsPrivateModeActive,
} = usePrivatePage();
const ecommerceStore = useEcommerceStore();

const hasUserSeenHiddenLogoHint = useStorage('hasUserSeenHiddenLogoHint', false);
const isLogoHintVisible = computed(() => isGamificationVisible.value
			&& !getAchievementById(GAMIFICATION_TASK_CHANGE_LOGO)?.isCompleted
			&& hasUserSeenHiddenLogoHint.value === false
			&& getters.siteNav.every(({ isHidden }: {isHidden: boolean}) => isHidden));
const isCurrentPagePrivate = computed(() => getters.isCurrentPagePrivate);
const isCurrentPageTypeDynamicProduct = computed(() => getters.isCurrentPageTypeDynamicProduct);

const closeLogoHintTooltip = () => {
	hasUserSeenHiddenLogoHint.value = true;
};

const isDialogOpen = ref(false);

const openAddPageModal = () => {
	dispatch(`${GUI_NAMESPACE}/${OPEN_MODAL}`, {
		name: MODAL_ADD_PAGE,
	});

	activeDrawerPageSettingsPopup.value = null;

	EventLogApi.logEvent({
		eventName: 'website_builder.add_page.enter',
	});
};

const openModal = ({ name }: { name: string}) => {
	dispatch(`${GUI_NAMESPACE}/${OPEN_MODAL}`, {
		name,
	});
};

const openProductPageTemplate = () => {
	ecommerceStore.handleEditDynamicProductPageClick();

	EventLogApi.logEvent({
		eventName: 'website_builder.ecomm_product_template.enter',
		eventProperties: {
			location: 'side_bar_pages',
		},
	});
};

const handleItemMove = ({
	toId,
	item,
}: {
	toId: string;
	item: SiteNavItem;
}) => {
	// Allow moving item (which is not a folder) without subitems to anywhere
	if (item.linkType !== NAVIGATION_TYPE.FOLDER && item.subItems?.length === 0) {
		return true;
	}

	if (toId === NAVIGATION_GROUP_ROOT || toId === NAVIGATION_GROUP_HIDDEN) {
		return true;
	}

	return false;
};

const edit = (data: { newValue: SiteNavItem}) => {
	const {
		navItemId,
		name,
		linkType,
		linkedPageId,
	} = data.newValue;

	if (linkedPageId) {
		dispatch('mergePageData', {
			pageId: linkedPageId,
			pageData: {
				name,
			},
		});
	}

	if (linkType === NAVIGATION_TYPE.LINK || linkType === NAVIGATION_TYPE.FOLDER) {
		dispatch('navigation/setItemData', {
			data: {
				navItemId,
				name,
			},
		});
	}
};

const handleItemClick = ({
	linkedPageId,
	innerLinkPageId,
	isCurrent,
	slug,
}: {
	linkedPageId: string;
	innerLinkPageId: string;
	isCurrent: boolean;
	slug: string;
}) => {
	if ((linkedPageId || innerLinkPageId) && !isCurrent) {
		dispatch('updateCurrentPageId', linkedPageId || innerLinkPageId);

		if (slug) {
			dispatch('updateCurrentPageSlug', slug);
		}
	}
};

const addFolder = () => {
	dispatch('navigation/addItem', {
		item: {
			linkType: NAVIGATION_TYPE.FOLDER,
			name: t('common.newDropdown'),
			subItems: [],
		},
	});
};

onMounted(() => {
	const {
		drawer,
		...restQuery
	} = route.query;

	if (drawer !== REDIRECT_PARAM_VALUES.PAGES) {
		return;
	}

	router.replace({
		...route,
		query: restQuery,
	});
});
</script>

<style lang="scss" scoped>
.multipage-drawer {
	overflow: visible;
}

.multipage-drawer-content {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__content {
		overflow-x: hidden;
		overflow-y: auto;

		:deep() {
			.page-list {
				&:first-child {
					margin: 0;
				}

				&__list {
					margin: 0;
				}

				&__title,
				&__description {
					padding: 0;
					margin-bottom: 16px;
				}
			}
		}

		&--no-scroll {
			overflow: hidden;
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		margin-top: auto;
	}

	&__footer-right-side {
		display: flex;
	}

	&__footer-dropdown-button {
		margin-right: 4px;
	}
}

.logo-hint {
	position: absolute;
	width: 340px;
	padding: 12px 16px;
	background-color: #fff;
	border-radius: 8px;
	transform: translate(16px, -50%);
	box-shadow: 0 6px 14px rgba(0, 0, 0, 10%);

	&__container {
		position: relative;
	}

	&__heading {
		font-weight: 700;
		margin-bottom: 4px;
	}

	&__content {
		line-height: 1.7;
	}

	&__close-button {
		position: absolute;
		right: 4px;
		top: 0;

		&:hover {
			cursor: pointer;
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: -16px;
		width: 16px;
		height: 10px;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 10px solid #fff;
		transform: translateY(-50%);
	}
}

.other-pages {
	$this: &;

	margin: 24px 0;

	&__item-wrapper {
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $color-gray-border;
		cursor: pointer;

		&:hover,
		&:focus {
			background-color: $color-gray-light;
			transition: background 0.3s ease 0s;
		}

		&--active {
			background-color: $color-light;

			#{$this}__toggle-title {
				&__title {
					font-weight: 700;
				}
			}
		}
	}

	&__toggle-wrapper {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-left: 8px;
		color: $color-gray;
	}

	&__toggle-title {
		display: flex;
		align-items: center;
	}

	&__toggle-title-icon {
		margin-right: 8px
	}

	&__toggle {
		width: 40px;
		padding: 0;
	}

	&__toggle-note {
		margin-top: 8px;
		color: $color-gray;
	}
}
</style>
