import { AxiosError } from 'axios';
// eslint-disable-next-line import/no-cycle
import { validateSiteJSON } from '@/api/SitesApi';
import { addBreadcrumb } from '@sentry/vue';
import { SiteData } from '@hostinger/builder-schema-validator';

export const getErrorMessagePath = (error: AxiosError) => {
	switch (error.response?.status) {
	case 401: return 'builder.unauthorizedSavingNotification';
	case 409: return 'builder.changesOnMultipleDevices';
	case 403: return 'builder.embeddedCodeEditDisabled';
	default: return 'builder.defaultSavingNotification';
	}
};

export const validateSiteJSONHandler = async ({
	websiteId,
	website,
}: {
	websiteId: string;
	website: SiteData;
}) => {
	const { errors } = await validateSiteJSON({
		siteId: websiteId,
		siteData: website,
	});

	if (errors.length) {
		addBreadcrumb({
			category: 'JSON_VALIDATION',
			message: 'Invalid JSON after save',
			data: {
				siteData: website,
				errors,
			},
		});
	}
};
