<template>
	<div
		v-qa="'gamification-drawer-referral-banner'"
		class="gamification-drawer-referral-banner"
	>
		<div class="gamification-drawer-referral-banner__content">
			<div class="gamification-drawer-referral-banner__text">
				<i18n-t
					tag="p"
					keypath="builder.referAFriendBannerMessageV2"
				>
					<i18n-t
						tag="b"
						keypath="builder.dollarAmount"
					>
						{{ REFFERAL_DOLLAR_REWARD }}
					</i18n-t>
					<b>{{ REFERRAL_PERCENT }}%</b>
				</i18n-t>
			</div>
		</div>
		<HostingerButton
			class="gamification-drawer-referral-banner__button"
			theme="primary"
			@click="referralsStore.redirectToBuyReferrals('builder_gamefication')"
		>
			<span class="gamification-drawer-referral-banner__button-text">
				{{ $t('builder.referAFriendV2') }}
			</span>
			<template #icon-right>
				<Icon
					name="open_in_new"
					dimensions="20px"
				/>
			</template>
		</HostingerButton>
	</div>
</template>

<script setup lang="ts">
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useReferralsStore } from '@/stores/referralsStore';
import {
	REFERRAL_PERCENT,
	REFFERAL_DOLLAR_REWARD,
} from '@/constants/builderConstants';
import { onMounted } from 'vue';
import EventLogApi from '@/api/EventLogApi';

const referralsStore = useReferralsStore();

onMounted(() => {
	EventLogApi.logEvent({
		eventName: 'website_builder.gamefication.referral_offer_shown',
	});
});
</script>

<style lang="scss" scoped>
.gamification-drawer-referral-banner {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 12px;
	padding: 16px;
	background: var(--color-primary-light);
	border-radius: 12px;

	&__content {
		display: flex;
	}

	&__text {
		font-size: 14px;
		line-height: 24px;
		color: var(--color-primary-dark);
	}

	&__button {
		min-height: 32px;
		height: 32px;
		width: 100%;
		display: flex;
		justify-content: center;
	}
}
</style>
