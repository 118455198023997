import { useStore } from 'vuex';
import { computed } from 'vue';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { usePreviewDomain } from '@/use/usePreviewDomain';
import { useReferralsStore } from '@/stores/referralsStore';

export const usePublish = () => {
	const {
		state,
		dispatch,
		commit,
	} = useStore();

	const { setPreviewDomain } = usePreviewDomain();
	const referralsStore = useReferralsStore();

	const siteExpiresAt = computed(() => state.expiresAt);
	const zyroDomain = computed<string>(() => state.zyroDomain);
	const previewDomain = computed(() => state.previewDomain);

	const daysLeftUntilExpiration = computed(() => {
		const currentDate = new Date();

		const expirationDate = new Date(siteExpiresAt.value);
		const timeDiff = expirationDate.getTime() - currentDate.getTime();
		const daysUntilExpiration = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

		if (daysUntilExpiration < 0) return 0;

		return daysUntilExpiration;
	});

	const handleRepublish = async () => {
		referralsStore.handleSiteUpdate();

		dispatch('gui/setIsSiteBeingUpdated', true);
		commit('gui/toggleSiteBeingPublished');

		await dispatch('updateWebsite');

		commit('gui/toggleSiteBeingPublished');
	};

	const handlePublish = async () => {
		if (isHostingerBrand) {
			await setPreviewDomain();
		}

		commit('gui/toggleSiteBeingPublished');

		await dispatch('publishWebsite', {
			zyroDomain: zyroDomain.value,
			previewDomain: previewDomain.value,
		});

		commit('gui/toggleSiteBeingPublished');
	};

	return {
		setPreviewDomain,
		handleRepublish,
		handlePublish,
		daysLeftUntilExpiration,
	};
};
