<template>
	<div
		ref="pulsingDotRef"
		class="template-onboarding"
		:class="{ 'template-onboarding--with-popup-offset': usePopupOffset, }"
	>
		<PulsingDot />

		<Popup
			v-if="currentOnboardingStepConfig"
			:target-ref="pulsingDotRef"
			:placement="placement"
			:offset="offset"
			:flip="false"
			auto-update
			@click-outside="finishProductTemplateOnboarding"
		>
			<div
				ref="pulsingDotPopupRef"
				class="template-onboarding-popup"
			>
				<div class="template-onboarding-popup__header">
					<p class="text-bold-2">
						{{ t(currentOnboardingStepConfig.title18nPath) }}
					</p>
					<HostingerButton
						v-qa="'modal-btn-close'"
						button-type="plain"
						size="sm"
						:title="$t('common.close')"
						class="template-onboarding-popup__button"
						@click="finishProductTemplateOnboarding"
					>
						<template #icon>
							<Icon name="close" />
						</template>
					</HostingerButton>
				</div>
				<div class="template-onboarding-popup__content">
					<p class="text-body-2">
						{{ t(currentOnboardingStepConfig.description18nPath) }}
					</p>
				</div>
				<div class="template-onboarding-popup__footer">
					<p class="template-onboarding-popup__step-count text-body-2 ">
						{{ `${currentProductTemplateStepIndex}/${productTemplateOnboardingStepCount}` }}
					</p>
					<HostingerButton
						v-qa="'template-onboarding-next'"
						:title="t('builder.onboarding.next')"
						@click="goToNextProductTemplateOnboardingStep"
					>
						{{ isLastStep ? t('common.done') : t('builder.onboarding.next') }}
					</HostingerButton>
				</div>
			</div>
		</Popup>
	</div>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
	onMounted,
} from 'vue';
import Popup from '@/components/global/Popup.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import PulsingDot from '@/components/global/PulsingDot.vue';
import { useProductTemplateOnboarding } from '@/use/useProductTemplateOnboarding';
import { productTemplateOnboardingConfig } from '@/constants/builderConstants';
import { useI18n } from 'vue-i18n';

interface PopupOffset {
	mainAxis: number,
	crossAxis: number,
}

withDefaults(defineProps<{
	left?: string,
	top?: string,
	placement: string,
	usePopupOffset?: boolean,
	offset?: PopupOffset,
}>(), {
	top: '80%',
	left: '45%',
	usePopupOffset: true,
});

const { t } = useI18n();

const {
	currentProductTemplateOnboardingStep,
	productTemplateOnboardingStepCount,
	currentProductTemplateStepIndex,
	finishProductTemplateOnboarding,
	goToNextProductTemplateOnboardingStep,
	isLastStep,
} = useProductTemplateOnboarding();

const pulsingDotRef = ref<null | HTMLElement>(null);
const pulsingDotPopupRef = ref<null | HTMLElement>(null);

const currentOnboardingStepConfig = computed(() => currentProductTemplateOnboardingStep.value
		&& productTemplateOnboardingConfig[currentProductTemplateOnboardingStep.value]);

onMounted(() => {
	if (pulsingDotRef.value) {
		pulsingDotRef.value.scrollIntoView({
			behavior: 'smooth',
		});
	}
});
</script>

<style scoped lang="scss">

.template-onboarding {
	position: absolute;
	top: calc(v-bind(top) - 8px);
	left: calc(v-bind(left) - 8px);
}

.template-onboarding--with-popup-offset {
	margin-top: -250px;
	padding-top: 250px;
}

.template-onboarding-popup {
	background-color: $color-light;
	max-width: 340px;
	display: flex;
	flex-direction: column;
	border-radius: 16px;
	width: 340px;
	box-shadow: 0 6px 14px 0 rgba($color-dark, 10%);
	border: 1px solid $color-gray-border;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		border-bottom: 1px solid $color-gray-border;
	}

	&__header,
	&__content{
		padding: 12px 16px;
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 4px 12px 16px;
	}

	&__button {
		margin-top: -8px;
		margin-bottom: -8px;
		margin-right: -6px;
	}

	&__step-count {
		margin-right: 16px;
		color: #6D7081;
	}
}
</style>
