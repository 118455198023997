<script setup>
import {
	ref,
	computed,
	onMounted,
	onBeforeUnmount,
	nextTick,
} from 'vue';
import { useStore } from 'vuex';

import LayoutElementWrapper from '@zyro-inc/site-modules/components/blocks/layout/LayoutElementWrapper.vue';
import VisibilityTooltip from '@/components/builder-view/VisibilityTooltip.vue';
import {
	ELEMENT_TYPE_BUTTON,
	ELEMENT_TYPE_STRIPE_BUTTON,
	ELEMENT_TYPE_ECOMMERCE_BUTTON,
	ELEMENT_TYPE_MAP,
	ELEMENT_TYPE_VIDEO,
	ELEMENT_TYPE_IMAGE,
	ELEMENT_TYPE_TEXT_BOX,
	ELEMENT_TYPE_FORM,
	ELEMENT_TYPE_INSTAGRAM_FEED,
	ELEMENT_TYPE_SOCIAL_ICONS,
	ELEMENT_TYPE_GALLERY,
	ELEMENT_TYPE_EMBED,
	ELEMENT_TYPE_SHAPE,
	ELEMENT_TYPE_SEARCH_BAR,
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
	ANIMATION_NOT_SUPPORTED_ELEMENTS,
	DATA_ATTRIBUTE_SELECTOR_POPUP_CONTENT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { LAYOUT_ELEMENT_SELECTOR_CURRENT_ELEMENT } from '@/constants/builderConstants';
import GridButtonProviderBuilder from '@/components/elements/GridButtonProviderBuilder.vue';
import GridEcommerceButtonProviderBuilder from '@/components/elements/GridEcommerceButtonProviderBuilder.vue';
import GridEmbedProviderBuilder from '@/components/elements/GridEmbedProviderBuilder.vue';
import GridFormProviderBuilder from '@/components/elements/GridFormProviderBuilder.vue';
import GridGalleryProviderBuilder from '@/components/elements/GridGalleryProviderBuilder.vue';
import GridImageProviderBuilder from '@/components/elements/GridImageProviderBuilder.vue';
import GridInstagramFeedProviderBuilder from '@/components/elements/GridInstagramFeedProviderBuilder.vue';
import GridMapProviderBuilder from '@/components/elements/GridMapProviderBuilder.vue';
import GridSocialIconsProviderBuilder from '@/components/elements/GridSocialIconsProviderBuilder.vue';
import GridStripeButtonProviderBuilder from '@/components/elements/GridStripeButtonProviderBuilder.vue';
import GridTextBoxProviderBuilder from '@/components/elements/GridTextBoxProviderBuilder.vue';
import GridVideoProviderBuilder from '@/components/elements/GridVideoProviderBuilder.vue';
import GridShapeProviderBuilder from '@/components/elements/GridShapeProviderBuilder.vue';
import SiteElementSearchBarProviderBuilder from '@/components/elements/SiteElementSearchBarProviderBuilder.vue';
import { useCurrentElementRef } from '@/use/useCurrentElementRef';
import { useElementsRefs } from '@/use/useElementsRefs';
import { useLayoutContextMenu } from '@/components/context-menu/useLayoutContextMenu';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import { useLayoutElementResizeObserver } from '@/use/useLayoutElementResizeObserver';
import { onKeyStroke } from '@vueuse/core';
import { useTextEditor } from '@/use/text-editor/useTextEditor';

const isLocalEnv = import.meta.env.DEV;

const props = defineProps({
	elementId: {
		type: String,
		required: true,
	},
	blockId: {
		type: String,
		required: true,
	},
	elementData: {
		type: Object,
		required: true,
	},
	multiSelectedElementsIds: {
		type: Array,
		default: () => [],
	},
	lowerElementsIdsRelativeToActive: {
		type: Array,
		default: () => [],
	},
	elementsCssVars: {
		type: Object,
		default: () => {},
	},
	isMobileView: {
		type: Boolean,
		default: false,
	},
	renderedPosition: {
		type: Object,
		default: null,
	},
	areControlsDisabled: {
		type: Boolean,
		default: false,
	},
	isActiveElementPresent: {
		type: Boolean,
		default: false,
	},
	isMultiSelectInProgress: {
		type: Boolean,
		default: false,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
	isBlockingResize: {
		type: Boolean,
		default: false,
	},
	isMobileMode: {
		type: Boolean,
		required: false,
	},
	isPreviewMode: {
		type: Boolean,
		required: false,
	},
});

const emits = defineEmits([
	'element-size-changed',
	'reset-multi-select',
]);

const {
	dispatch,
	state,
	getters,
} = useStore();
const { refs } = useElementsRefs();
const { elementRef } = useCurrentElementRef(props);
const { editor } = useTextEditor();
const {
	animationInEditorClass,
	isAnimationDisplayedInEditor,
	isAnimationDisplayedInEditorActive,
	animationInEditorComponentId,
	animationInEditorBlockId,
} = useSiteEngineAnimations();

const {
	intersectionObserver,
	animationClass,
	addIntersectionObserver,
	observe,
} = useSiteEngineAnimations({
	elementData: props.elementData,
	elementId: props.elementId,
});

const {
	observeLayoutElement,
	unobserveLayoutElement,
} = useLayoutElementResizeObserver();

const ELEMENT_SIZE_CHANGE_INTERVAL = 1000;

const isTouchMoving = ref(false);

const isInView = ref(false);

const elementTaps = ref(0);
const elementTapTimer = ref(null);

const elementSizeChangeInterval = ref(null);

const currentElementId = computed(() => getters.currentElementId);
const hasGoogleFontsLoaded = computed(() => state.fonts.hasGoogleFontsLoaded);
const isElementEditMode = computed(() => state.isElementEditMode);

const isHiddenDesktop = computed(() => !props.isMobileView && !!props.elementData.desktop?.isHidden);
const isHiddenMobile = computed(() => props.isMobileView && !!props.elementData.mobile?.isHidden);
const isTooltipShown = computed(() => props.elementData.desktop.width > 90 && props.elementData.desktop.height > 90);

const customAnimationClass = computed(() => {
	const isAnimationTriggered = !animationInEditorBlockId.value
	&& isAnimationDisplayedInEditor.value
	&& !ANIMATION_NOT_SUPPORTED_ELEMENTS.includes(props.elementData.type);

	const isAnimationForElementTriggered = props.elementId === animationInEditorComponentId.value;

	if (!isAnimationTriggered || (animationInEditorComponentId.value && !isAnimationForElementTriggered)) {
		return '';
	}

	return animationInEditorClass.value;
});

const isHighlighted = computed(() => currentElementId.value === props.elementId);

const elementComputedStyles = computed(() => props.elementsCssVars?.[props.elementId] ?? {});

const isElementMultiSelected = computed(() => props.multiSelectedElementsIds.includes(props.elementId));
const isElementLowerThatActiveElement = computed(() => props.lowerElementsIdsRelativeToActive.includes(props.elementId));

const isElementActive = computed(() => props.isActive
	|| (props.isActiveElementPresent && isElementMultiSelected.value)
	|| (props.isActiveElementPresent && isElementLowerThatActiveElement.value));

const observeElement = async () => {
	if (elementRef.value?.$el) {
		await observe(elementRef.value.$el);
	}
};

const initiateAnimations = async () => {
	const imageListTypeElements = [
		ELEMENT_TYPE_INSTAGRAM_FEED,
		ELEMENT_TYPE_GALLERY,
	];
	const isElementTypeImageList = imageListTypeElements.includes(props.elementData.type);
	const elementHeight = elementRef.value?.$el.getBoundingClientRect().height;
	const isElementTooTallForScreen = elementHeight > window.innerHeight;
	const elementRoot = !isElementTypeImageList && isElementTooTallForScreen && elementRef.value
		? elementRef.value.$el.parentElement
		: null;

	addIntersectionObserver({
		root: elementRoot,
	});

	if (!isElementTypeImageList) {
		await observeElement();
	}
};

onMounted(() => {
	// Had to add setTimeout 0, to put animation action into async
	// event loop. Without it, grid layout is set before animation end, and this causes grid layout to break
	if (props.isPreviewMode) {
		setTimeout(() => {
			initiateAnimations();
		}, 0);
	}

	refs.value[props.elementId] = elementRef.value.$el;

	observeLayoutElement({
		htmlElement: elementRef.value.$el,
		triggerCallback: () => {
			if (props.elementData.type === ELEMENT_TYPE_TEXT_BOX) {
				if (hasGoogleFontsLoaded.value || isLocalEnv) {
					emits('element-size-changed');
				} else {
					elementSizeChangeInterval.value = setInterval(() => {
						if (hasGoogleFontsLoaded.value) {
							emits('element-size-changed');
							clearInterval(elementSizeChangeInterval.value);
						}
					}, ELEMENT_SIZE_CHANGE_INTERVAL);
				}

				return;
			}

			// Gallery is not in view and image are not rendered, so we don't want to trigger size change
			if (props.elementData.type === ELEMENT_TYPE_GALLERY && !isInView.value) {
				return;
			}

			emits('element-size-changed');
		},
		elementId: props.elementId,
	});
});

onBeforeUnmount(() => {
	if (props.isPreviewMode) {
		intersectionObserver.value?.disconnect();
	}

	delete refs.value[props.elementId];

	// in development during dev server refresh elementRef is not defined and throws error
	if (!elementRef.value?.$el && isLocalEnv) return;

	unobserveLayoutElement({
		htmlElement: elementRef.value.$el,
		elementId: props.elementId,
	});
});

const {
	isElementCut,
	copiedElementId,
	copiedElementLocale,
} = useLayoutContextMenu();

onKeyStroke('Enter', (event) => {
	if (currentElementId.value !== props.elementId) {
		return;
	}

	const isPopupPresent = !!document.querySelector(`body > [${DATA_ATTRIBUTE_SELECTOR_POPUP_CONTENT}]`);

	if (!isPopupPresent && !state.isElementEditMode) {
		event.preventDefault();

		dispatch('enterElementEditMode');
	}
}, {
	eventName: 'keyup',
	dedupe: true,
});

const isCurrentElementCut = computed(
	() => isElementCut.value && props.elementId === copiedElementId.value && state.currentLocale === copiedElementLocale.value,
);

const enterSelectedElementEditMode = () => {
	dispatch('selectCurrentElement', {
		elementId: props.elementId,
	});

	dispatch('enterElementEditMode');
};

const handleElementMobileDoubleTap = async () => {
	elementTaps.value += 1;

	if (elementTaps.value === 1) {
		elementTapTimer.value = setTimeout(() => {
			elementTaps.value = 0;
		}, 700);
	} else {
		enterSelectedElementEditMode();

		if (props.elementData.type === ELEMENT_TYPE_TEXT_BOX) {
			// double next tick for editor to fully initialize before focusing it and making keyboard open on mobile
			await nextTick();
			await nextTick();

			editor.value.view.focus();
		}

		clearTimeout(elementTapTimer.value);

		elementTaps.value = 0;
	}
};

const handleElementMousedown = () => {
	if (isTouchMoving.value) {
		return;
	}

	if (props.elementId === currentElementId.value) {
		return;
	}

	if (isElementEditMode.value) {
		dispatch('leaveElementEditMode');
	}

	if (!isElementMultiSelected.value) {
		emits('reset-multi-select');
	}

	dispatch('selectCurrentElement', {
		elementId: props.elementId,
	});

	dispatch('updateCurrentBlockId', props.blockId);
};

const handleTouchend = (e, elementId) => {
	if (props.isPreviewMode) {
		return;
	}

	if (e.cancelable) {
		e.preventDefault();
	}

	handleElementMousedown(elementId);
	handleElementMobileDoubleTap();
};
</script>

<template>
	<LayoutElementWrapper
		ref="elementRef"
		v-qa="`${elementData.type}:${elementId}`"
		class="layout-element"
		:data-element-id="elementId"
		:element-data="elementData"
		:style="elementComputedStyles"
		:data-popper-reference="currentElementId === elementId ? LAYOUT_ELEMENT_SELECTOR_CURRENT_ELEMENT : null"
		:class="[
			{
				'layout-element--desktop-element-hidden': isPreviewMode && !isMobileView && isHiddenDesktop,
				'layout-element--mobile-element-hidden': isPreviewMode && isMobileView && isHiddenMobile,
				'is-cut': isCurrentElementCut,
				'is-active-element-present': isActiveElementPresent,
				'is-highlighted': !isPreviewMode && isHighlighted,
				'is-blocking-resize': isBlockingResize,
				'layout-element--is-active': !isPreviewMode && isElementActive,
				'layout-element--is-multi-selected': isElementMultiSelected,
				'layout-element--is-preview-mode': isPreviewMode,
			}, isPreviewMode ? animationClass : customAnimationClass
		]"
		:[DATA_ATTRIBUTE_ANIMATION_STATE]="isAnimationDisplayedInEditorActive ? DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE : null"
		:is-mobile-view="isMobileView"
		@mousedown.stop="!isPreviewMode && handleElementMousedown(elementId)"
		@touchmove="isTouchMoving = true"
		@touchstart="isTouchMoving = false"
		@touchend="(e) => handleTouchend(e, elementId)"
		@dblclick="!isPreviewMode && enterSelectedElementEditMode()"
	>
		<VisibilityTooltip
			:is-hidden-desktop="isHiddenDesktop"
			:is-hidden-mobile="isHiddenMobile"
			:is-tooltip-shown="isTooltipShown"
		/>
		<GridButtonProviderBuilder
			v-if="elementData.type === ELEMENT_TYPE_BUTTON"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<GridStripeButtonProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_STRIPE_BUTTON"
			:data="elementData"
			:data-element-ref="elementId"
			:is-active="isElementActive"
			class="layout-element__component layout-element__component--GridStripeButton"
			:is-preview-mode="isPreviewMode"
		/>
		<GridEcommerceButtonProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_ECOMMERCE_BUTTON"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<GridMapProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_MAP"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<GridVideoProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_VIDEO"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<GridImageProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_IMAGE"
			:element-id="elementId"
			:data="elementData"
			:rendered-position="renderedPosition"
			:reset-mobile-position="false"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<GridTextBoxProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_TEXT_BOX"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
			:is-edit-controls-hidden="isMultiSelectInProgress"
		/>
		<GridFormProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_FORM"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<GridInstagramFeedProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_INSTAGRAM_FEED"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
			@media-loaded="() => isPreviewMode && observeElement()"
		/>
		<GridSocialIconsProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_SOCIAL_ICONS"
			:element-id="elementId"
			:data="elementData"
			:are-controls-disabled="areControlsDisabled"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<GridGalleryProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_GALLERY"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
			@update-is-in-view="isInView = $event"
			@image-load="() => isPreviewMode && observeElement()"
		/>
		<GridEmbedProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_EMBED"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<GridShapeProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_SHAPE"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<SiteElementSearchBarProviderBuilder
			v-else-if="elementData.type === ELEMENT_TYPE_SEARCH_BAR"
			:element-id="elementId"
			:data="elementData"
			:is-active="isElementActive"
			:is-preview-mode="isPreviewMode"
		/>
		<div
			v-if="!isPreviewMode"
			class="layout-element__slot"
		>
			<slot />
		</div>
	</LayoutElementWrapper>
</template>

<style lang="scss">
@import "@zyro-inc/site-modules/components/blocks/layout/LayoutElementWrapperProvider";

.layout-element {
	$this: &;

	pointer-events: all;
	cursor: move;

	&--is-preview-mode {
		cursor: auto;

		* {
			pointer-events: all !important;
		}
	}

	&__component {
		overflow: var(--overflow, hidden);

		&--GridImage {
			height: 100%;
		}

		&--GridSocialIcons {
			height: auto;

			&#{&} {
				max-height: fit-content;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.layout-element {
	$this: &;

	&--desktop-element-hidden, &--mobile-element-hidden {
		display: none;
	}

	&--is-active {
		&#{&} {
			position: absolute;
			top: var(--element-top);
			left: var(--element-left);
			grid-area: 1 / 1 / -1 / -1;
			width: var(--element-width);
			height: var(--element-height);

			// Needed for correctly setting hovered block while dragging element.
			// Without it wrong block will be set, because you hover element that belongs to another block
			pointer-events: none;

			#{$this}__component {
				user-select: none;

				:deep(.warning-tooltip) {
					* {
						pointer-events: none;
					}
				}
			}
		}
	}

	&__slot {
		display: grid;
		grid-area: 1 / 1 / -1 / -1;
		visibility: hidden;
	}

	&.is-cut {
		opacity: 0.5;
	}

	&:not(.is-highlighted) {
		:deep(#{$this}__component) {

			* { pointer-events: none; }

			.warning-tooltip {
				* {
					pointer-events: auto;
				}
			}
		}
	}

	&:not(.is-active-element-present):hover,
	&.is-highlighted,
	&.is-blocking-resize,
	&--is-multi-selected {
		// Needed so you can select text in safari while editing
		user-select: text;

		#{$this}__slot {
			visibility: visible;
		}
	}
}
</style>
