<template>
	<div
		ref="builderSidebarBackButtonRef"
		v-qa="'settings-button'"
		class="settings-button"
		:class="[{ 'settings-button--open': isManageModalOpen }]"
	>
		<HostingerButton
			v-qa="'builder-sidebar-btn-back'"
			class="settings-button__button"
			:class="[{ 'settings-button__button--open': isManageModalOpen }]"
			button-type="plain"
			@click="handleMenuClick"
		>
			<Hamburger
				v-if="isMobileScreen"
				:is-open="isManageModalOpen"
			/>
			<div
				v-else
				class="settings-button__toggle"
			>
				{{ t('common.websiteBuilder') }}
				<Icon
					name="expand_more"
					class="settings-button__toggle-icon"
					:class="[{ 'settings-button__toggle-icon--open': isManageModalOpen }]"
				/>
			</div>
		</HostingerButton>
		<Transition
			name="fade"
			mode="out-in"
		>
			<div
				v-if="isManageModalOpen"
				class="backdrop"
				@click="closeMenu"
			/>
		</Transition>
		<Transition
			mode="out-in"
			appear
			:name="isMobileScreen ? 'drawer-slide-left' : 'slide-top-to-bottom'"
		>
			<div
				v-if="isManageModalOpen"
				class="menu"
			>
				<HostingerButton
					class="menu__close-button"
					button-type="plain"
					@click="closeMenu"
				>
					<template #icon>
						<Icon name="close" />
					</template>
				</HostingerButton>
				<div class="menu__buttons">
					<div class="menu__controls menu__controls--hidden">
						<BuilderHeaderDomainConnectionStatus
							v-if="customDomain && isMobileScreen && !isUserPayToPublish"
							class="menu__button menu__button--truncated"
						/>
						<ZyroSeparator class="separator" />
					</div>
					<!-- Back buttons -->
					<HostingerButton
						v-if="isHostingerBrand"
						v-qa="`builderheader-backtohpanel-websites`"
						button-type="plain"
						class="menu__button menu__button--top"
						@click="redirectToHPanel({ path: 'websites' })"
					>
						{{ t('siteSettings.myWebsites') }}
						<template #icon-left>
							<Icon name="home" />
						</template>
					</HostingerButton>
					<HostingerButton
						v-else
						v-qa="`builderheader-backtossdashboard-sites`"
						button-type="plain"
						class="menu__button menu__button--top"
						:href="getRedirectUrlToDashboard()"
					>
						{{ t('siteSettings.allWebsites') }}
						<template #icon-left>
							<Icon name="chevron_left" />
						</template>
					</HostingerButton>
					<ZyroSeparator class="separator" />

					<!-- Hostinger buttons -->
					<template v-if="isHostingerBrand">
						<HostingerButton
							v-qa="`builderheader-manage-websites-button`"
							button-type="plain"
							class="menu__button"
							@click="redirectToHPanel({ path: hpanelManagePath })"
						>
							{{ t('builder.websiteManager') }}
							<template #icon-left>
								<Icon name="settings" />
							</template>
						</HostingerButton>
						<HostingerButton
							v-qa="`builderheader-manage-store-button`"
							button-type="plain"
							class="menu__button"
							:href="getRedirectUrlToEcommerce({
								siteId: websiteId,
								path: '',
								params: {}
							})"
							@click.prevent="redirectToEcommerce({ siteId: websiteId, })"
						>
							{{ t('builder.storeManager.title') }}
							<template #icon-left>
								<Icon name="shopping_bag" />
							</template>
						</HostingerButton>
						<ZyroSeparator class="separator" />
						<HostingerButton
							v-if="isSiteCapacityReached || isUserPayToPublish"
							v-qa="`builderheader-button-changedesign`"
							button-type="plain"
							class="menu__button menu__button--bottom"
							:href="getRedirectUrlToBuilder({
								path: REDIRECT_PARAM_VALUES.AI_BUILDER,
								params: {
									siteId: websiteId,
									isChangingTemplate: true
								}
							})"
						>
							{{ t('builder.changeDesign') }}
							<template #icon-left>
								<Icon
									name="space_dashboard"
									dimensions="24px"
								/>
							</template>
						</HostingerButton>
						<HostingerButton
							v-else
							v-qa="`builderheader-design-ai-button`"
							button-type="plain"
							class="menu__button menu__button--bottom"
							:is-disabled="wasRedirectTriggered"
							:is-loading="wasRedirectTriggered"
							@click="handleCreateNewClick"
						>
							{{ t('builder.createNew') }}
							<template #icon-left>
								<Icon
									name="plus"
									dimensions="20px"
									is-custom
								/>
							</template>
						</HostingerButton>
					</template>

					<!-- Zyro buttons -->
					<template v-else>
						<p class="text-body-2 menu__title">
							{{ t('builder.builderHeaderManage') }}
						</p>
						<HostingerButton
							v-qa="`builderheader-manage-zyrowebsites-button`"
							button-type="plain"
							class="menu__button"
							:href="getRedirectUrlToDashboard({
								path: WWW_REDIRECT_PATHS.SINGLE_SITE_DASHBOARD,
								params: { siteId: websiteId }
							})"
						>
							{{ t('builder.websiteManager') }}
							<template #icon-left>
								<Icon name="settings" />
							</template>
						</HostingerButton>
						<template v-if="isStoreTypeZyro">
							<HostingerButton
								v-qa="`builderheader-manage-zyrostore-button`"
								button-type="plain"
								class="menu__button menu__button--bottom"
								:href="getRedirectUrlToEcommerce({
									siteId: websiteId,
									path: '',
									params: {}
								})"
								@click.prevent="redirectToEcommerce({ siteId: websiteId, })"
							>
								{{ t('builder.storeManager.title') }}
								<template #icon-left>
									<Icon name="shopping_bag" />
								</template>
							</HostingerButton>
						</template>
					</template>
					<div class="menu__controls menu__controls--hidden">
						<ZyroSeparator class="separator" />
						<HostingerButton
							v-qa="`builderheader-chat-button`"
							button-type="plain"
							class="menu__button"
							@click="handleIntercomOpen"
						>
							<template #icon-left>
								<Icon name="chat" />
							</template>
							{{ t('builder.chatWithUs') }}
						</HostingerButton>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>
<script setup lang="ts">
import Hamburger from '@/components/global/Hamburger.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import Icon from '@/components/global/Icon.vue';
import ZyroSeparator from '@/components/global/ZyroSeparator.vue';
import BuilderHeaderDomainConnectionStatus from '@/components/builder-view/BuilderHeaderDomainConnectionStatus.vue';
import {
	DATA_SELECTOR_MODAL_CONTENT,
	WWW_REDIRECT_PATHS,
	REDIRECT_PARAM_VALUES,
} from '@/constants/builderConstants';
import { useStore } from 'vuex';
import { useDomainStore } from '@/stores/domainStore';
import { useI18n } from 'vue-i18n';
import {
	getRedirectUrlToDashboard,
	getRedirectUrlToEcommerce,
	getRedirectUrlToBuilder,
	useRedirects,
} from '@/use/useRedirects';

import EventLogApi from '@/api/EventLogApi';
import { isHostingerBrand } from '@/utils/isHostingerBrand';

import { useUserAuthorizationState } from '@/use/useUserAuthorizationState';

import {
	ref,
	computed,
} from 'vue';
import { onClickOutside } from '@/utils/onClickOutside';
import { useSidebar } from '@/use/useSidebar';
import { AI_BUILDER_ROUTE } from '@/constants/routes';
import { useIntercomStore } from '@/stores/intercomStore';
import { useRoute } from 'vue-router';
import { useSavingStore } from '@/stores/savingStore';
import { useAiBuilderStore } from '@/stores/aiBuilderStore';

const emit = defineEmits(['toggle-menu']);
const { t } = useI18n();
const route = useRoute();
const {
	state,
	getters,
} = useStore();
const domainStore = useDomainStore();

const { closeSidebar } = useSidebar();
const {
	redirectToHPanel,
	redirectToEcommerce,
} = useRedirects();

const builderSidebarBackButtonRef = ref(null);
const isManageModalOpen = ref(false);
const wasRedirectTriggered = ref(false);

const websiteId = computed<string>(() => state.websiteId);
const isStoreTypeZyro = computed(() => getters['ecommerce/isStoreTypeZyro']);
const redirectDomain = computed(() => domainStore.customDomain || state.previewDomain);
const hpanelManagePath = computed(() => `websites/${redirectDomain.value}/dashboard`);
const customDomain = computed(() => domainStore.customDomain);
const isMobileScreen = computed(() => state.gui.isMobileScreen);
const {
	isSiteCapacityReached,
	isUserPayToPublish,
} = useUserAuthorizationState();
const intercomStore = useIntercomStore();
const savingStore = useSavingStore();
const aiBuilderStore = useAiBuilderStore();

const closeMenu = () => {
	isManageModalOpen.value = false;
	emit('toggle-menu', false);
};

onClickOutside({
	target: builderSidebarBackButtonRef,
	preventSelector: DATA_SELECTOR_MODAL_CONTENT,
}, () => {
	closeMenu();
});

const handleCreateNewClick = async () => {
	if (wasRedirectTriggered.value) {
		return;
	}

	wasRedirectTriggered.value = true;

	await savingStore.saveWebsite();

	EventLogApi.logEvent({
		eventName: 'website_builder.create_new.enter',
	});

	const hostingReferenceId = aiBuilderStore.selectedHostingReferenceId || route.query.hostingReferenceId;

	window.location.assign(getRedirectUrlToBuilder({
		path: AI_BUILDER_ROUTE,
		...(hostingReferenceId && {
			params: {
				hostingReferenceId,
			},
		}),

	}));
};

const handleMenuClick = () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.builder.back',
	});

	isManageModalOpen.value = !isManageModalOpen.value;
	emit('toggle-menu', isManageModalOpen.value);
	closeSidebar();
};

const handleIntercomOpen = () => {
	intercomStore.handleIntercomOpen();
};

</script>
<style lang="scss" scoped>
@import '@/assets/scss/mixins/backdrop';

.settings-button {
	$this: &;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 49px;

	&__button {
		width: 100%;
		margin: 0 4px;
		min-height: 40px;

		:deep() {
			.hostinger-button__text {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.burger {
				height: 13px;
			}
		}

		&--open,
		&--open.zyro-button-header:focus {
			background-color: $color-primary-light;
			color: $color-primary;
		}
	}

	&__toggle {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
	}

	&__toggle-icon {
		transition: transform 0.2s ease-in-out;
		transform: rotate(0deg);

		&--open {
			// move by half of the icon size to keep the same center
			transform: translateY(-2px) rotate(180deg);

		}
	}
}

.menu {
	position: absolute;
	top: #{$header-height-editor + 8px};
	left: 8px;
	z-index: $z-index-layout-sidebar;
	min-width: 224px;
	color: $color-dark;
	background-color: $color-light;
	border: 1px solid $color-gray-border;
	border-radius: 16px;
	box-shadow: $box-shadow;

	@media screen and (max-width: $media-mobile) {
		z-index: $z-index-layout-sidebar-menu;
		position: fixed;
		display: flex;
		overflow: auto;
		padding-bottom: 16px;
		flex-direction: column;
		top: 0;
		left: 0;
		width: calc(100% - $sidebar-min-gap-mobile);
		border: none;
		max-width: 320px;
		border-radius: 0;
		height: 100vh;
		min-width: unset;
	}

	&__controls {
		&--hidden {
			@media screen and (min-width: $media-mobile) {
				display: none;
			}
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
	}

	&__button {
		margin: 0 8px;

		@media screen and (max-width: $media-mobile) {
			margin: 0;
			width: 100%;
		}

		&--top {
			margin-top: 8px;

			@media screen and (max-width: $media-mobile) {
				margin-top: 0;
			}
		}

		&--bottom {
			margin-bottom: 8px;

			@media screen and (max-width: $media-mobile) {
				margin-bottom: 0;
			}
		}

		&--truncated {
			:deep(.hostinger-button__text) {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				display: block;
			}
		}

		:deep() {
			.hostinger-button__text {
				font-weight: 400;
				white-space: break-spaces;
			}
		}
	}

	&__close-button {
		align-self: flex-end;

		@media screen and (min-width: $media-mobile) {
			display: none;
		}
	}

	&__title {
		width: 100%;
		padding: 8px 16px;
		white-space: nowrap;
		font-weight: 700;
		font-size: 12px;
		text-transform: uppercase;
	}

}

.separator {
	margin: 8px 0;

	&--desktop-hidden {
		@media screen and (min-width: $media-mobile) {
			display: none;
		}
	}
}

.backdrop {
	@include backdrop;

	@media screen and (min-width: $media-mobile) {
		display: none;
	}
}
</style>
