<template>
	<div class="form-card">
		<h3 class="text-bold-1 form-card__title">
			<span class="form-card__form-name">
				{{ formId }}
			</span>
			<ZyroSvgDeprecated
				name="bullet-point"
				class="form-card__title-bullet-point"
			/>
			<span class="form-card__submission-count"> {{ entryCount }} {{ t('common.submissions') }}</span>
			<HostingerButton
				v-if="!hasGridFormAssociated"
				ref="moreOptionsButtonRef"
				v-qa="`sitesettings-${formId}-btn-more`"
				class="form-card__more"
				button-type="plain"
				@click="isMoreOptionsPopupOpen = !isMoreOptionsPopupOpen"
			>
				<Icon name="more_horiz" />
			</HostingerButton>
			<Popup
				v-if="isMoreOptionsPopupOpen"
				placement="left"
				:portal-selector="SELECTOR_DATA_PORTAL_APP"
				:target-ref="moreOptionsButtonRef && moreOptionsButtonRef.$el"
				@click-outside="isMoreOptionsPopupOpen = false"
			>
				<div class="popup-content">
					<HostingerButton
						v-qa="`sitesettings-${formId}-btn-removelist`"
						button-type="plain"
						theme="danger"
						@click="isListDeleteModalShown = true"
					>
						<template #icon-left>
							<Icon
								name="delete"
								dimensions="20px"
							/>
						</template>
						<span class="text-body-2">
							{{ t('siteSettings.forms.deleteForm') }}
						</span>
					</HostingerButton>
				</div>
			</Popup>
		</h3>

		<i18n-t
			tag="p"
			class="text-body-1 form-card__form-name"
			keypath="siteSettings.forms.cardDescription"
		>
			{{ formId }}
		</i18n-t>
		<HostingerButton
			v-qa="`sitesettings-${formId}-btn-viewlist`"
			button-type="outlined"
			class="form-card__button"
			@click="emit('update', formId)"
		>
			{{ t('siteSettings.forms.viewSubmissions') }}
		</HostingerButton>
	</div>
	<Teleport
		v-if="isListDeleteModalShown"
		to="body"
	>
		<SystemDialogModal
			:title="t('siteSettings.forms.removeModalTitle')"
			:subtitle="t('siteSettings.forms.removeModalSubtitle', [formId])"
			:primary-button-text="t('common.cancel')"
			:secondary-button-text="t('common.delete')"
			:is-loading="isDeleteLoading"
			secondary-button-color="danger"
			class="delete-modal"
			@click-primary="isListDeleteModalShown = false"
			@click-secondary="removeForm"
			@close="isListDeleteModalShown = false, isMoreOptionsPopupOpen = false"
		/>
	</Teleport>
</template>

<script setup lang="ts">
import {
	ComponentPublicInstance,
	computed,
	ref,
} from 'vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import ZyroSvgDeprecated from '@/components/global/ZyroSvgDeprecated.vue';
import Icon from '@/components/global/Icon.vue';
import Popup from '@/components/global/Popup.vue';
import SystemDialogModal from '@/components/builder-modals/modals/SystemDialogModal.vue';
import { useStore } from 'vuex';
import { SELECTOR_DATA_PORTAL_APP } from '@/constants/builderConstants';
import { SiteLanguages } from '@hostinger/builder-schema-validator';
import { deleteForm } from '@/api/UserDataApi';
import { captureException } from '@sentry/vue';
import { useNotifications } from '@/use/useNotifications';
import { useI18n } from 'vue-i18n';

const props = withDefaults(defineProps<{
	formId: string,
	entryCount: number,
	activeFormId: string | null,
}>(), {
	formId: '',
	entryCount: 0,
});

const emit = defineEmits<{
	update: [string]
}>();

const {
	state,
	commit,
	getters,
} = useStore();
const { notify } = useNotifications();
const { t } = useI18n();

const moreOptionsButtonRef = ref<ComponentPublicInstance<typeof HostingerButton> | null>(null);
const isMoreOptionsPopupOpen = ref(false);
const isListDeleteModalShown = ref(false);
const isDeleteLoading = ref(false);

const hasGridFormAssociated = computed(() => Object.values(getters.siteLanguages as SiteLanguages).some(
	({ elements }) => Object.values(elements).some((element) => (element.type === 'GridForm' ? element.formId === props.formId : false)),
));

const removeForm = async () => {
	isDeleteLoading.value = true;

	try {
		await deleteForm({
			siteId: state.websiteId,
			name: props.formId,
		});

		commit('forms/removeForm', props.formId);
	} catch (error) {
		captureException(new Error('Failed to delete form'));

		notify({
			message: t('builder.notifications.errorDeletingForm'),
		});
	} finally {
		isDeleteLoading.value = false;
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Popup";

.form-card {
	flex: 0 0 60%;
	padding: 24px 32px;
	margin-bottom: 16px;
	background: $color-light;

	@media screen and (max-width: $media-mobile) {
		padding: 16px;
		margin-bottom: 8px;
	}

	&__form-name {
		word-break: break-all;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 24px;

		&-bullet-point {
			margin: 0 7px 0 10px;
			flex-shrink: 0;
		}
	}

	&__submission-count {
		color: $color-gray;
		white-space: nowrap;
	}

	&__button {
		display: block;
		margin-top: 28px;
		margin-left: auto;
	}

	&__more {
		margin-left: auto;
	}
}
</style>
