<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.editBlockNavigation.title')"
		:tabs="tabs"
		:current-tab="currentTab"
		editor-popup-width="400px"
		@update:current-tab="currentTab = $event"
		@close="$emit('close')"
	>
		<EditBlockHeaderTabLayout v-if="currentTab?.id === EDIT_BLOCK_NAVIGATION_TAB_ID_LAYOUT" />
		<EditBlockHeaderTabElements
			v-if="currentTab?.id === EDIT_BLOCK_NAVIGATION_TAB_ID_ELEMENTS"
			@update:current-tab="updateCurrentTab"
			@update:current-style-option-by-id="updateCurrentStyleOptionById"
		/>
		<EditBlockHeaderTabLogo v-if="currentTab?.id === EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO" />
		<EditBlockHeaderTabCart v-if="currentTab?.id === EDIT_BLOCK_NAVIGATION_TAB_ID_CART" />
		<EditBlockHeaderTabStyle
			v-if="currentTab?.id === EDIT_BLOCK_NAVIGATION_TAB_ID_STYLE"
			:current-style-option="currentStyleOption"
			:style-options="STYLE_OPTIONS"
			@update:current-tab="updateCurrentTab"
			@update:current-style-option="currentStyleOption = $event"
		/>
	</ZyroPopupCard>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
	watch,
	onBeforeUnmount,
} from 'vue';

import { useStore } from 'vuex';

import ZyroPopupCard from '@/components/global/ZyroPopupCard.vue';
import EditBlockHeaderTabCart from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabCart.vue';
import EditBlockHeaderTabLayout from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabLayout.vue';
import EditBlockHeaderTabLogo from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabLogo.vue';
import EditBlockHeaderTabStyle from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabStyle.vue';
import EditBlockHeaderTabElements from '@/components/builder-controls/edit-block-header/EditBlockHeaderTabElements.vue';
import {
	EDIT_BLOCK_NAVIGATION_TAB_ID_STYLE,
	EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO,
	EDIT_BLOCK_NAVIGATION_TAB_ID_CART,
	STYLE_OPTION_TYPES,
	EDIT_BLOCK_NAVIGATION_TAB_ID_LAYOUT,
	EDIT_BLOCK_NAVIGATION_TAB_ID_ELEMENTS,
} from '@/constants/builderConstants';
import { EditBlockHeaderStyleDropdownOption } from '@/types/builderTypes';
import { useI18n } from 'vue-i18n';

const emit = defineEmits([
	'close',
	'update:current-tab-id',
]);

const { t } = useI18n();
const {
	state,
	getters,
	dispatch,
} = useStore();

const defaultBlockEditTab = computed(() => state.defaultBlockEditTab);

const STYLE_OPTIONS = [
	{
		id: STYLE_OPTION_TYPES.HEADER,
		title: t('common.header'),
	},
	{
		id: STYLE_OPTION_TYPES.BUTTON,
		title: t('common.button'),
	},
	{
		id: STYLE_OPTION_TYPES.SOCIAL_ICONS,
		title: t('common.socialIcons'),
	},
] as EditBlockHeaderStyleDropdownOption[];
const TABS = [
	{
		id: EDIT_BLOCK_NAVIGATION_TAB_ID_LAYOUT,
		title: t('common.layout'),
		component: EditBlockHeaderTabLayout,
	},
	{
		id: EDIT_BLOCK_NAVIGATION_TAB_ID_ELEMENTS,
		title: t('builder.helpAndResourcesElements'),
		component: EditBlockHeaderTabElements,
	},
	{
		id: EDIT_BLOCK_NAVIGATION_TAB_ID_LOGO,
		title: t('common.logo'),
		component: EditBlockHeaderTabLogo,
	},
	{
		id: EDIT_BLOCK_NAVIGATION_TAB_ID_CART,
		title: t('common.shoppingBag'),
		component: EditBlockHeaderTabCart,
	},
	{
		id: EDIT_BLOCK_NAVIGATION_TAB_ID_STYLE,
		title: t('common.style'),
		component: EditBlockHeaderTabStyle,
	},
];

const currentStyleOption = ref<EditBlockHeaderStyleDropdownOption>(STYLE_OPTIONS[0]);
const initialTab = computed(() => (defaultBlockEditTab.value
	? TABS.find((tab) => tab.id === defaultBlockEditTab.value)
	: TABS[0]));
const currentTab = ref(initialTab.value);
const tabs = computed(() => (getters['ecommerce/isLocaleWithEcommerceItems'] ? TABS : TABS.filter((tab) => tab.id !== EDIT_BLOCK_NAVIGATION_TAB_ID_CART)));

const updateCurrentStyleOptionById = (id: string) => {
	currentStyleOption.value = STYLE_OPTIONS.find((option) => option.id === id) || STYLE_OPTIONS[0];
};

const updateCurrentTab = (tabId: string) => {
	currentTab.value = tabs.value.find((tab) => tab.id === tabId);
};

watch(currentTab, (newTab) => emit('update:current-tab-id', newTab?.id), {
	immediate: true,
});

onBeforeUnmount(() => {
	dispatch('undoRedo/createSnapshot');
	emit('update:current-tab-id', null);
});
</script>
