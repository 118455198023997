// eslint-disable-next-line import/no-cycle
import axios from '@/services/http/axios';
import { PaymentMethod } from '@/types/billingTypes';
import { snakeToCamelObj } from '@/utils/snakeToCamel';

const HBILLING_API = `${import.meta.env.VITE_HBILLING_API_URL}/api`;

type PaymentMethodsResponse = {
	data: PaymentMethod[];
	status: number,
	success: boolean,
};

export default {
	getPaymentMethods: async (): Promise<PaymentMethod[]> => {
		const { data } = await axios.get<PaymentMethodsResponse>(`${HBILLING_API}/v1/payment/get-payment-methods`);

		return snakeToCamelObj(data.data);
	},
};
