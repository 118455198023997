<script setup lang="ts">
import CheckerList from '@/components/global/CheckerList.vue';
import ZyroFieldInput from '@/components/global/ZyroFieldInput.vue';
import { useStore } from 'vuex';
import { MAX_LENGTH_PAGE_SLUG } from '@/constants/builderConstants';
import { getPagePathFromId } from '@zyro-inc/site-modules/utils/page/getPagePathFromId';
import { slugifyPagePath } from '@/utils/urlValidators';
import { PAGE_TYPES_EXCLUDED_FROM_SLUG_VALIDATION } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import {
	computed,
	ref,
	onMounted,
	watch,
	nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { usePageSeoSettings } from '@/use/usePageSeoSettings';
import {
	getFocusKeywordIncludedInPageSlug,
	getIsSlugUnique,
} from '@/utils/seoValidation';
import { useSiteStore } from '@/stores/siteStore';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';
import { createDiff } from '@/utils/jsondiffpatch';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { useSavingStore } from '@/stores/savingStore';

const USER_SITE_PAGE_SLUG_ECOMMERCE_CHECKOUT = 'checkout';
const {
	state,
	getters,
	dispatch,
} = useStore();
const { t } = useI18n();
const {
	pageMetaFocusKeyword,
	pageSlug,
	mergePageMeta,
} = usePageSeoSettings();
const siteStore = useSiteStore();
const ecommerceStore = useEcommerceStore();
const savingStore = useSavingStore();

const error = ref<string | null>(null);
const initialSlug = ref<string>(pageSlug.value || '');

const currentPageId = computed(() => state.currentPageId);
const currentDynamicPageProductId = computed(() => ecommerceStore.currentDynamicPageProductId);
const isCurrentPageTypeEcommerceProduct = computed(() => getters.isCurrentPageTypeEcommerceProduct);
const pageSlugMessage = computed(() => {
	const rootUrlMessage = `${t('builder.pageSettingsModal.textFields.pageUrl.message')} ${getters.siteUrl}`;

	if (isCurrentPageTypeEcommerceProduct.value) {
		return `${rootUrlMessage}/${pageSlug.value || ''}`;
	}

	const path = getPagePathFromId({
		pageId: currentPageId.value,
		siteData: siteStore.site,
		locale: state.currentLocale,
	});

	return `${rootUrlMessage}${path}`;
});

const updatedProductsSlugs = computed(() => ecommerceStore.allProducts.map((product: EcommerceProduct) => {
	const productMeta = product.seo_settings || {};
	const metaUpdates = state.ecommerce.productMetaUpdates[product.id] || {};

	const updatedMeta = {
		...productMeta,
		...metaUpdates,
	};

	return updatedMeta.slug;
}));

const updatedPagesSlugs = computed(() => {
	const pagesUpdates = createDiff(savingStore.siteDataSnapshot, siteStore.site);
	// @ts-ignore
	const updatedPagesData = pagesUpdates?.languages?.[state.currentLocale]?.pages;

	return Object.entries(siteStore.site.languages[state.currentLocale].pages)
		.filter(([, pageData]) => pageData.type && !PAGE_TYPES_EXCLUDED_FROM_SLUG_VALIDATION.includes(pageData.type))
		.map(([pageId, pageData]) => {
			if (updatedPagesData && updatedPagesData[pageId]?.slug) {
				return updatedPagesData[pageId].slug[1];
			}

			return pageData.slug;
		});
});

const updatedSlugs = computed(() => [
	...updatedPagesSlugs.value,
	...updatedProductsSlugs.value,
]);

const isFocusKeywordIncludeInPageSug = computed(() => getFocusKeywordIncludedInPageSlug({
	pageSlug: pageSlug.value,
	focusKeyword: pageMetaFocusKeyword.value,
}));

const isSlugUnique = computed(() => getIsSlugUnique({
	slug: pageSlug.value,
	slugList: updatedSlugs.value,
}));

const pageSeoCheckerList = computed(() => ([
	{
		id: 'seoPageFocusKeyword',
		isCompleted: isFocusKeywordIncludeInPageSug.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerPageSlugIncludeFocusKeyword',
			value: pageMetaFocusKeyword.value ? `"${pageMetaFocusKeyword.value}"` : '',
		},
	},
	{
		id: 'seoPageDuplicatedSlug',
		isCompleted: isSlugUnique.value,
		text: {
			keyPath: 'builder.seoDrawer.seoChecker.seoCheckerSlugUnique',
			value: pageSlug.value,
		},
	},
]));

const setCheckoutSlugError = () => {
	error.value = t('builder.ecommerceCheckoutSlugError');
};

const setEmptySlugError = () => {
	error.value = t('validate.emptyValue');
};

const setSlugDuplicatedError = () => {
	error.value = t('validate.slugDuplicated');
};

const updateErrorMessage = (slug: string) => {
	const isUpdatedSlugUnique = getIsSlugUnique({
		slug,
		slugList: updatedSlugs.value,
	});

	if (!slug) {
		setEmptySlugError();
	} else if (slug === USER_SITE_PAGE_SLUG_ECOMMERCE_CHECKOUT) {
		setCheckoutSlugError();
	} else if (!isUpdatedSlugUnique) {
		setSlugDuplicatedError();
	} else {
		error.value = null;
	}
};

const updatePageSlug = async (newValue: string) => {
	const slug = slugifyPagePath(newValue).path;

	if (isCurrentPageTypeEcommerceProduct.value) {
		mergePageMeta({
			slug,
		});

		await nextTick();
		updateErrorMessage(slug);

		return;
	}

	dispatch('mergePageData', {
		pageId: currentPageId.value,
		pageData: {
			slug,
		},
	});

	updateErrorMessage(slug);
};

const handleBlur = () => {
	if (initialSlug.value === pageSlug.value) {
		return;
	}

	if (!error.value) {
		initialSlug.value = pageSlug.value;
	}

	updatePageSlug(initialSlug.value);
};

onMounted(() => {
	if (state.gui.activeDrawerSettings?.isCheckoutSlugError) {
		setCheckoutSlugError();
		dispatch('gui/setActiveDrawerSettings', {});
	}

	if (state.gui.activeDrawerSettings?.isSeoSlugInvalid) {
		setSlugDuplicatedError();
		dispatch('gui/setActiveDrawerSettings', {});
	}
});

watch([
	currentPageId,
	currentDynamicPageProductId,
], () => {
	initialSlug.value = pageSlug.value;

	updateErrorMessage(pageSlug.value);
});

</script>
<template>
	<div>
		<ZyroFieldInput
			id="page-url"
			:model-value="pageSlug"
			:label="t('builder.pageSettingsModal.textFields.pageUrl.label')"
			:message="pageSlugMessage"
			:maxlength="MAX_LENGTH_PAGE_SLUG"
			:error="error"
			@update:model-value="updatePageSlug"
			@input-blur="handleBlur"
		/>
		<CheckerList :checker-items="pageSeoCheckerList" />
	</div>
</template>

<style lang="scss" scoped>
.status-icon {
	flex-shrink: 0;
	color: $color-warning-dark;
	margin-right: 8px;

	&--completed {
		color: $color-success;
	}
}

.collapsible-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: 400;
}

:deep(.collapsible) {
	border: 1px solid $color-gray-border;
	border-radius: 8px;
	overflow: hidden;
}

:deep(.collapsible__header) {
	padding: 12px 16px;
}

.checker-list-item {
	display: flex;
	align-items: top;
}

.icon-wrapper {
	display: flex;
}
</style>
