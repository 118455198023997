<template>
	<!-- Overflows are visible -->
	<ZyroModal
		max-width="744px"
		height="auto"
		class="published-modal"
		:delay="600"
		@close-modal="userClosesModal"
	>
		<div class="published-modal__text-container">
			<h2 class="published-modal__title text-heading-1">
				<slot name="title" />
			</h2>
			<p class="published-modal__subtitle text-body-2">
				<slot name="subtitle" />
			</p>

			<ZyroLoader
				v-if="domainStore.isStatusLoading"
				class="published-modal__loader"
			/>
			<div
				v-else
				class="published-modal__content"
			>
				<PublishedDomainPreview
					:domain="siteUrlToUse"
					@open-site="openSite"
				/>
				<PublishedModalUpdateSection />
			</div>
		</div>
		<PublishedShareButtons
			class="published-modal__share-buttons"
			:domain-to-share="customDomain"
		/>
	</ZyroModal>
</template>

<script setup lang="ts">
import ZyroModal from '@/components/global/ZyroModal.vue';
import {
	computed,
	onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useDomainStore } from '@/stores/domainStore';

import ZyroLoader from '@zyro-inc/site-modules/components/ZyroLoader.vue';

import PublishedModalUpdateSection from '@/components/builder-modals/modals/PublishedModalUpdateSection.vue';

import PublishedDomainPreview from '@/components/ui/PublishedDomainPreview.vue';
import PublishedShareButtons from '@/components/ui/PublishedShareButtons.vue';
import { CLOSE_MODAL } from '@/store/builder/gui';
import EventLogApi from '@/api/EventLogApi';

import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { useNpsStore } from '@/stores/npsStore';

const {
	state,
	getters,
	dispatch,
} = useStore();

const domainStore = useDomainStore();
const npsStore = useNpsStore();

const customDomain = computed(() => domainStore.customDomain);
const siteCustomDomainUrl = computed(() => getters.siteCustomDomainUrl);
const siteZyroDomainUrl = computed(() => getters.siteZyroDomainUrl);
const sitePreviewDomainUrl = computed(() => getters.sitePreviewDomainUrl);
const isCustomDomainPreview = computed(() => getters.isCustomDomainPreview);

const activeModalSettings = computed(() => state.gui.activeModalSettings);
const isUpdate = computed(() => activeModalSettings.value?.isUpdate);

const siteUrlToUse = computed(() => {
	const isCustomDomainShown = customDomain.value && !isCustomDomainPreview.value && domainStore.isDomainConnected;
	const previewDomainUrl = isHostingerBrand ? sitePreviewDomainUrl.value : siteZyroDomainUrl.value;

	return isCustomDomainShown ? siteCustomDomainUrl.value : previewDomainUrl;
});
const showUpdateSection = computed(() => !customDomain.value || isCustomDomainPreview.value);

const handleNpsModalVisibilityStatus = async () => {
	npsStore.refetchNpsModalVisibilityStatus();

	dispatch(`gui/${CLOSE_MODAL}`);
};

const openSite = () => {
	EventLogApi.logEvent({
		eventName: 'website_builder.published.view_site',
		eventProperties: {
			creation_location: isUpdate.value ? 'website_updated_modal' : 'website_published_modal',
		},
	});

	window.open(siteUrlToUse.value, '_blank');
	handleNpsModalVisibilityStatus();
};

const userClosesModal = async () => {
	handleNpsModalVisibilityStatus();
};

onMounted(async () => {
	if (!isCustomDomainPreview.value) {
		domainStore.fetchDomainStatus();
	}

	if (isHostingerBrand) {
		await domainStore.fetchFreeDomainEligibility();
	}

	if (showUpdateSection.value) {
		EventLogApi.logEvent({
			eventName: 'websites.connect_domain.shown',
			eventProperties: {
				location: 'builder',
				creation_location: isUpdate.value ? 'website_updated_modal' : 'website_published_modal',
			},
		});
	}
});
</script>

<style lang="scss" scoped>
.published-modal {
	z-index: $z-index-popup;
	color: $color-dark;

	$this: &;

	&__content {
		display: flex;
		flex-direction: column;
	}

	/* stylelint-disable-next-line selector-class-pattern */
	:deep(.modal__content) {
		display: flex;
		flex-direction: column;
		padding: 0;
		overflow: hidden;
		border-radius: 16px;
		border: 1px solid $color-gray-border;
		box-shadow: $box-shadow-base;
	}

	&__share-buttons {
		margin: 24px 0 40px;
	}

	&__text-container {
		padding: 40px 40px 0;

		@media screen and (max-width: $media-mobile) {
			padding: 40px 16px 0;
		}
	}

	&__title {
		text-align: center;
	}

	&__icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: center;
		height: 20px;
		padding: 3px;
		margin-right: 8px;
		background: $color-warning-light;
		border-radius: 100%;
	}

	&__icon {
		width: 16px;
		height: 16px;
		color: $color-warning-dark;
	}

	&__separator {
		border-top: 1px solid $color-gray-border;
		width: calc(100% - 80px);
    margin-left: 40px;
	}

	&__subtitle {
		margin: 8px auto 32px;
		color: $color-gray-dark;
		text-align: center;
	}

	&__upsell-banners {
		margin-top: 32px;

		&--no-top-margin {
			margin-top: 0;
		}
	}

	&__domain-connection-banner {
		margin: 32px 0;
	}

	&__loader {
		margin: 0 auto;
	}

	&__domain-disclaimer {
		margin-top: 16px;
		color: $color-gray;
	}
}

@media screen and (max-width: $media-mobile) {
	.published-modal {
		/* stylelint-disable-next-line selector-class-pattern */
		:deep(.modal__content) {
			display: flex;
			flex-direction: column;
			padding: 0;
			overflow-y: auto;
		}

		&__separator {
			width: calc(100% - 40px);
			margin-left: 20px;
		}

		&__subtitle {
			margin-bottom: 40px;
		}
	}
}
</style>
