<template>
	<div
		:data-element-ref="elementId"
		class="layout-element__component layout-element__component--GridSocialIcons"
	>
		<GridSocialIcons
			ref="socialIconsRef"
			v-bind="composedProps"
			:prevent-links="!isPreviewMode"
		/>

		<template v-if="!isPreviewMode">
			<WarningTooltip v-if="showWarning && !areControlsDisabled">
				{{ $t('builder.socialIconsNoLinks') }}
			</WarningTooltip>

			<ElementEditControls
				:target-ref="socialIconsRef?.$el"
				:element-id="elementId"
				:is-element-active="isActive"
				:enter-edit-mode-button-title="$t('builder.editSocialIcons.title')"
				@element-deleted="handleElementDeletion"
			>
				<template #edit-mode-popup>
					<EditSocialIcons @close="closeElementEditPopupHandler" />
				</template>
			</ElementEditControls>
		</template>
	</div>
</template>

<script>
import {
	ref,
	defineComponent,
} from 'vue';

import WarningTooltip from '@zyro-inc/site-modules/components/WarningTooltip.vue';
import GridSocialIcons from '@zyro-inc/site-modules/components/elements/social-icons/GridSocialIcons.vue';
import ElementEditControls from '@/components/builder-controls/ElementEditControls.vue';
import EditSocialIcons from '@/components/builder-controls/edit-social-icons/EditSocialIcons.vue';

import { useGridSocialIcons } from '@zyro-inc/site-modules/components/elements/social-icons/useGridSocialIcons';
import { useGamification } from '@/use/useGamification';

import { DEFAULT_SOCIAL_LINKS } from '@zyro-inc/site-modules/constants/defaultSocialLinks';
import { GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS } from '@/constants/builderConstants';

import { useElementEditPopup } from '@/use/useElementEditPopup';

export default defineComponent({
	components: {
		GridSocialIcons,
		WarningTooltip,
		ElementEditControls,
		EditSocialIcons,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
		elementId: {
			type: String,
			required: true,
		},
		areControlsDisabled: {
			type: Boolean,
			default: false,
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		isPreviewMode: {
			type: Boolean,
			required: false,
		},
	},

	setup(props, context) {
		const socialIconsRef = ref(null);

		const { closeElementEditPopupHandler } = useElementEditPopup({
			elementId: props.elementId,
		});
		const { completeAchievement } = useGamification();

		const handleElementDeletion = () => {
			completeAchievement(GAMIFICATION_TASK_CHANGE_SOCIAL_ICONS);
		};

		return {
			socialIconsRef,
			composedProps: useGridSocialIcons(props, context),
			closeElementEditPopupHandler,
			handleElementDeletion,
		};
	},

	computed: {
		showWarning() {
			return this.data.links.some((link) => {
				const defaultSocialLink = DEFAULT_SOCIAL_LINKS[link.icon];

				return defaultSocialLink ? link.link === defaultSocialLink.link : false;
			});
		},
	},
});
</script>
