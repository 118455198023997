<script lang="ts" setup>
import Icon from '@/components/global/Icon.vue';
import HostingerButton from '@/components/global/HostingerButton.vue';
import { useReferralsStore } from '@/stores/referralsStore';
import {
	REFERRAL_PERCENT,
	REFFERAL_DOLLAR_REWARD,
} from '@/constants/builderConstants';
import { onMounted } from 'vue';
import EventLogApi from '@/api/EventLogApi';

defineEmits(['close']);

const referralsStore = useReferralsStore();

onMounted(() => {
	EventLogApi.logEvent({
		eventName: 'website_builder.referrals.banner_shown',
	});
});
</script>

<template>
	<div
		v-qa="'referral-banner'"
		class="referral-banner"
	>
		<div class="referral-banner__content">
			<i18n-t
				tag="p"
				keypath="builder.referAFriendBannerMessageV2"
			>
				<i18n-t
					tag="b"
					keypath="builder.dollarAmount"
				>
					{{ REFFERAL_DOLLAR_REWARD }}
				</i18n-t>
				<b>{{ REFERRAL_PERCENT }}%</b>
			</i18n-t>
		</div>
		<HostingerButton
			theme="danger"
			size="sm"
			@click="referralsStore.redirectToBuyReferrals('builder_bottom_bar')"
		>
			<span class="referral-banner__button-text">
				{{ $t('builder.referAFriendV2') }}
			</span>
			<template #icon-right>
				<Icon
					name="open_in_new"
					dimensions="20px"
				/>
			</template>
		</HostingerButton>
		<HostingerButton
			class="referral-banner__close-button"
			button-type="plain"
			@click="$emit('close')"
		>
			<template #icon>
				<Icon
					name="close"
					color="gray"
				/>
			</template>
		</HostingerButton>
	</div>
</template>

<style lang="scss" scoped>
.referral-banner {
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	gap: 16px;
	background: var(--color-meteorite-dark-2);
	justify-content: center;
	align-items: center;
	padding: 12px;

	&__content {
		color: var(--color-light);
		font-size: 14px;
		line-height: 24px;
	}

	&__close-button {
		padding: 0;
	}
}
</style>
